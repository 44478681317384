import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import ChangePassword from "./Components/Login/ChangePassword/ChangePassword";
import {HTMLTitle} from './Components/HTMLTitle/HTMLTitle'
import {LoginFirstPageWrapper} from "./Components/Login/LoginFirstPageWrapper/LoginFirstPageWrapper";
import './App.scss'
import {Home} from "./Components/Home/Home";
import {PublicRoute} from "./Components/Routes/PublicRoute";
import {PrivateRoute} from "./Components/Routes/PrivateRoute";
import {Logout} from "./Components/Logout/Logout";
import {LearningCenter} from "./LearningCenter/LearningCenter";
import {Reports} from "./Components/Reports/Reports";
import {Settings} from "./Components/Settings/Settings";
import {Course} from "./Course/Course";
import {LearningManagement} from "./Components/LearningManagement/LearningManagement";
import {SuperAdminCourseMgmnt} from "./Components/SuperAdmin/SuperAdminCourseMgmnt/SuperAdminCourseMgmnt";
import {SuperAdminCustomerMgmnt} from "./Components/SuperAdmin/SuperAdminCustomerMgmnt/SuperAdminCustomerMgmnt";
import {CourseCompleted} from "./Components/CourseCompleted/CourseCompleted";
import {CompleteCourseForm} from "./Components/CourseCompleted/CompleteCourseForm";
import {SuperAdminAllUsers} from "./Components/SuperAdmin/SAAllItemsPages/SAAllUsers/SAAllUsers";
import {SuperAdminAllOrganizaitons} from "./Components/SuperAdmin/SAAllItemsPages/SAAllOrgs/SAAllOrgs";
import {SignDocPage} from "./Components/SignDocPage/SignDocPage";
import {KnowledgeCenter} from "./Components/KnowledgeCenter/KnowledgeCenter";

class App extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        document.addEventListener("keydown", function(e) {

            if(e.ctrlKey && e.shiftKey && e.code === 'KeyY'){//ctrl+shift+y
                alert(`last updated on: ${process.env.REACT_APP_LAST_UPDATE_DATE}` )
            }
        })
    }


    render() {
        const additionalProps = {
            logout: this.logout,
            ...this.state,
            login: this.login,
        }
        return <>
            <HTMLTitle/>
            <Routes>
                <Route path="/" element={<Navigate to={{pathname: `/login`}} replace={true}/>}/>
                <Route path="/login" element={<PublicRoute component={LoginFirstPageWrapper} {...additionalProps}/>}/>
                <Route path="/changePassword" element={<PublicRoute component={ChangePassword} {...additionalProps}/>}/>
                <Route path="/home" element={<PrivateRoute component={Home} {...additionalProps}/>}/>
                <Route path="/learningCenter/*" element={<PrivateRoute component={LearningCenter} {...additionalProps}/>}/>
                <Route path="/knowledgeCenter" element={<PrivateRoute component={KnowledgeCenter} {...additionalProps}/>}/>
                <Route path="/reports/*" element={<PrivateRoute  component={Reports} {...additionalProps}/>}/>
                <Route path="/settings/*" element={<PrivateRoute  component={Settings} {...additionalProps}/>}/>
                <Route path="/courseComplete/:courseId" element={<PrivateRoute  component={CourseCompleted} {...additionalProps}/>}/>
                <Route path="/completeCourseForm/" element={<PrivateRoute  component={CompleteCourseForm} {...additionalProps}/>}/>
                <Route path="/learningManagement/*" element={<PrivateRoute component={LearningManagement} {...additionalProps}/>}/>
                <Route path="/course/:signDocId" element={<PrivateRoute context="standAlone" component={Course} {...additionalProps}/>}/>
                <Route path="/signDoc/:signDocId" element={<PrivateRoute component={SignDocPage} {...additionalProps}/>}/>
                <Route path="/nihol/courses" element={<PrivateRoute  component={SuperAdminCourseMgmnt} {...additionalProps} context/>}/>
                <Route path="/nihol/customers" element={<PrivateRoute  component={SuperAdminCustomerMgmnt} {...additionalProps} context/>}/>
                <Route path="/nihol/users" element={<PrivateRoute  component={SuperAdminAllUsers} {...additionalProps} context/>}/>
                <Route path="/nihol/organizations" element={<PrivateRoute  component={SuperAdminAllOrganizaitons} {...additionalProps} context/>}/>
                <Route element={<Navigate to={{pathname: `/login`}} replace={true}/>}/>
                <Route path="/logout" element={<Logout/>}/>
                <Route path="*" element={<Navigate to="/home" replace={true} />}/>
            </Routes>
        </>
    }

}




export default App