import React, {Component} from "react";
import {Navigate} from "react-router-dom";
import logo from '../../../assets/images/logo.png'
import './ChangePassword.scss'
import {FormInput} from "../../Form/Input/FormInput";
import {Button} from "../../Form/Button/Button";
import _ from 'lodash'
import {withUserContext} from "../../../contexts/UserContext";
import {i18n} from "../../../Utils/i18n/i18n";
import {buttonColors} from "../../Pickers/InputTheme";

class ChangePassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            referrer: props.referUrl,
            redirect: false
        };
    }
    getStateWithValidity = (newState) => {
        const {oldPassword, newPassword, repeatPassword} = newState
        let errorMsg = null
        let isValid = true
        if((_.isEmpty(_.trim(oldPassword)) && oldPassword !== undefined) ||
            (_.isEmpty(_.trim(newPassword)) && newPassword !== undefined)||
            (_.isEmpty(_.trim(repeatPassword)) && repeatPassword !== undefined)){
            errorMsg = i18n.translate('changePassword.pleaseFillAllFields')
            isValid = false;
        }
        else if(_.isEmpty(_.trim(oldPassword)) || _.isEmpty(_.trim(newPassword)) || _.isEmpty(_.trim(repeatPassword))){
            isValid = false
        }
        else if(!_.isEqual(newPassword, repeatPassword) && (repeatPassword !== undefined || newPassword !== undefined)){
            errorMsg = i18n.translate('changePassword.newPasswordsDoesNotMatch')
            isValid = false;
        }
        else if(!_.isEqual(newPassword, repeatPassword)){
            isValid = false;
        }
        return {
            ...newState,
            errorMsg,
            isValid
        }
    }
    handleChange = (attrName) => (event) => {
        let newState = {
            ...this.state,
            [attrName]: event.target.value
        }
        newState = this.getStateWithValidity(newState)
        this.setState(newState);
    }

    handleSubmit = async(event) => {
        event.preventDefault();
        if(this.state.errorMsg){
            return
        }
        const {userContext} = this.props
        try{
            await userContext.changePassword(this.state)
            this.setState({
                redirect: true
            })
        } catch(e){
            this.setState({
                errorMsg: _.get(e, 'errors[0].message'),
                redirect: false
            })
        }

    }

    render() {
        const {errorMsg, isValid} = this.state
        const {userContext} = this.props
        if (userContext.redirect) {
            return <Navigate to={{pathname: 'history'}}/>
        }


        return <div className="change-password-container">
            <img src={logo}/>
            <form className="login-form" onSubmit={this.handleSubmit}>
                <FormInput
                    error={this.state.error}
                    required
                    type="password"
                    id="oldPassword"
                    label={i18n.translate('changePassword.currentPassword')}
                    className={`login-text-field ${!this.state.error ? 'first' : ''}`}
                    value={this.state.oldPassword}
                    onChange={this.handleChange('oldPassword')}
                />
                <FormInput
                    error={this.state.error}
                    required
                    type="password"
                    id="newPassword"
                    label={i18n.translate('changePassword.password')}
                    className={`login-text-field ${!this.state.error ? 'first' : ''}`}
                    value={this.state.newPassword}
                    onChange={this.handleChange('newPassword')}
                />
                <FormInput
                    error={this.state.error}
                    required
                    type="password"
                    id="repeatPassword"
                    label={i18n.translate('changePassword.repeatPassword')}
                    className={`login-text-field ${!this.state.error ? 'first' : ''}`}
                    value={this.state.repeatPassword}
                    onChange={this.handleChange('repeatPassword')}
                />
               {errorMsg && <div className="error">
                    {errorMsg}
                </div>}
                <div className="login-btn-wrapper">
                    <Button type="submit" color={buttonColors.BLUE} style={{width: '100%'}} disabled={!isValid} text={i18n.translate('changePassword.changePassword')} onClick={this.handleSubmit} value="Change Password" />
                </div>
            </form>
        </div>
    }
}

export default withUserContext(ChangePassword)