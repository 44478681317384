import {map} from "lodash";
import React from "react";
import './ImageSelectGallery.scss'

export const ImageSelectGallery = ({selectedImageUrl, onSelectedImageUrl, imageOptions}) => {
    return <div className="courseImages">
        {map(imageOptions, (image, index) => {
            return <div key={index}
                        className={`courseImage ${selectedImageUrl === image ? 'selected' : ''}`}
                        onClick={onSelectedImageUrl(image)}>
                <img src={image} alt=""/>
            </div>
        })}
    </div>
}