import {Button} from "../../../Form/Button/Button";
import {buttonColors} from "../../../Pickers/InputTheme";
import {i18n} from "../../../../Utils/i18n/i18n";
import React, {useContext, useState} from "react";
import {GridReactContext} from "../../../../Utils/DataGridWrapper/GridContext";
import './RemoveCourseCol.scss'
import {Modal} from "../../../Smart/Modal/Modal";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {SA_removeSignDocFromCompanyAPI} from "../../../../Services/SA_api";

export const RemoveCourseCol = ({signDocId, docName}) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const gridContext = useContext(GridReactContext)

    const removeCourse = async () => {
        await SA_removeSignDocFromCompanyAPI({signDocId})
        gridContext.triggerTableRefresh()
        setIsModalOpen(false)
    }
    const onOpenModal = () => {
        setIsModalOpen(true)
    }
    const onModalClose = () => {
        setIsModalOpen(false)
    }
    return <div className="RemoveCourseCol">
        <Button color={buttonColors.GREY}
                icon={<DeleteForeverIcon/>}
                size={'small'}
                text={i18n.translate('removeCourseFromCompanyCol.deleteCourseButton')}
                onClick={onOpenModal}/>
        {isModalOpen ? <Modal open={isModalOpen} onClose={onModalClose}>
            <div className="RemoveCourseModal modalWrapper">
                <div
                    className="modalTitle">{i18n.translate('removeCourseFromCompanyCol.removeCourseTitle')} {docName}</div>
                <div
                    className="modalContent">{i18n.translate('removeCourseFromCompanyCol.removeCourseText')} {docName}?
                </div>
                <div className="modalFooter">
                    <Button color={buttonColors.GREY}
                            text={i18n.translate('removeCourseFromCompanyCol.removeCourseConfirm')}
                            onClick={removeCourse}/>
                    <Button color={buttonColors.YELLOW}

                            text={i18n.translate('general.cancel')}
                            onClick={onModalClose}/>
                </div>
            </div>
        </Modal> : ""}
    </div>
}