import {map} from "lodash";
import {ValuesBar} from "./ValuesBar/ValuesBar";
import {useEffect, useState} from "react";
import './MultipleHorizontalValueBars.scss'

/**
 * barsData example:
 * [
 *     {
 *     signDocId: signDocId,
 *     signDocName: signDocName,
 *     values: ValueBarData[],
 *     }
 *
 * ]
 * */

export const MultipleHorizontalValueBars = ({barsData, showPercent}) => {
    const [barsValues, setBarsValues] = useState()

    useEffect(() => {
        if (!showPercent) {
            //go over barsData, for each bar caculate the values and get the lergest value
            let maxBarValue = 0
            const newBarsValues = map(barsData, (barData, index) => {
                const totalValue = barData.values.reduce((acc, value) => acc + value.value, 0)
                maxBarValue = Math.max(maxBarValue, totalValue)
                return {
                    barId: barData.barId,
                    barTitle: barData.barTitle,
                    values: barData.values,
                    totalValue
                }
            })
            //set the overall width percent for each bar
            newBarsValues.forEach(barData => {
                barData.overallWidthPercent = (barData.totalValue / maxBarValue) * 100

            })
            setBarsValues(newBarsValues)
        } else {
            setBarsValues(map(barsData, (barData, index) => {
                return {
                    barId: barData.barId,
                    barTitle: barData.barTitle,
                    values: barData.values,
                    overallWidthPercent: 100
                }
            }))
        }
    }, [barsData]);

    return <div className="MultipleHorizontalValueBars">
        {map(barsValues, (barData, index) => {
            return <div className="barWrapper" key={barData.barId}>
                <div className="barTitle">{barData.barTitle}</div>
                <div className="barComponent">
                    <ValuesBar values={barData.values} overallWidthPercent={barData.overallWidthPercent}
                               showPercent={showPercent} small={barsValues.length > 4}/>
                </div>
            </div>
        })}
    </div>
}