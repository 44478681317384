import axios from "axios/index";
import {setServerCall} from "../Utils/rest";
import {get, set} from 'lodash'
import React from "react";
import {AlertDialog} from "../Components/Smart/AlertDialog/AlertDialog";
import {i18n} from "../Utils/i18n/i18n";
import {getAccessToken} from "../Services/auth";
import {withUserContext} from "../contexts/UserContext";
import {Navigate} from "react-router-dom";

const API_PORT = process.env.REACT_APP_API_PORT
const locationArray = window.location.href.split(':')
const BASE_API = process.env.REACT_APP_BASE_CLIENT || `https:${locationArray[1]}:${API_PORT}/`;

let serverCall = axios.create({
    baseURL: BASE_API
});
setServerCall(serverCall)

class APIHandlerInner extends React.Component {
    constructor(props) {
        super(props)

        const that = this;

        serverCall.interceptors.request.use((config) => {
                config.headers = config.headers || {};
                const accessToken = getAccessToken();
                if (accessToken) {
                    config.headers.Authorization = accessToken;
                }

                return config;
            }, (error) => Promise.reject(error)
        );
        serverCall.interceptors.response.use((res) => {
                if (this.state.apiError === 'UNAUTHORIZED' && res.request.responseURL.includes('login')) {
                    this.setState({
                        apiError: ""
                    })
                }
                return res
            }, (error) => {
                if (get(error, 'response.data.constructor') === ArrayBuffer) {
                    const decodedDataString = String.fromCharCode.apply(null, new Uint8Array(get(error, 'response.data')));
                    const data = JSON.parse(decodedDataString);
                    set(error, 'response.data', data)
                }
                if (get(error, 'response.status') === 401) {
                    if (this.state.apiError !== 'UNAUTHORIZED') {
                        that.setState({apiError: 'UNAUTHORIZED'})
                    }
                }
                if (get(error, 'response.status') === 457) {
                    that.setState({apiError: 'EMPTY_LOGIN'})
                }
                if (get(error, 'response.status') === 400) {
                    const handleByComponent = get(error, 'config.handleErrorByComponent', false)
                    if (handleByComponent) {
                        return Promise.reject({
                            error: get(error, 'response.data.meta.type'),
                            params: get(error, 'response.data.meta.params')
                        })
                    }
                    that.setState({
                        apiError: 'WRONG_INPUT',
                        errorMessage: get(error, 'response.data.meta.errorMessage')
                    })
                }
                if (get(error, 'response.status') === 403) {
                    that.setState({
                        apiError: 'FORBIDDEN',
                        errorMessage: get(error, 'response.data.meta.errorMessage')
                    })
                }
            if (get(error, 'response.status') === 409) {
                //errors with status 409 are not errors, they are just messages
                //they also have parameters that are needed to be shown
                that.setState({
                    apiError: 'FORBIDDEN',
                    errorMessage: i18n.translate(`Errors_409.${get(error, 'response.data.meta.type')}`, {context: {...get(error, 'response.data.meta.params')}})
                })
            }
                if (get(error, 'response.status') === 500) {
                    if (get(error, 'config.handleErrorByComponent', false)) {
                        return Promise.reject(error)
                    }
                    that.setState({
                        apiError: 'SERVER_ERROR',
                        errorMessage: get(error, 'response.data.meta.errorMessage', '')
                    })
                }
                return Promise.reject(error)
            }
        );
        this.state = {
            apiError: "",
            clearErrors: this.clearErrors
        }

    }

    clearErrors = () => {
        this.setState(
            {
                apiError: "",
                errorMessage: ""
            })
    }


    render() {
        const {clearErrors, errorMessage, apiError} = this.state
        if (apiError === 'SERVER_ERROR') {
            return <AlertDialog title={i18n.translate('errors.serverError')}
                                alertMessage={errorMessage || i18n.translate('errors.generalServerError')}
                                onOk={clearErrors}/>
        }
        if (apiError === 'WRONG_INPUT') {
            return <AlertDialog title={i18n.translate('errors.clientError')}
                                alertMessage={errorMessage || i18n.translate('errors.serverErrorWrongInput')}
                                onOk={clearErrors}/>
        }
        if (apiError === 'NOT_FOUND') {
            return <AlertDialog title={i18n.translate('errors.clientError')}
                                alertMessage={errorMessage || i18n.translate('errors.notFound')} onOk={clearErrors}/>
        }
        if (apiError === 'NOT_ACCEPTABLE') {
            return <AlertDialog title={i18n.translate('errors.clientError')}
                                alertMessage={errorMessage || i18n.translate('errors.notAcceptable')}
                                onOk={clearErrors}/>
        }
        if (apiError === 'FORBIDDEN') {
            return <AlertDialog title={i18n.translate('errors.clientError')}
                                alertMessage={errorMessage || i18n.translate('errors.forbidden')}
                                onOk={clearErrors}/>
        }
        if (apiError === 'EMPTY_LOGIN') {
            return <Navigate to={{pathname: `/logout`}} replace={true}/>
        }
        return ''
    }
}

export const APIHandler = withUserContext(APIHandlerInner)