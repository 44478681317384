import './MessageTypeSelectionButtons.scss'
import {i18n} from "../../../Utils/i18n/i18n";
import {FeatureFlags, MessageTypes} from "../../../Utils/Constants";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import {WhatsAppIcon} from "../../../Utils/SVGs/WhatsAppIcon";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import React, {useContext, useEffect} from "react";
import {CompanyReactContext} from "../../../contexts/CompanyContext";


const showWhatsApp = ({companyContext, excludeMessageTypes}) => {
    return companyContext.featureFlags[FeatureFlags.WHATSAPP] && !excludeMessageTypes.includes(MessageTypes.WHATSAPP)
}
const showSMS = ({companyContext, excludeMessageTypes}) => {
    return companyContext.featureFlags[FeatureFlags.SMS] && !excludeMessageTypes.includes(MessageTypes.SMS)
}
const showEmail = ({companyContext, excludeMessageTypes}) => {
    return companyContext.featureFlags[FeatureFlags.EMAIL] && !excludeMessageTypes.includes(MessageTypes.EMAIL)

}

export const MessageTypeSelectionButtons = ({onSelectedMessageTypeChange, selectedMessagingTypes=[], excludeMessageTypes=[]}) => {
    const companyContext = useContext(CompanyReactContext)
    useEffect(() => {
        //if selectedMessagingType is allowd by feature flags, leave it as is
        if (companyContext.featureFlags[FeatureFlags.SMS] && selectedMessagingTypes.includes(MessageTypes.SMS) ||
            companyContext.featureFlags[FeatureFlags.WHATSAPP] && selectedMessagingTypes.includes(MessageTypes.WHATSAPP) ||
            companyContext.featureFlags[FeatureFlags.EMAIL] && selectedMessagingTypes.includes(MessageTypes.EMAIL)) {
            return
        }
        //check featureFlags, if SMS valid, set SMS as default, if not, check for WhatsApp, if valid, set WhatsApp as default, if not, set Email as default
        if (companyContext.featureFlags[FeatureFlags.SMS]) {
            onSelectedMessageTypeChange(MessageTypes.SMS)
        } else if (companyContext.featureFlags[FeatureFlags.WHATSAPP]) {
            onSelectedMessageTypeChange(MessageTypes.WHATSAPP)
        } else if (companyContext.featureFlags[FeatureFlags.EMAIL]){
            onSelectedMessageTypeChange(MessageTypes.EMAIL)
        }
    }, []);
    const isSMSValid = showSMS({companyContext, excludeMessageTypes})
    const isWhatsAppValid = showWhatsApp({companyContext, excludeMessageTypes})
    const isEmailValid = showEmail({companyContext, excludeMessageTypes})

    return <div className="MessageTypeSelectionButtons">
        {isSMSValid || isWhatsAppValid || isEmailValid ? <div className="smsTitle">{i18n.translate('sendSMSModal.selectMessageDeliveryWay')}</div> : ''}
        <div className="messageTypeWrapper">
            {isSMSValid ? <div onClick={() => onSelectedMessageTypeChange(MessageTypes.SMS)}
                  className={`messageTypeButton ${selectedMessagingTypes.includes(MessageTypes.SMS) ? 'selected' : ''}`}>
                <div className="messageTypeIcon">{<SmsOutlinedIcon width="36px" height="36px"/>}</div>
                <div className="messageTypeText">{i18n.translate('sendSMSModal.sms')}</div>
            </div> : ''}
            {isWhatsAppValid ? <div onClick={() => onSelectedMessageTypeChange(MessageTypes.WHATSAPP)}
                 className={`messageTypeButton ${selectedMessagingTypes.includes(MessageTypes.WHATSAPP) ? 'selected' : ''}`}>
                <div className="messageTypeIcon">{<WhatsAppIcon width="32px" height="32px"/>}</div>
                <div className="messageTypeText">{i18n.translate('sendSMSModal.whatsapp')}</div>
            </div> : ''}
            {isEmailValid  ? <div onClick={() => onSelectedMessageTypeChange(MessageTypes.EMAIL)}
                 className={`messageTypeButton ${selectedMessagingTypes.includes(MessageTypes.EMAIL) ? 'selected' : ''}`}>
                <div className="messageTypeIcon">{<MailOutlineIcon width="32px" height="32px"/>}</div>
                <div className="messageTypeText">{i18n.translate('sendSMSModal.email')}</div>
            </div> : ''}

        </div>
    </div>
}