import {Button} from "../../../../Form/Button/Button";
import {buttonColors} from "../../../../Pickers/InputTheme";
import {i18n} from "../../../../../Utils/i18n/i18n";
import React, {useContext, useState} from "react";
import {GridReactContext} from "../../../../../Utils/DataGridWrapper/GridContext";
import './SARemoveUserCell.scss'
import {Modal} from "../../../../Smart/Modal/Modal";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {SA_permanentlyDeleteUsers} from "../../../../../Services/SA_api";

export const SARemoveUserCell = ({user: {id: userId, firstName, lastName, isCompanyAdmin, deletedAt}}) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const gridContext = useContext(GridReactContext)

    const removeUser = async () => {
        await SA_permanentlyDeleteUsers({userId})
        gridContext.triggerTableRefresh()
        setIsModalOpen(false)
    }
    const onOpenModal = () => {
        setIsModalOpen(true)
    }
    const onModalClose = () => {
        setIsModalOpen(false)
    }
    const userName = `${firstName} ${lastName}`
    return <div className="removeUserCell">

        <Button color={buttonColors.GREY}
                size="small"
                disabled={!deletedAt}
                icon={<DeleteForeverIcon/>}
                text={i18n.translate('SARemoveUser.permanentlyDeleteUser')}
                onClick={onOpenModal}/>
        {isModalOpen ? <Modal open={isModalOpen} onClose={onModalClose}>
            <div className="removeUserModal modalWrapper">
                <div
                    className="modalTitle">{i18n.translate('SARemoveUser.deleteUserTitle')} {userName}</div>
                <div
                    className="modalContent">{i18n.translate('SARemoveUser.deleteUserText')} {userName}?
                </div>
                <div className="modalFooter">
                    <Button color={buttonColors.GREY}
                            text={i18n.translate('SARemoveUser.deleteOrgConfirm')}
                            onClick={removeUser}/>
                    <Button color={buttonColors.YELLOW}
                            text={i18n.translate('general.cancel')}
                            onClick={onModalClose}/>
                </div>
            </div>
        </Modal> : ""}
    </div>
}