import {i18n} from "../../Utils/i18n/i18n";

export const SignDocTypes = {
    READ_AND_SIGN_DOC_PDF: 'READ_AND_SIGN_DOC_PDF',
    WATCH_AND_SIGN_YOUTUBE_LINK: 'WATCH_AND_SIGN_YOUTUBE_LINK',
    WATCH_AND_SIGN_VIMEO_LINK: 'WATCH_AND_SIGN_VIMEO_LINK',
    WATCH_AND_SIGN_VIDEO_FILE: 'WATCH_AND_SIGN_VIDEO_FILE',
    REGULAR_COURSE: 'REGULAR_COURSE'
}

export const getAllSignDocTypes = () => {
    return Object.values(SignDocTypes)

}

export const signDocTypeToFeatureFlagMap = {
    READ_AND_SIGN_DOC_PDF: 'signDocsReadAndSignDocPdfFF',
    WATCH_AND_SIGN_YOUTUBE_LINK: 'signDocsWatchAndSignYoutubeLinkFF',
    WATCH_AND_SIGN_VIMEO_LINK: 'signDocsWatchAndSignVimeoLinkFF',
    WATCH_AND_SIGN_VIDEO_FILE: 'signDocsWatchAndSignVideoFileFF',
    REGULAR_COURSE: 'regularCourseFF'
}
export const signDocReadOrWatchMap = {
    READ_AND_SIGN_DOC_PDF: 'read',
    WATCH_AND_SIGN_YOUTUBE_LINK: 'watch',
    WATCH_AND_SIGN_VIMEO_LINK: 'watch',
    WATCH_AND_SIGN_VIDEO_FILE: 'watch'

}

export const SignDocIcons = {
    READ_AND_SIGN_DOC_PDF: `${process.env.REACT_APP_PUBLIC_FILES_URL}/images/signDocImages/signDocPDF.png`,
    WATCH_AND_SIGN_YOUTUBE_LINK: `${process.env.REACT_APP_PUBLIC_FILES_URL}/images/signDocImages/SignDocVideo.png`,
    WATCH_AND_SIGN_VIMEO_LINK: `${process.env.REACT_APP_PUBLIC_FILES_URL}/images/signDocImages/SignDocVideo.png`,
    WATCH_AND_SIGN_VIDEO_FILE: `${process.env.REACT_APP_PUBLIC_FILES_URL}/images/signDocImages/SignDocVideo.png`

}

export const isYoutubeLink = ({videoUrl}) => {
    return videoUrl.includes('youtube.com/watch') || videoUrl.includes('youtube.com/embed') || videoUrl.includes('youtu.be')
}
export const isVimeoLink = ({videoUrl}) => {
    return videoUrl.includes('vimeo.com')
}

export const getSignDocPrefix = ({signDoc}) => {
    if (signDoc.type === SignDocTypes.REGULAR_COURSE) {
        return i18n.translate('settingsPage.reminders.course')
    } else if (signDoc.type === SignDocTypes.READ_AND_SIGN_DOC_PDF) {
        return i18n.translate('settingsPage.reminders.signDoc')
    } else if (signDoc.type === SignDocTypes.WATCH_AND_SIGN_VIMEO_LINK || signDoc.type === SignDocTypes.WATCH_AND_SIGN_YOUTUBE_LINK) {
        return i18n.translate('settingsPage.reminders.watchVideo')
    }
}