import SuperAdminCoursesTable from "../SuperAdminCoursesTable/SuperAdminCoursesTable";
import './SuperAdminCourseMgmnt.scss'
import '../SuperAdminPage.scss'
import {buttonColors} from "../../Pickers/InputTheme";
import {i18n} from "../../../Utils/i18n/i18n";
import {Button} from "../../Form/Button/Button";
import React from "react";
import {GridReactContextProvider} from "../../../Utils/DataGridWrapper/GridContext";
import {CreateNewCourseModal} from "../CreateEditCourse/CreateNewCourseModal";

export const SuperAdminCourseMgmnt = () => {
    const [isCreateNewCourseModalOpen, setIsCreateNewCourseModalOpen] = React.useState(false)
    const openCreateNewCourseModal = () => {
        setIsCreateNewCourseModalOpen(true)
    }
    const closeCreateNewCourseModal = () => {
        setIsCreateNewCourseModalOpen(false)
    }
    return <div className="SuperAdminCourseMgmnt SuperAdminPage">
        <div className="buttonsWrapper">
            <Button color={buttonColors.GREY}
                // style={{width: '100%'}}
                    text={i18n.translate('superAdmin.createNewCourse.modalTitle')}
                    onClick={openCreateNewCourseModal}/>
        </div>
        <GridReactContextProvider>
            <div className="superAdminCoursesTableWrapper">
                <SuperAdminCoursesTable/>
            </div>
            {isCreateNewCourseModalOpen ? <CreateNewCourseModal
                createCourseModalOpen={isCreateNewCourseModalOpen}
                closeCreateCourseModal={closeCreateNewCourseModal}/> : ''}
        </GridReactContextProvider>
    </div>
}