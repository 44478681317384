

// src/styles/colors.js
export const Colors = {
    '$dark': '#3f3f3f',
    '$headerColor': '#fff',
    '$menuItemText': '#efb713',
    '$menuItemIcon': '#efb713',
    '$buttonColor': '#595a5c',
    '$buttonSelectedColor': '#4f4f50',
    '$lightGreyBackground': 'rgba(231,235,244,0.99)',
    '$buttonYellowColor': '#D19C00',
    '$buttonGoldColor': '#D19C00',
    '$buttonLightGreyColor': '#BDBCBC',
    '$button-color': '#595a5c',

    // Menu
    '$menuBackground': '#efb713',
    '$menuButton': '#d19c00',
    '$menuButtonSelected': '#595a5c',
    '$menuTextColor': '#fff',
    '$greyText': '#A8A8A8',
    '$textBackground': '#F1F1F1',

    // Course
    '$completedColor': '#efb713',
    '$inProgressColor': '#595a5c',
    '$notStartedColor': '#d7d5d5',


    // Page
    '$pageTitle': '#595a5c',
    '$subTitle': '#a8a8a8',
    '$lightGrey': 'rgba(168, 168, 168, 0.16)',

    '$colorLightBlue': '#79b7ee',
    '$colorLink': '#007bff',

    '$colorRed': 'red',
    '$grey1': 'grey',

    '$borderColor': '#d19c00',


    '$themeLight': '#ffffff',
    '$themeText': '#a8dadc',
    '$themeTitle': '#457b9d',
    '$themeDark': '#020518',
    '$themeRed': '#e63946',
    '$DefaultColor': '#f1faee',


    '$GraphBackground1': '#A1D6B2',
    '$GraphBackground2': '#F5D471',
    '$GraphBackground3': '#E3E2E2',
    '$GraphColor1': '#efb713',
    '$GraphColor2': '#595a5c',
    '$GraphColor3': '#d7d5d5',
    '$GraphBorder': '#9B9C9E'
};

