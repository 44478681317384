import './GeneratedReports.scss';
import React from "react";
import {GridReactContextProvider} from "../../Utils/DataGridWrapper/GridContext";

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {GeneratedReportsTable} from "./GeneratedReportsTable/GeneratedReportsTable";
import {useUserAdminOrgs} from "../Hooks/UseUserAdminOrgs";

export const GeneratedReports = () => {
    const {adminOrganizationOptions, adminOrganizations, isOrgsLoading} = useUserAdminOrgs({})

    return <div className="GeneratedReports">
        <GridReactContextProvider >
            {!adminOrganizationOptions || isOrgsLoading ? <LoadingSpinner/> : <GeneratedReportsTable organizationsOptions={adminOrganizationOptions}/>}
        </GridReactContextProvider>
    </div>
}