import cover from "../../assets/images/cover.png";
import React, {useContext} from "react";
import "./PageTitle.scss";
import "./MenuPage.scss"
import logo from "../../assets/images/poweredByLogo.svg";
import {RefreshDataReactContext} from "../../contexts/RefreshDataContext";
import {CompanyReactContext} from "../../contexts/CompanyContext";

export const UserPageTitle = ({size, title, subTitle, subTitleBold, customComponent}) => {
    const refreshDataContext = useContext(RefreshDataReactContext)
    const companyContext = useContext(CompanyReactContext)
    const companyLogo = companyContext.companyLogoUrl
    return <div className={`PageTitle ${size}`}>
        <div className="PageTitleAbsolute">
            <img src={cover} alt=""/>
            <div className="gradient"></div>
        </div>
        <div className="WeezBeeLogo">
            <img src={logo} alt=""/>
        </div>
        {companyLogo ? <div className="customerLogo">
            {companyLogo && <img src={`${companyLogo}`} key={refreshDataContext.key} alt=""/>}
        </div> : ''}
        <div className="TitleRow">
            <div className="TitleWrapper">
                <div className="TitleText">{title}</div>
                <div className="SubtitleText">
                    {subTitle}
                    <span className="SubtitleBoldText">{subTitleBold}</span>
                </div>
            </div>
            <div className="customComponent">
                {customComponent}
            </div>
        </div>
    </div>
}