import './EditFeatureFlagsModal.scss'
import {i18n} from "../../../../Utils/i18n/i18n";
import {Button} from "../../../Form/Button/Button";
import {buttonColors} from "../../../Pickers/InputTheme";
import {Modal} from "../../../Smart/Modal/Modal";
import React, {useEffect} from "react";
import {SA_getCompanyFeatureFlags, SA_updateCompanyFeatureFlags} from "../../../../Services/SA_api";
import {keys, map} from "lodash";
import {FormControlLabel, Switch} from "@mui/material";
import {FeatureFlags} from "../../../../Utils/Constants";

const FFGroups = {
    [FeatureFlags.SMS]: [],
    [FeatureFlags.EMAIL]: [],
    [FeatureFlags.WHATSAPP]: [],
    [FeatureFlags.AUTOMATION]: [],
    [FeatureFlags.SEND_WELCOME_MESSAGE]: [],
    [FeatureFlags.SIGN_DOC_REGULAR_COURSE]: [],
    [FeatureFlags.SIGN_DOCS_READ_AND_SIGN_DOC_PDF]: [],
    [FeatureFlags.SIGN_DOCS_WATCH_AND_SIGN_VIDEO_FILE]: [],
    [FeatureFlags.SIGN_DOCS_WATCH_AND_SIGN_VIMEO_LINK]: [],
    [FeatureFlags.SIGN_DOCS_WATCH_AND_SIGN_YOUTUBE_LINK]: [],
    [FeatureFlags.NOTIFY_NEW_TASK_TO_USER]: [],
    [FeatureFlags.KNOWLEDGE_CENTER]: []
}

export const EditFeatureFlagsModal = ({editFFModalOpen, closeEditFFModal, customerId}) => {
    const [isLoading, setIsLoading] = React.useState(false)
    const [featureFlags, setFeatureFlags] = React.useState({})

    const onFFChange = (key) => async () => {
        const newFF = await SA_updateCompanyFeatureFlags({
            companyId: customerId,
            featureFlags: {...featureFlags, [key]: !featureFlags[key]}
        })
        setFeatureFlags(newFF)
    }
    const onFFGroupChange = (key) => async () => {
        setIsLoading(true)
        const keys = [key, ...FFGroups[key]]
        let tmpFeatureFlags = {...featureFlags}
        if(tmpFeatureFlags[key]) {
            map(keys, (k) => {
                tmpFeatureFlags[k] = false
            })
            tmpFeatureFlags = await SA_updateCompanyFeatureFlags({
                companyId: customerId,
                featureFlags: tmpFeatureFlags
            })
            setFeatureFlags(tmpFeatureFlags)
        }
        else{
            await onFFChange(key)()
        }
        setIsLoading(false)
    }
    const fetchFeatureFlags = async () => {
        setIsLoading(true)
        const ffs = await SA_getCompanyFeatureFlags({companyId: customerId})
        setFeatureFlags(ffs)
        setIsLoading(false)
    }
    useEffect(() => {
        fetchFeatureFlags()
    }, [])

    return <Modal
        open={editFFModalOpen}
        onClose={closeEditFFModal}>
        <div className="SuperAdminEditFFModal modalWrapper">
            <div className="modalTitle">
                <div>{i18n.translate('superAdmin.editFFModal.modalTitle')}</div>
            </div>
            <div className="modalContent">
                {featureFlags ? keys(FFGroups).map((key) => {
                    return <div key={key} className="ffGroup">
                        <FormControlLabel control={<Switch checked={featureFlags[key]}
                                                           onChange={onFFGroupChange(key)}/>}
                                          label={i18n.translate(`superAdmin.editFFModal.featureFlags.${key}`)}/>
                        {featureFlags[key] ? <div className="ffSubGroup">
                            {FFGroups[key].map((subKey) => {
                                return <FormControlLabel control={<Switch checked={featureFlags[subKey]}
                                                                          onChange={onFFChange(subKey)}/>}
                                                         label={i18n.translate(`superAdmin.editFFModal.featureFlags.${subKey}`)}/>
                            })}
                        </div> : ''}
                    </div>
                }) : ''}
            </div>
            <div className="modalFooter">
                <Button
                    color={buttonColors.GREY}
                    onClick={closeEditFFModal}
                    text={i18n.translate('general.close')}
                />
            </div>
        </div>
    </Modal>
}