import {Navigate} from "react-router-dom";
import React, {useContext} from "react";
import {i18n} from "../../Utils/i18n/i18n";
import Main from "../Main/Main";
import {UserReactContext} from "../../contexts/UserContext";

export const PrivateRoute = ({component: Component, rest, context, path, requiredPermissions, additionalProps}) => {
    const userContext = useContext(UserReactContext)
    if(!userContext.isUserValid()){
        alert('המשתמש אינו מחובר ולכן לא ניתן לראות את התכנים, אנא התחבר ולאחר מכן נסה שוב')
        return <Navigate to={{pathname: `/login`}}/>
    }
    if (userContext.isPermissionsLoaded() && requiredPermissions && !userContext.canUserPerformAction(requiredPermissions, 'or')) {
        return <div>{i18n.translate('permissions.actionNotAllowed')}</div>
    }
    else if (context === 'standAlone') {
        return <>
            <Component path={path} key={userContext.isUserValid()}/>
        </>
    }
    else {
        return <Main path={path}>
            <Component path={path} key={userContext.isUserValid()}/>
        </Main>
    }
}

