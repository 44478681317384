import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import './index.scss';
import {mainThemeJson} from "./mainTheme";
import createCache from "@emotion/cache";
import {CacheProvider} from "@emotion/react";

import {UserContextProvider} from "./contexts/UserContext";
import {APIHandler} from "./interceptors/APIHandler";
import {he} from 'date-fns/locale/he';
import {StyleSheetManager} from 'styled-components';
import rtlPlugin from 'stylis-plugin-rtl';
import {prefixer} from "stylis";
import {RefreshDataContextProvider} from "./contexts/RefreshDataContext";
import {CompanyContextProvider} from "./contexts/CompanyContext";
import {GoogleAnalytics} from "./Utils/GoogleAnalytics/GoogleAnalytics";
import { createRoot } from 'react-dom/client';

const mainTheme = createTheme(mainThemeJson);
const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin]
});
const root = createRoot(document.getElementById('root'));

root.render(
    <CacheProvider value={cacheRtl}>
        <StyleSheetManager stylisPlugins={[rtlPlugin]}>
            <ThemeProvider theme={mainTheme}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={he}>
                    <BrowserRouter>
                        {/*<MockDBLocalStorageContextProvider>*/}
                        <RefreshDataContextProvider>
                            <UserContextProvider>
                                <CompanyContextProvider>
                                    <APIHandler/>
                                    <GoogleAnalytics/>
                                    <App/>
                                </CompanyContextProvider>
                            </UserContextProvider>
                        </RefreshDataContextProvider>
                        {/*</MockDBLocalStorageContextProvider>*/}
                    </BrowserRouter>
                </LocalizationProvider>
            </ThemeProvider>
        </StyleSheetManager>
    </CacheProvider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
