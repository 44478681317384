import _ from 'lodash'

export const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const toCamelCase = (text) => {
    if(text){
        let capText = _.map(text.toLowerCase().split(' '), function (word) {
            return capitalizeFirstLetter(word)
        }).join('')
        return capText.charAt(0).toLowerCase() + capText.slice(1);
    }
    return text
}
export const parseWordsFromCamelCase = (camelCased) => {
    if(camelCased){
        let str = camelCased.replace(/([a-z](?=[A-Z]))/g, '$1 ')
        return str[0].toUpperCase() + str.slice(1)
    }
    else return camelCased;
}
export const getLinkWithQueryParams = (link, queryParams) => {
    if (queryParams) {
        let paramsString = [];
        _.map(queryParams, (val, key) => {
            if (key && val) paramsString.push(key + '=' + val)
        })
        return link + (paramsString ? ('?' + paramsString.join('&')) : '')
    }
    return link
}

export const exportBlobToFile = ({blobFile, fileName}) => {
    const element = document.createElement("a");
    element.href = URL.createObjectURL(blobFile);
    element.download = fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
}

export const isEmailValid = (email) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


export const isPasswordPatternValid = (password) => {
    if (!password || password.length < 8 ||
        password.toLowerCase() === password ||
        password.toUpperCase() === password ||
        !(/\d/.test(password))){
        return false
    }
    return true
}

export const removeEmptyStrings = (obj) => {
    _.map(_.keys(obj), function (key) {
        if(!obj[key] || obj[key] === ''){
            delete obj[key]
        }
    })
    return obj
}

export const compact = (array) => {
    return _.compact(_.filter(array, function (item) {
        return !_.isEmpty(item) && _.compact(_.toArray(item)).length > 0;
    }))
}


export const deepDiff = (object, base) => {
    const changes = (object, base) => {
        return _.transform(object, function (result, value, key) {
            if (!_.isEqual(value, base[key])) {
                result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
            }
        });
    }

    let changesRes = changes(object, base);
    return changesRes;

}

export const validatePhoneNumberAndFormat = (phoneNumber) => {
    if(phoneNumber){
        let phone = phoneNumber.replace(/[^0-9]/g, '');
        if(phone.length === 10){
            return phone
        }
    }
    return null
}

export const sanitizePhoneNumber = (to) => {
    if(!to || to === ''){
        return to
    }

    //remove letters and special characters
    let sanitizedTo = to.replace(/[\s-()[\]/.,]/g, '');
    //remove +972 prefix and add 0 prefix
    if (sanitizedTo.startsWith('+972')) {
        sanitizedTo = `0${to.substring(4)}`;
    }
    if (sanitizedTo.startsWith('9725')) {
        sanitizedTo = `0${to.substring(3)}`;
    }
    //if number does not start with 0, add 0 prefix
    if (!sanitizedTo.startsWith('0')) {
        sanitizedTo = `0${sanitizedTo}`;
    }
    // //if to value has country prefix, use it, else add israel country prefix
    // if (!sanitizedTo.startsWith('+')) {
    //     sanitizedTo = `+972${to.substring(1)}`;
    // }
    return sanitizedTo;
}

