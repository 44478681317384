import React, {useState} from "react";
import './FormInput.scss'
import TextField from '@mui/material/TextField';
import {InputTheme, SmallInputTheme} from "../../Pickers/InputTheme";
import {IconButton, InputAdornment} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {createStyles, ThemeProvider} from "@mui/material/styles";
import {makeStyles} from '@mui/styles';
import _ from 'lodash'

export const FormInput = ({
                              value, disabled, title, onChange, label, id, type = 'text', required = false,
                              onKeyDown, onKeyUp, colorScheme = 'primary',
                              showErrorWhenEmpty = false,
                              className = '', error = false, variant, placeholder, InputProps = {}, inputProps = {}, small = false, style = {}, autoFocus = null, dontShowErrorMessage, onBlur, onFocus,
                              InputLabelProps = {shrink: !_.isNull(value) || !_.isUndefined(value) || !_.isEmpty(String(value))}, hideEyeForPass = false, usePasswordPlaceholder = false
                          }) => {
    const [state, setState] = useState({
        showPassword: false,
        stateType: type
    })
    const useStyles = makeStyles((theme) => {
            return createStyles({
                textFieldNotchedOutline: {
                    color: 'black',
                    borderColor: 'black !important',

                }
            })
        }
    );
    const secondaryClasses = useStyles();

    const handleClickShowPassword = () => {
        setState({
            showPassword: !state.showPassword,
            stateType: !state.showPassword ? 'text' : 'password'
        })
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };


    const {showPassword, stateType} = state
    const passwordPlaceholder = "*******";

    if (type === 'password' && !hideEyeForPass) {
        InputProps.endAdornment = <InputAdornment position="end">
            <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
            >
                {showPassword ? <Visibility/> : <VisibilityOff/>}
            </IconButton>
        </InputAdornment>
    }
    const enableShrink = (labelProps) => {
        return {
            ...labelProps,
            shrink: true
        }
    }
    if (usePasswordPlaceholder) {
        InputLabelProps = enableShrink(InputLabelProps);
        value = passwordPlaceholder;
    }
    if (colorScheme === 'secondary') {
        InputProps = {
            classes: {
                notchedOutline: secondaryClasses.textFieldNotchedOutline,
            }
        }
    }
    return <ThemeProvider theme={small ? SmallInputTheme : InputTheme}>
        <TextField
            dir="rtl"
            title={title}
            error={value && error || (showErrorWhenEmpty && error)}
            autoFocus={autoFocus}
            required={required}
            type={stateType || type}
            id={id}
            label={label}
            style={style}
            className={`form-input ${className}`}
            placeholder={placeholder}
            value={value}
            variant={variant}
            color={colorScheme}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onBlur={onBlur}
            disabled={disabled}
            onFocus={onFocus}
            inputProps={inputProps}
            InputProps={InputProps}
            InputLabelProps={InputLabelProps}
            helperText={!dontShowErrorMessage ? (error && error !== true ? error : null) : null}
        />
    </ThemeProvider>

}
