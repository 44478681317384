import './CreateEditCourseModal.scss';
import {i18n} from "../../../Utils/i18n/i18n";
import React from "react";
import {OrganizationRowMenu} from "./OrganizationRowMenu/OrganizationRowMenu";

export const getAdminNewCourseColumns = ({signDocId}) => {
    return [
        {
            field: 'orgName',
            headerName: i18n.translate("superAdmin.createNewCourse.organizationsTable.orgName"),
            minWidth: 430,
            align: 'right',
            sortable: false,
            renderCell: (params) => <OrganizationRowMenu valueToPresent={params.value} organizationId={params.row.id} organizationSignDocs={params.row.organizationSignDocs} signDocId={signDocId}/>,
        },
    ];
}

export const adminNewCourseFilterModel = ({companies, companyId}) => {
    return {
        showFilters: {
            search: true,
            companies: true
        },
        filterValues: {
            companyId: companyId
        },
        filterData: {
            companies
        }
    }
}
export const adminNewCourseConvertData = (organizations) => {
    return organizations
}