import React from 'react';
import "./ProgressSlider.scss"
import {i18n} from "../../i18n/i18n";

export const CourseProgressSlider = ({progressPercent, type}) => {
    let actualProgress = progressPercent
    if (progressPercent > 0 && progressPercent < 100) {
        actualProgress = 50
    }
    return <div className="ProgressSlider CourseProgressSlider">
        <div className="progressBar">
            <div className={`progressRound rightRound ${actualProgress >= 0 ? 'on' : ''}`}/>
            <div className={`progressRound middleRound ${actualProgress >= 50 ? 'on' : ''}`}/>
            <div className={`progressRound leftRound ${actualProgress === 100 ? 'on' : ''}`}/>
        </div>
        <div className="overlayProgressBar" style={{width: `${actualProgress}%`}}/>
        <div className="progressTexts">
            <div
                className={`progressText rightText ${actualProgress >= 0 ? 'on' : ''}`}>{i18n.translate(`signDocProgress.notStarted.${type}`)}</div>
            <div
                className={`progressText middleText ${actualProgress >= 50 ? 'on' : ''}`}>{i18n.translate(`signDocProgress.inProgress.${type}`)}</div>
            <div
                className={`progressText leftText ${actualProgress === 100 ? 'on' : ''}`}>{i18n.translate(`signDocProgress.finished.${type}`)}</div>
        </div>
    </div>
}

