import React, {useContext, useState} from "react";
import {GridReactContext} from "../../../../../Utils/DataGridWrapper/GridContext";
import {Button} from "../../../../Form/Button/Button";
import {buttonColors} from "../../../../Pickers/InputTheme";
import {ConfirmationModal} from "../../../../Smart/confirmationModal/confirmationModal";
import {i18n} from "../../../../../Utils/i18n/i18n";
import {SA_makeCompanyAdmin, SA_makeRegularUser} from "../../../../../Services/SA_api";
import './IsCompanyAdminCell.scss'
//params.value ? i18n.translate('superAdmin.allUsersTable.columns.yes') : i18n.translate('superAdmin.allUsersTable.columns.no')
export const IsCompanyAdminCell = ({user}) => {
    const [makeAdminConfirmationModalOpen, setMakeAdminConfirmationModalOpen] = useState(false)
    const [removeAsAdminConfirmationModalOpen, setRemoveAsAdminConfirmationModalOpen] = useState(false)
    const gridContext = useContext(GridReactContext);
    const openMakeAdminConfirmationModal = () => {
        setMakeAdminConfirmationModalOpen(true)
    }
    const closeMakeAdminConfirmationModal = () => {
        setMakeAdminConfirmationModalOpen(false)
    }

    const openRemoveAsAdminConfirmationModal = () => {
        setRemoveAsAdminConfirmationModalOpen(true)
    }
    const closeRemoveAsAdminConfirmationModal = () => {
        setRemoveAsAdminConfirmationModalOpen(false)
    }
    const makeAdmin = async () => {
        await SA_makeCompanyAdmin({newAdminUserId: user.id})
        gridContext.triggerTableRefresh()
        closeMakeAdminConfirmationModal()
    }
    const removeAsAdmin = async () => {
        await SA_makeRegularUser({adminUserId: user.id})
        gridContext.triggerTableRefresh()
        closeRemoveAsAdminConfirmationModal()
    }
    return <div className="isCompanyAdminCell">
        {user.isCompanyAdmin ? <Button
            size="small"
            color={buttonColors.GREY}
            // icon={<DeleteForeverIcon/>}
            onClick={openRemoveAsAdminConfirmationModal}
            text={i18n.translate('isCompanyAdmin.removeAdmin.button')}
        /> : <Button
            size="small"
            color={buttonColors.YELLOW}
            // icon={<DeleteForeverIcon/>}
            onClick={openMakeAdminConfirmationModal}
            text={i18n.translate('isCompanyAdmin.makeAdmin.button')}
        />}
        {makeAdminConfirmationModalOpen ? <ConfirmationModal
            confirmationModalOpen={makeAdminConfirmationModalOpen}
            onCloseConfirmationModal={closeMakeAdminConfirmationModal}
            title={i18n.translate('isCompanyAdmin.makeAdmin.title')}
            message={i18n.translate('isCompanyAdmin.makeAdmin.message')}
            messageBody={<div>
                <div>{i18n.translate('isCompanyAdmin.userName')}: <span
                    style={{fontWeight: 'bold'}}>{user.firstName + ' ' + user.lastName}</span></div>
            </div>}
            onConfirm={makeAdmin}
            onCancel={closeMakeAdminConfirmationModal}
            confirmText={i18n.translate('isCompanyAdmin.makeAdmin.confirm')}
        /> : ''}
        {removeAsAdminConfirmationModalOpen ? <ConfirmationModal
            confirmationModalOpen={removeAsAdminConfirmationModalOpen}
            onCloseConfirmationModal={closeRemoveAsAdminConfirmationModal}
            title={i18n.translate('isCompanyAdmin.removeAdmin.title')}
            message={i18n.translate('isCompanyAdmin.removeAdmin.message')}
            messageBody={<div>
                <div>{i18n.translate('isCompanyAdmin.userName')}: <span
                    style={{fontWeight: 'bold'}}>{user.firstName + ' ' + user.lastName}</span></div>
            </div>}
            onConfirm={removeAsAdmin}
            onCancel={closeRemoveAsAdminConfirmationModal}
            confirmText={i18n.translate('isCompanyAdmin.removeAdmin.confirm')}
        /> : ''}
    </div>
}