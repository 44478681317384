import React, {useState} from "react";
import {PageTitle} from "../PageTitle/PageTitle";
import {i18n} from "../../Utils/i18n/i18n";


export const SuperAdminHome = () => {
    const [organizations, setOrganizations] = useState()


    return <div className="home MenuPage">
        <PageTitle
            title={i18n.translate('home.welcome')}
            subTitle={i18n.translate('home.superAdmin')}/>
        <div className="menuInnerPage">
            <div className="homeContent">

            </div>
        </div>

    </div>
}