import _ from 'lodash'
import {setAccessToken} from "../../Services/auth";

const authLocalStorageName = 'WebAuth'

export const calcAndGetTokenFromLogin = (loginData, decodedToken) => {
    let token;
    if (decodedToken) {
        token = decodedToken
    }
    else if (loginData) {
        token = getDecodedToken(_.get(loginData, 'token'))
    }
    return token
}

export const setUserState = ({user, token}) => {
    saveUserStateToLocalStorage({user, token})
    setAccessToken(getDecodedToken(token))
    console.log('setting token')
}

export const saveUserStateToLocalStorage = ({user, token}) => {
    const userStateToSave = {user, token}
    typeof window !== undefined && window.localStorage.setItem(authLocalStorageName, JSON.stringify(userStateToSave))
}

export const loadUserStateFromLocalStorage = () => {
    const userState = typeof window !== undefined && window.localStorage.getItem(authLocalStorageName);
    if (userState) {
        return JSON.parse(userState);
    }
    return emptyLoginStateFromLocalStorage();
};


export const emptyLoginStateFromLocalStorage = () => {
    typeof window !== undefined && window.localStorage.removeItem(authLocalStorageName);
    return null
};

export const getDecodedToken = (token) => {

    if (token) {
        token = token.replace("Bearer ", "");
        return "Bearer " + token
    }
    else return ''
};