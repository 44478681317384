import React, {useState} from "react";
import {Button} from "../../../Form/Button/Button";
import {buttonColors} from "../../../Pickers/InputTheme";
import {SACustomerQuotaMngmntModal} from "./SACustomerQuotaMngmntModal";
import './SACustomerQuotaMngmnt.scss'
import {i18n} from "../../../../Utils/i18n/i18n";
import {GridReactContext} from "../../../../Utils/DataGridWrapper/GridContext";
import {get} from 'lodash'

export const SACustomerQuotaMngmnt = ({company, companyId}) => {
    const [isAddQuotaModalOpen, setIsAddQuotaModalOpen] = useState(false)
    const gridContext = React.useContext(GridReactContext)
    const openAddQuotaModal = () => {
        setIsAddQuotaModalOpen(true)
    }
    const closeAddQuotaModal = () => {
        setIsAddQuotaModalOpen(false)
    }
    const onAddCompleted = () => {
        gridContext.triggerTableRefresh()
        closeAddQuotaModal()
    }
    return <div className="SACustomerQuota">
        <Button color={buttonColors.GOLD}
                size="small"

                text={i18n.translate('superAdmin.customersTable.quota.editQuota')}
                onClick={openAddQuotaModal}/>


        {isAddQuotaModalOpen ? <SACustomerQuotaMngmntModal
            messageQuota={get(company, 'smsQuota')}
            storageQuotaMB={get(company, 'storageQuotaMB')}
            addQuotaModalOpen={isAddQuotaModalOpen}
            closeAddQuotaModal={closeAddQuotaModal}
            companyId={companyId}
            onQuotaAdded={onAddCompleted}/> : ''}
    </div>
}