import './SACreateEditUserModal.scss'
import {i18n} from "../../../../../Utils/i18n/i18n";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import React, {useEffect} from "react";
import {Modal} from "../../../../Smart/Modal/Modal";
import {getUserAPI} from "../../../../../Services/api";
import {GridReactContext} from "../../../../../Utils/DataGridWrapper/GridContext";
import {FormInput} from "../../../../Form/Input/FormInput";
import {Button} from "../../../../Form/Button/Button";
import {buttonColors} from "../../../../Pickers/InputTheme";
import {SA_updateUserAPI} from "../../../../../Services/SA_api";
import {FormControlLabel, Switch} from "@mui/material";
import {get} from 'lodash'


export const SACreateEditUserModal = ({
                                        createEditUserModalOpen,
                                        closeCreateEditUserModal,
                                        onSuccess,
                                        editMode,
                                        userId
                                    }) => {
    const [isLoading, setIsLoading] = React.useState(false)
    const gridContext = React.useContext(GridReactContext)
    const [firstName, setFirstName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [identifier, setIdentifier] = React.useState('')
    const [phone, setPhone] = React.useState('')
    const [isSuperAdmin, setIsSuperAdmin] = React.useState(false)
    const [isMfaRequired, setIsMfaRequired] = React.useState(false)



    const editFirstName = (e) => {
        setFirstName(e.target.value)
    }
    const editLastName = (e) => {
        setLastName(e.target.value)
    }
    const editEmail = (e) => {
        setEmail(e.target.value)
    }
    const editIdentifier = (e) => {
        setIdentifier(e.target.value)
    }
    const editPhone = (e) => {
        setPhone(e.target.value)
    }
    const editMfaRequired = (e) => {
        setIsMfaRequired(e.target.checked)
    }




    const fetchUser = async () => {
        const user = await getUserAPI({userId})
        setFirstName(user.firstName)
        setLastName(user.lastName)
        setEmail(user.email)
        setIdentifier(user.identifier)
        setPhone(user.phone)
        setIsSuperAdmin(get(user, 'roles.SuperAdmin'))
        setIsMfaRequired(get(user, 'isMfaRequired'))
    }

    const init = async () => {
        setIsLoading(true)
        if(editMode){
            await fetchUser()
        }
        setIsLoading(false)
    }

    useEffect(() => {
      init()
    }, []);

    const onSave = async () => {
        setIsLoading(true)
        try{
            await SA_updateUserAPI({ userId, firstName, lastName, email, identifier, phone, isMfaRequired})
        }
        catch (e){
            console.log(e)
        }
        finally {
            setIsLoading(false)
            gridContext.triggerTableRefresh()
            onSuccess()
        }
    }

    return <Modal
        open={createEditUserModalOpen}
        onClose={closeCreateEditUserModal}>
        <div className="SACreateEditUserModal modalWrapper">
            <div className="modalTitle">
                <div>{`${i18n.translate('createEditUserModal.titleEdit')} ${firstName} ${lastName}`}</div>
            </div>
            <div className="modalContent">
                {isLoading && <LoadingSpinner/>}
                <div className="inputsWrapper">
                    <FormInput
                        label={i18n.translate('createEditUserModal.firstName')}
                        className=""
                        value={firstName}
                        onChange={editFirstName}
                    />
                    <FormInput
                        label={i18n.translate('createEditUserModal.lastName')}
                        className=""
                        value={lastName}
                        onChange={editLastName}/>
                    <FormInput
                        label={i18n.translate('createEditUserModal.email')}
                        className=""
                        value={email}
                        onChange={editEmail}/>
                    <FormInput
                        disabled={editMode}
                        label={i18n.translate('createEditUserModal.identifier')}
                        className=""
                        value={identifier}
                        onChange={editIdentifier}/>
                    <FormInput
                        label={i18n.translate('createEditUserModal.phone')}
                        className=""
                        value={phone}
                        onChange={editPhone}/>
                    <FormControlLabel control={<Switch
                        disabled={isSuperAdmin}
                        checked={isSuperAdmin || isMfaRequired}
                        onChange={editMfaRequired}
                    />} label={i18n.translate('createEditUserModal.mfaRequired')} />


                </div>

            </div>
            <div className="modalFooter">
                <Button
                    color={buttonColors.YELLOW}
                    onClick={closeCreateEditUserModal}
                    text={i18n.translate('general.close')}
                />
                <Button
                    disabled={!firstName || !lastName || !identifier}
                    color={buttonColors.GREY}
                    onClick={onSave}
                    text={i18n.translate('general.saveChanges')}
                />
            </div>
        </div>
    </Modal>
}