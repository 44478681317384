import React, {useEffect, useState} from "react";
import './Main.scss'
import {getUserOrganizations} from "../../Services/api";
import {getAdminOrganizations} from "../../Utils/User/UserLib";
import {AppSideMenu} from "../AppSideMenu/AppSideMenu";
import {useUserAdminOrgs} from "../Hooks/UseUserAdminOrgs";

const Main = ({children, path}) => {
    const {adminOrganizationOptions, adminOrganizations, isOrgsLoading} = useUserAdminOrgs({})

    return (
        <div className="root-container" dir="rtl">
            <div className="AppSideMenuWrapper">
                <AppSideMenu path={path} adminOrganizations={adminOrganizations}/>
            </div>

            <div className="MenuAndMainWrapper">
                <main
                    id={'main-container'}
                    className={`main-container`}
                >
                        {children}
                </main>
            </div>

        </div>
    );
}

export default Main;