import {i18n} from "../../../../Utils/i18n/i18n";
import {Button} from "../../../Form/Button/Button";
import {buttonColors} from "../../../Pickers/InputTheme";
import React, {useContext, useEffect, useState} from "react";
import {Modal} from "../../../Smart/Modal/Modal";
import {SelectBox} from "../../../Pickers/SelectBox/SelectBox";
import './SendNotificationsModal.scss'
import {getCompanySMSQuotaAPI, sendTaskReminderAPI, userCountToSendSMSAPI} from "../../../../Services/api";
import LoadingSpinner from "../../../LoadingSpinner/LoadingSpinner";
import {UserReactContext} from "../../../../contexts/UserContext";
import {AlertDialog} from "../../../Smart/AlertDialog/AlertDialog";
import {
    NotificationSuccessAlertMessageBody
} from "../NotificationSuccessAlertMessageBody/NotificationSuccessAlertMessageBody";
import bellIcon from '../../../../assets/images/notification-bell.png'
import {MessageTypeSelectionButtons} from "../../MessageTypeSelectionButtons/MessageTypeSelectionButtons";
import {useUserAdminOrgs} from "../../../Hooks/UseUserAdminOrgs";
import {SendNotificationCounts} from "../../SendNotificationCounts/SendNotificationCounts";
import {CompanyReactContext} from "../../../../contexts/CompanyContext";
import {EntityTypes} from "../../NotificationsLib";
import {getTaskOptionsForNotificationsInOrganization} from "../../../Settings/Reminders/RemindersLib";
import {MessageTypes} from "../../../../Utils/Constants";


const getSendOptions = () => [{
    value: '', text: ''
},
    {
        value: 'all', text: i18n.translate('sendSMSModal.sendToOptions.all')
    },
    {
        value: 'onlyNotNotified', text: i18n.translate('sendSMSModal.sendToOptions.onlyNotNotified')
    }

]



export const SendNotificationsModal = ({sendSMSModalOpen, closeSendSMSModal}) => {
    const {adminOrganizationOptions, adminOrganizations, isOrgsLoading} = useUserAdminOrgs({withAllOption: true, withCoursesAndSignDocs: true})
    const [selectedSentToOption, setSelectedSentToOption] = useState()
    const [signDocsOptions, setSignDocsOptions] = useState([])
    const [selectedTaskOption, setSelectedTaskOption] = useState()
    const [loadingSMSParameters, setLoadingSMSParameters] = useState(false)
    const [usersToSendCount, setUsersToSendCount] = useState(null)
    const [companySMSQuota, setCompanySMSQuota] = useState(null)
    const [showSuccessAlert, setShowSuccessAlert] = useState(false)
    const [successAlertData, setSuccessAlertData] = useState(null)
    const [showFailedAlert, setShowFailedAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [isSending, setIsSending] = useState(false)
    const [selectedMessagingType, setSelectedMessagingType] = useState(MessageTypes.SMS)
    const [selectedOrgOption, setSelectedOrgOption] = useState('all')

    const userContext = useContext(UserReactContext)
    const companyContext = useContext(CompanyReactContext)
    const onSelectedSentToOptionChange = (e) => {
        setSelectedSentToOption(e.target.value)
    }
    const onSelectedCourseOptionChange = (e) => {
        setSelectedTaskOption(e.target.value)
    }
    const onSelectedOrgOptionChange = (e) => {
        setSelectedOrgOption(e.target.value)
    }

    const canSend = () => {
        return selectedSentToOption && selectedTaskOption && (selectedOrgOption || selectedOrgOption === "") && selectedSentToOption !== '' && selectedTaskOption !== '' && !loadingSMSParameters
    }

    useEffect(() => {
        loadSMSParameters()
    }, [selectedSentToOption, selectedTaskOption]);

    const loadSMSParameters = async () => {
        if (!canSend()) {
            return
        }
        setLoadingSMSParameters(true)
        const skipAlreadyNotifiedUsers = selectedSentToOption === 'onlyNotNotified'
        const entityType = EntityTypes.SIGN_DOC
        const userCount = await userCountToSendSMSAPI({entityId: selectedTaskOption, entityType, organizationId: selectedOrgOption , skipAlreadyNotifiedUsers})
        const {remainingQuota} = await getCompanySMSQuotaAPI({companyId: userContext.getUserCompanyId()})
        setCompanySMSQuota(remainingQuota)
        setUsersToSendCount(userCount)
        setLoadingSMSParameters(false)
    }

    const setsignDocsOptions = () => {
        let {signDocOptionsTmp} = getTaskOptionsForNotificationsInOrganization({selectedOrgOption, adminOrganizations, showPastSignDocs: true});
        setSignDocsOptions(signDocOptionsTmp)
    }

    useEffect(() => {
        setsignDocsOptions()
        setSelectedTaskOption("")
    }, [selectedOrgOption, adminOrganizationOptions, adminOrganizations])





    const sendSMSToUsers = async () => {
        setIsSending(true)
        const entityType = EntityTypes.SIGN_DOC
        const {sentMessages, notSentMessages, error, params} = await sendTaskReminderAPI({
            entityId: selectedTaskOption,
            entityType,
            organizationId: selectedOrgOption,
            skipAlreadyNotifiedUsers: selectedSentToOption === 'onlyNotNotified',
            messagingType: selectedMessagingType
        })
        if (error) {
            setAlertMessage(i18n.translate(`CourseReminderNotificationButton.alerts.${error}`))
            setShowFailedAlert(true)
            return
        }
        setSuccessAlertData({sentMessages, notSentMessages})
        setShowSuccessAlert(true)
        setIsSending(false)
    }

    const clearAndCloseAlerts = () => {
        setShowSuccessAlert(false)
        setShowFailedAlert(false)
        setAlertMessage('')
        closeSendSMSModal()
    }
    const hasError = () => {
        return !canSend() || (!usersToSendCount && selectedSentToOption === 'onlyNotNotified') || (!usersToSendCount && selectedSentToOption !== 'onlyNotNotified') || (companySMSQuota < usersToSendCount)
    }

    return <Modal
        open={sendSMSModalOpen}
        onClose={closeSendSMSModal}>
        {isSending ? <LoadingSpinner/> : ''}
        <div className="sendSMSModal modalWrapper">
            <div className="modalTitle">
                <div>{i18n.translate('sendSMSModal.title')}</div>
            </div>
            <div className="modalContent">
                <div className="selectBoxes">
                    <SelectBox options={getSendOptions()} value={selectedSentToOption}
                               label={i18n.translate('sendSMSModal.sendToOptions.label')}
                               onChange={onSelectedSentToOptionChange}/>
                    <div className="selectCourseAndOrg">
                        {adminOrganizationOptions ? <SelectBox options={adminOrganizationOptions} value={selectedOrgOption}
                                                           label={i18n.translate('sendSMSModal.organizationOptions.label')}
                                                           onChange={onSelectedOrgOptionChange}/> : ""}

                        {signDocsOptions ? <SelectBox options={signDocsOptions} value={selectedTaskOption}
                                                     label={i18n.translate('sendSMSModal.taskOptions.label')}
                                                     onChange={onSelectedCourseOptionChange}/> : ""}
                    </div>
                    <div className="messagingTypeWrapper">
                        <MessageTypeSelectionButtons onSelectedMessageTypeChange={setSelectedMessagingType} selectedMessagingTypes={[selectedMessagingType]}/>
                    </div>
                </div>

                <SendNotificationCounts canSend={canSend} loadingSMSParameters={loadingSMSParameters} usersToSendCount={usersToSendCount}
                                        companySMSQuota={companySMSQuota} selectedSentToOption={selectedSentToOption}/>
            </div>
            <div className="modalFooter">
                <Button
                    color={buttonColors.GREY}
                    onClick={closeSendSMSModal}
                    text={i18n.translate('general.cancel')}
                />
                <Button
                    color={buttonColors.YELLOW}
                    onClick={sendSMSToUsers}
                    disabled={hasError()}
                    text={i18n.translate('sendSMSModal.send')}
                />

            </div>
        </div>
        {showSuccessAlert ?
            <AlertDialog
                title={<div className="alertReminderTitle">
                    {i18n.translate('CourseReminderNotificationButton.alerts.reminderSent')}
                    <img src={bellIcon} alt=""/>
                </div>}
                alertMessage={<NotificationSuccessAlertMessageBody {...successAlertData}/>}
                onOk={clearAndCloseAlerts}/> : ''}
        {showFailedAlert ?
            <AlertDialog title={i18n.translate('CourseReminderNotificationButton.alerts.reminderNotSent')}
                         alertMessage={alertMessage}
                         onOk={clearAndCloseAlerts}/> : ''}
    </Modal>
}