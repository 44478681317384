import './SMSQuota.scss';
import {i18n} from "../../../Utils/i18n/i18n";
import {useEffect, useState} from "react";
import {getCompanySMSQuotaAPI} from "../../../Services/api";
import {getFormattedGMTPlus2DateAndTime} from "../../../Utils/Date/Date";

export const SMSQuota = ({companyId}) => {
    const [remainingQuota, setRemainingQuota] = useState(0)
    const [sentQuota, setSentQuota] = useState(0)
    const [lastSMSDate, setLastSMSDate] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)
        getCompanySMSQuotaAPI({companyId})
            .then(({remainingQuota, sentMessages, lastSMSDate}) => {
                setRemainingQuota(remainingQuota)
                setSentQuota(sentMessages)
                setLastSMSDate(lastSMSDate)

            })
            .finally(() => {
                setIsLoading(false)
            })
    }, []);

    return <div className="SMSQuota">
        {/*<div className="bell-icon">*/}
        {/*    <img src={bellIcon} alt=""/>*/}
        {/*</div>*/}
        <div className="SMSQuota__header">
            <h2>{i18n.translate('settingsPage.SMSQuota.title')}</h2>
        </div>
        <div className="SMSQuota__content">
            <div className="SMSQuota__content__item">
                {isLoading ? '' : <div className="SMSQuota__content__item__title">{i18n.translate('settingsPage.SMSQuota.sent', {
                    context: {
                        sent: sentQuota,
                        quota: remainingQuota
                    }
                })}</div>}
            </div>
            <div className="SMSQuota__content__item">
                {isLoading || !sentQuota ? '' : <div
                    className="SMSQuota__content__item__title">{i18n.translate('settingsPage.SMSQuota.lastSMSWasSentOn', {context: {date:getFormattedGMTPlus2DateAndTime(lastSMSDate)}})}</div>}
            </div>
        </div>
    </div>
}