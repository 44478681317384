import React, {useContext, useEffect, useState} from 'react';
import {i18n} from "../../../Utils/i18n/i18n";
import {FormInput} from "../../Form/Input/FormInput";
import './TypeVerificationCode.scss'
import {Button} from "../../Form/Button/Button";
import {buttonColors} from "../../Pickers/InputTheme";
import {UserReactContext} from "../../../contexts/UserContext";
import {get} from 'lodash'
import {requestAnother2fa} from "../../../Services/api";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";

export const TypeVerificationCode = ({linkLogin}) => {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const userContext = useContext(UserReactContext);
    const [preStep, setPreStep] = useState(linkLogin);
    const handleChange = (event) => {
        const value = event.target.value;
        if (value.length <= 6) {
            setCode(value);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!code || code.length < 6) {
            setError(i18n.translate('login.verificationCodeFromSMS'));
        } else {
            const validation = userContext.validateMFA({code});
        }
    }

    const enterKeyListener = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(event)
        }
    }
    useEffect(() => {
        document.addEventListener('keydown', enterKeyListener)
        return () => {
            document.removeEventListener('keydown', enterKeyListener)
        }

    })
    const requestAnother2faCode = async () => {
        setLoading(true)
        const {valid} = await requestAnother2fa()
        setLoading(false)
        setPreStep(false)
    }

    if (preStep) {
        return <div className="TypeVerificationCode">
            <div className="login-btn-wrapper">
                <div className="messageSentText">{i18n.translate('login.helloX', {context: {name: get(userContext, 'user.firstName')}})}</div>
                <Button color={buttonColors.GREY} type="submit"
                        style={{width: '100%'}}
                        text={i18n.translate('login.sendCode')}
                        onClick={requestAnother2faCode} value="Login"/>
            </div>
        </div>
    }

    return (
        <div className="TypeVerificationCode">
            <FormInput
                error={error}
                dontShowErrorMessage
                required
                id="identifier"
                label={i18n.translate('login.verificationCodeFromSMS')}
                className="login-text-field first"
                value={code}
                onChange={handleChange}
                type="number"
                inputProps={{
                    maxLength: 6,
                    inputMode: 'numeric',
                    pattern: '[0-9]*'
                }}
            />
            <div className="sendAgainAndTextWrapper">
                <div
                    className="messageSentText">{i18n.translate('login.messageWasSentTo', {context: {last4digits: get(userContext, 'user.last4digits')}})}</div>
                <div className="tryAgain" onClick={requestAnother2faCode}>{i18n.translate('login.resendCode')}</div>
            </div>
            <div className="login-btn-wrapper">
                <Button color={buttonColors.GREY} type="submit" style={{width: '100%'}}
                        disabled={!code || code.length < 6}
                        text={i18n.translate('login.validate')} onClick={handleSubmit} value="Login"/>
            </div>
            {loading ? <LoadingSpinner/> : null}
        </div>
    );
};

