const imagesBaseUrl = `${process.env.REACT_APP_PUBLIC_FILES_URL}/images/default-course-images/example-course`
export const imageOptionsArray = [
    `${imagesBaseUrl}-1.png`,
    `${imagesBaseUrl}-2.png`,
    `${imagesBaseUrl}-3.png`,
    `${imagesBaseUrl}-4.png`,
    `${imagesBaseUrl}-5.png`,
    `${imagesBaseUrl}-6.png`,
    `${imagesBaseUrl}-7.png`,
    `${imagesBaseUrl}-8.png`,
    `${imagesBaseUrl}-9.png`,
    `${imagesBaseUrl}-10.png`,
    `${imagesBaseUrl}-11.png`,
    `${imagesBaseUrl}-12.png`,
    `${imagesBaseUrl}-13.png`,
    `${imagesBaseUrl}-14.png`,

]