import {i18n} from "./i18n/i18n";
import {SignDocTypes} from "../Components/SignDoc/SignDocLib";

export const signDocToTextProgressValueConverter = ({progressPercent, isSignDocValid, type, viewed, signed }) => {
    if(type === SignDocTypes.REGULAR_COURSE){
        if (isSignDocValid && (progressPercent === 0 || !progressPercent) ) {
            return i18n.translate('courseProgressMgmnt.notStarted')
        } else if (progressPercent > 0 && progressPercent < 100) {
            return i18n.translate('courseProgressMgmnt.inProgress')
        } else if (progressPercent === 100) {
            return i18n.translate('courseProgressMgmnt.finished')
        }
    }
    else{
        if (isSignDocValid && !viewed && !signed) {
            return i18n.translate('signDocProgressMgmnt.notStarted')
        } else if (viewed && !signed) {
            return i18n.translate('signDocProgressMgmnt.inProgress')
        } else if (signed) {
            return i18n.translate('signDocProgressMgmnt.finished')
        }
    }

}
export const signDocToClassProgressValueConverter = ({signed, viewed, progressPercent, type}) => {
    if(type === SignDocTypes.REGULAR_COURSE){
        if (progressPercent === 0 || !progressPercent) {
            return "notStarted"
        } else if (progressPercent > 0 && progressPercent < 100) {
            return "inProgress"
        } else if (progressPercent === 100) {
            return "completed"
        }
    }
    else{
        if (signed) {
            return "completed"
        } else if (viewed) {
            return "inProgress"
        } else {
            return "notStarted"
        }
    }
}





export const getSignDocDateText = ({finishByDate, completedOnDate, viewed, signed, type, progressPercent}) => {
    const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    if(type === SignDocTypes.REGULAR_COURSE){
        if (progressPercent === 100) {
            if(!completedOnDate){
                return undefined
            }
            return i18n.translate('courseProgressMgmnt.completedOnCourse') + new Date(completedOnDate).toLocaleDateString('en-GB', dateOptions)
        } else if (progressPercent < 100) {
            if(!finishByDate){
                return undefined
            }
            return i18n.translate('courseProgressMgmnt.finishBy') + new Date(finishByDate).toLocaleDateString('en-GB', dateOptions)

        } else {
            return undefined
        }
    }
    else{
        if (signed) {
            if(!completedOnDate){
                return undefined
            }
            return i18n.translate('courseProgressMgmnt.completedOn') + new Date(completedOnDate).toLocaleDateString('en-GB', dateOptions)
        } else if (viewed) {
            if(!finishByDate){
                return undefined
            }
            return i18n.translate('courseProgressMgmnt.finishBy') + new Date(finishByDate).toLocaleDateString('en-GB', dateOptions)

        } else {
            return undefined
        }
    }

}
