import './SignDocsReports.scss'
import {GridReactContextProvider} from "../../../Utils/DataGridWrapper/GridContext";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import React from "react";
import {useUserAdminOrgs} from "../../Hooks/UseUserAdminOrgs";
import {
    OrganizationUsersSignDocsTable
} from "../../Users/OrganizationUsersSignDocsTable/OrganizationUsersSignDocsTable";

export const  SignDocsReports = ({types, textType}) => {
    const {adminOrganizationOptions, adminOrganizations, isOrgsLoading} = useUserAdminOrgs({})
    return <div className="SignDocsReports">
        <GridReactContextProvider>
            {adminOrganizationOptions ?
                <div className="reportDataGridContainer">
                    <OrganizationUsersSignDocsTable types={types} organizationsOptions={adminOrganizationOptions}/>
                </div> :
                <LoadingSpinner/>}
        </GridReactContextProvider>
    </div>
}