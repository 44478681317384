import './CustomerFFTableCol.scss';
import {buttonColors} from "../../../Pickers/InputTheme";
import {i18n} from "../../../../Utils/i18n/i18n";
import {Button} from "../../../Form/Button/Button";
import React from "react";
import {EditFeatureFlagsModal} from "../EditFeatureFlagsModal/EditFeatureFlagsModal";

export const CustomerFFTableCol = ({customer}) => {
    const [editFFModalOpen, setEditFFModalOpen] = React.useState(false)
    const onClickEditFF = () => {
        setEditFFModalOpen(true)
    }
    const closeEditFFModal = () => {
        setEditFFModalOpen(false)
    }
    return <div className="EditCustomerTableCol">
        <Button color={buttonColors.GOLD}
                size="small"
                text={i18n.translate('superAdmin.editFFModal.editFeatures')}
                onClick={onClickEditFF}/>

        {editFFModalOpen ? <EditFeatureFlagsModal editFFModalOpen={editFFModalOpen} closeEditFFModal={closeEditFFModal} customerId={customer.id} /> : ''}
    </div>
}