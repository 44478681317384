import {Viewer, Worker} from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {useState} from "react";
import {get} from "lodash";

export const PdfViewer = ({url, onReadingCompleted}) => {
    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);

    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    // const zoomPluginInstance = zoomPlugin();
    const handleDocumentLoad = ({doc}) => {
        const numPages = get(doc, '_pdfInfo.numPages')
        setNumPages(numPages);
        if(numPages === 1 && onReadingCompleted){
            onReadingCompleted();
        }
    }

    const handleScroll = ({ currentPage }) => {
        setCurrentPage(currentPage);
        if (currentPage === numPages - 1 && onReadingCompleted) {
            onReadingCompleted();
        }
    }

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.js">
                <Viewer
                    onDocumentLoad={handleDocumentLoad}
                    onPageChange={handleScroll}
                    fileUrl={url}
                    plugins={[
                        defaultLayoutPluginInstance,
                        // zoomPluginInstance
                    ]}
                />
        </Worker>
    );
}