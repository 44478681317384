import './SignDocsList.scss'
import {i18n} from "../../../Utils/i18n/i18n";
import {map, orderBy} from "lodash";
import React, {useContext, useEffect, useState} from "react";
import {getAllAdminSignDocsAPI} from "../../../Services/api";
import {MgmntSignDocCard} from "../../SignDoc/MgmntSignDocCard/MgmntSignDocCard";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {CompanyReactContext} from "../../../contexts/CompanyContext";
import {CreateNewSignDocButton} from "../CreateNewSignDocButton/CreateNewSignDocButton";
import {SignDocTypes} from "../../SignDoc/SignDocLib";

export const SignDocsList = ({types, showForceCompletion = false, showNonForceCompletion = false}) => {
    //remove SignDocTypes.REGULAR_COURSE from validTypesForCreation
    const validTypesForCreation = types.filter(type => type !== SignDocTypes.REGULAR_COURSE)
    const [signDocs, setSignDocs] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const companyContext = useContext(CompanyReactContext)
    const init = async () => {
        let signDocs = await getAllAdminSignDocsAPI({
            types,
            showForceCompletion,
            showNonForceCompletion
        })
        signDocs = orderBy(signDocs, ['createdAt'], ['desc'])
        setSignDocs(signDocs)
        setIsLoading(false)
    }
    useEffect(() => {
        if (!companyContext.featureFlags) {
            return
        }
        init()
    }, [companyContext.featureFlags]);

    const onAfterEditComplete = () => {
        init()
    }

    const forKnowledgeCenter = !showForceCompletion && showNonForceCompletion
    return <div className="SignDocsList">
        {isLoading ? <LoadingSpinner/> : <>
            <div className="buttonsRow">
                <CreateNewSignDocButton
                    onTriggerSignDocRefresh={onAfterEditComplete}
                    validSignDocTypes={validTypesForCreation}
                    forKnowledgeCenter={forKnowledgeCenter}
                    buttonText={forKnowledgeCenter ? i18n.translate('LearningManagementPage.createKnowledgeSource') : null}/>
            </div>
            <div className="signDocumentsWrapper">
                {map(signDocs, (signDoc) => {
                    return <div className="signDocumentWrapper" key={signDoc.id}>
                        <MgmntSignDocCard signDoc={signDoc} onAfterEditComplete={onAfterEditComplete}/>
                    </div>
                })}
                {signDocs && signDocs.length === 0 ?
                    <div className="noSignDocs">{i18n.translate('LearningManagementPage.noSignDocs')}</div> : ''}
            </div>
        </>}

    </div>
}