import './StatsWidgetWrapper.scss';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {useState} from "react";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
export const StatsWidgetWrapper = ({children, title, bottomTitle, info}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return <div className="StatsWidgetWrapper">
        {info ? <div className={`infoBubble`} >
            <Typography
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                <HelpOutlineIcon/>
            </Typography>
            <Popover
                id="mouse-over-popover"
                sx={{ pointerEvents: 'none' }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1 }}>{info}</Typography>
            </Popover>
        </div> : ''}
        {title ? <div className="statsWidgetTitle">
            {title}
        </div> : ''}
       <div className="widgetContent">
           {children}
       </div>
        {bottomTitle ? <div className="statsWidgetBottomTitle">
            {bottomTitle}
        </div> : ''}
    </div>

}