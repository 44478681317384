import {createUsersForOrganizations, getCompanyPrefixAPI, getUserOrganizationsPlain} from "../../../Services/api";
import {getAdminOrganizations} from "../../../Utils/User/UserLib";
import React, {useContext, useEffect, useState} from "react";
import {SelectBox} from "../../Pickers/SelectBox/SelectBox";
import {get, isEmpty, keys, map} from 'lodash';
import {i18n} from "../../../Utils/i18n/i18n";
import './ImportUsersModal.scss'
import {UploadButton} from "../../Form/Button/UploadButton";
import ImportIcon from '@mui/icons-material/ImportExport';
import {buttonColors} from "../../Pickers/InputTheme";
import {importParametersFromFile} from "../../Reports/ReportsLib";
import {Modal} from "../../Smart/Modal/Modal";
import {Button} from "../../Form/Button/Button";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {GridReactContext} from "../../../Utils/DataGridWrapper/GridContext";
import {FeatureFlags, MessageTypes} from "../../../Utils/Constants";
import {FormControlLabel, Switch} from "@mui/material";
import {MessageTypeSelectionButtons} from "../../Notifications/MessageTypeSelectionButtons/MessageTypeSelectionButtons";
import {CompanyReactContext} from "../../../contexts/CompanyContext";

export const ImportUsersModal = ({importUserModalOpen, closeImportUsersModal, onSuccessCreateUsers}) => {
    const gridContext = React.useContext(GridReactContext)
    const [organizations, setOrganizations] = useState()
    const [selectedOrganization, setSelectedOrganization] = useState(null)
    const [isUploading, setIsUploading] = useState(false)
    const [usersToUpload, setUsersToUpload] = useState()
    const [companyPrefix, setCompanyPrefix] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState()
    const [sendWelcomeMessage, setSendWelcomeMessage] = React.useState(false)
    const [selectedMessagingTypes, setSelectedMessagingTypes] = React.useState([MessageTypes.SMS])
    const companyContext = useContext(CompanyReactContext)
    const [processedFile, setProcessedFile] = useState()
    const fetchOrgs = async () => {
        setIsLoading(true)
        let orgs= await getUserOrganizationsPlain()
        setCompanyPrefix(companyPrefix)
        orgs = getAdminOrganizations({organizationsWithPermission: orgs})
        orgs = map(orgs, (org) => {
            return {
                value: get(org, 'organization.id'),
                orgLogo: get(org, 'organization.orgLogo'),
                text: get(org, 'organization.orgName')
            }
        })
        orgs = [{ value: '', text: '' }, ...orgs]
        setOrganizations(orgs)
        // const selectedOrg = find(orgs, {value: get(gridContext.filterModel, 'filterValues.organizationId')})
        // setSelectedOrganization(get(selectedOrg, 'value'))
        await getCompanyPrefix({organizationId: get(orgs, '[1].value')})
        setIsLoading(false)
    }
    const getCompanyPrefix = async ({organizationId}) => {
        const companyPrefix = await getCompanyPrefixAPI({organizationId})
        setCompanyPrefix(companyPrefix)
    }


    const innerOnSelectOrgChange = async (value) => {
        setIsLoading(true)
        await getCompanyPrefix({organizationId: value})
        setSelectedOrganization(value)
        setUsersToUpload(undefined)
        setIsLoading(false)
    }
    const onSelectOrgChange = (e) => {
        innerOnSelectOrgChange(e.target.value)
    }

    const createUsersFromFile = async ({target}) => {
        setProcessedFile(target.files[0])
        target.value = ''
    }
    const processSelectedFile = async () => {
        setIsUploading(true)
        try {
            const dataRes = await importParametersFromFile({file: processedFile, companyPrefix, selectedMessagingTypes,sendWelcomeMessage})
            setUsersToUpload(dataRes)
            setErrors({})
        } catch ({errors, data}) {
            setUsersToUpload(data)
            setErrors(errors)
        } finally {
            setIsUploading(false)
        }

    }
    useEffect(() => {
        processSelectedFile()
    }, [processedFile, sendWelcomeMessage, selectedMessagingTypes]);
    const uploadUsers = async () => {
        setIsUploading(true)
        try{
            let sendWelcomeMessageData;
            if (sendWelcomeMessage) {
                sendWelcomeMessageData = {
                    messageTypes: selectedMessagingTypes
                }
            }
            await createUsersForOrganizations({
                organizationId: selectedOrganization,
                users: usersToUpload,
                sendWelcomeMessage: sendWelcomeMessageData
            })
        }
        catch(e){
            console.log(e)
        }
        finally{
            setIsUploading(false)
            onSuccessCreateUsers()
            gridContext.triggerTableRefresh()
        }
    }
    useEffect(() => {
        fetchOrgs()
    }, []);

    const onSendWelcomeMessageChange = (e) => {
        setSendWelcomeMessage(e.target.checked)
    }
    const onSelectedMessagingTypeChange = (messagingType) => {
        //if it is the only one selected do not remove it

        if (selectedMessagingTypes.includes(messagingType) && selectedMessagingTypes.length > 1) {
            setSelectedMessagingTypes(selectedMessagingTypes.filter((type) => type !== messagingType))
        } else {
            let messagingTypes = [...selectedMessagingTypes, messagingType]
            //uniq array
            messagingTypes = messagingTypes.filter((value, index, self) => self.indexOf(value) === index)
            setSelectedMessagingTypes(messagingTypes)
        }
    }

    return <Modal
        open={importUserModalOpen}
        onClose={closeImportUsersModal}>
        <div className="importUsersModal modalWrapper">
            <div className="modalTitle">
                <div>{i18n.translate('importUsersModal.title')}</div>
            </div>
            {<div className="modalContent">
                {(isLoading || isUploading) && <LoadingSpinner/>}
                <div className="selectWrapper">
                    <div className="selectTitle">
                        <div>{i18n.translate('importUsersModal.selectOrganization')}</div>
                    </div>
                    <div className="selectBoxRowWrapper">
                        <SelectBox label={i18n.translate('importUsersModal.organization')} options={organizations} value={selectedOrganization} onChange={onSelectOrgChange}/>
                        <UploadButton color={buttonColors.GREY}
                                      accept="txt/*"
                                      disabled={isUploading || !selectedOrganization}
                                      icon={<ImportIcon/>}
                                      onInputChange={createUsersFromFile}
                                      uniqId={"upload-1"}
                                      text={i18n.translate('OrganizationsPage.importUsersFile')}
                        />
                    </div>
                </div>
                <div className="welcomeMessageSend">
                    {companyContext.featureFlags[FeatureFlags.SEND_WELCOME_MESSAGE] ? <FormControlLabel control={<Switch
                        checked={sendWelcomeMessage}
                        onChange={onSendWelcomeMessageChange}
                    />} label={i18n.translate('createEditUserModal.sendWelcomeMessage')}/> : ''}

                    {sendWelcomeMessage ? <MessageTypeSelectionButtons
                            multiple={true}
                            onSelectedMessageTypeChange={onSelectedMessagingTypeChange}
                            selectedMessagingTypes={selectedMessagingTypes}
                            // excludeMessageTypes={[MessageTypes.WHATSAPP]}
                        />
                        : ''}
                </div>

                <div className="usersContainer">
                    {usersToUpload ? <div className="tableTitles">
                        <div className="countCol"></div>
                        <div className="firstName">{i18n.translate('importUsersModal.users.firstName')}</div>
                        <div className="lastName">{i18n.translate('importUsersModal.users.lastName')}</div>
                        <div className="identifier">{i18n.translate('importUsersModal.users.identifier')}</div>
                        <div className="phone">{i18n.translate('importUsersModal.users.phone')}</div>
                        <div className="email">{i18n.translate('importUsersModal.users.email')}</div>
                    </div> : ''}
                    {usersToUpload ? <div className="usersValues">
                        {map(usersToUpload, (user, rowIndex) => {
                            return <div key={rowIndex} className={`userRow`}>
                                <div className="countCol">{parseInt(rowIndex) + 1}</div>
                                <div
                                    className={`firstName ${get(errors, `[${rowIndex}].firstName`) ? 'error' : ''}`}>{user.firstName}</div>
                                <div
                                    className={`lastName ${get(errors, `[${rowIndex}].lastName`) ? 'error' : ''}`}>{user.lastName}</div>
                                <div
                                    className={`identifier ${get(errors, `[${rowIndex}].identifier`) ? 'error' : ''}`}>{user.identifier}</div>
                                <div
                                    className={`phone ${get(errors, `[${rowIndex}].phone`) ? 'error' : ''}`}>{user.phone}</div>
                                <div
                                    className={`email ${get(errors, `[${rowIndex}].email`) ? 'error' : ''}`}>{user.email}</div>
                            </div>
                        })}
                    </div> : ''}
                    <div className="errorContainer">
                        {map(keys(errors).slice(0, 2), (errorRowIndex, index) => {
                            return <div key={`error-${index}`} className="error">
                                {i18n.translate('importUsersModal.errors.missingMandatoryField', {context: {row: parseInt(errorRowIndex) + 1}})} {map(keys(errors[errorRowIndex]), attr => `${i18n.translate(`importUsersModal.users.${attr}`)}`).join(', ')}
                            </div>
                        })}
                    </div>

                </div>
                <div className="modalFooter">
                    <Button
                        color={buttonColors.YELLOW}
                        onClick={closeImportUsersModal}
                        text={i18n.translate('general.close')}
                    />
                    <Button
                        disabled={!isEmpty(errors) || isUploading || !usersToUpload}
                        color={buttonColors.GREY}
                        onClick={uploadUsers}
                        text={i18n.translate('importUsersModal.createUsers')}
                    />
                </div>
            </div>}
        </div>
    </Modal>
}