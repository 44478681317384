import React from 'react';
import {useDropzone} from 'react-dropzone'
import './FileUploadBox.scss'
import {i18n} from "../../../Utils/i18n/i18n";
import {onProgress} from "./FileUploadBoxLib";

export const FileUploadBox = ({

                                  className,
                                  children,
                                  style,
                                  accept,
                                  maxFiles = 1,
                                  onFileUploaded,
                                  uploadFiles,
                                  maxSize= 10000000,
                                  minSize= 0,
                                  supportedFileTypesForNotifications
                              }) => {
    const [progress, setProgress] = React.useState()
    const [dragOver, setDragOver] = React.useState(false)
    const [fileAccepted, setFileAccepted] = React.useState(false)


    const localOnProgress = (event) => {
        const {progress} = onProgress(event)
        setProgress(progress)
        if (progress === 100 && onFileUploaded) {
            onFileUploaded()
        }
    }

    const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length) {
            setFileAccepted(true)
            return uploadFiles({
                files: acceptedFiles,
                onProgress: localOnProgress,

            })
        } else return null
    }

    const getInnerComponent = () => {
        if (progress && progress !== 100) {
            return <div>{i18n.translate('uploadBox.uploading') + progress + '%'}</div>
        } else if (progress && progress === 100) {
            return <div>{i18n.translate('uploadBox.uploaded')}</div>
        } else {
            return children
        }
    }
    const onDragOverChange = (dragOver) => () => {
        setDragOver(dragOver)
    }
    const {
        getRootProps,
        getInputProps,
        acceptedFiles,
        fileRejections
    } = useDropzone({
        onDrop,
        accept,
        onDragEnter: onDragOverChange(true),
        onDragLeave: onDragOverChange(false),
        maxFiles,
        maxSize,
        minSize,
        onDropRejected: (fileRejections, event) => {
            alert(i18n.translate('uploadBox.uploadFailed'))
        }
    });
    const maxSizeMB = maxSize / 1000000
    return <div
        {...getRootProps()}
        style={{...style, position: 'relative'}}
        className={`dropzone file-upload ${className} ${dragOver ? 'dragOver' : ''} ${fileAccepted ? 'accepted' : ''}`}>
        <div className="progressOrChild">
            {getInnerComponent()}
            <div className="file-upload-messages">
                {maxSize && <div
                    className="file-upload-description">{i18n.translate('uploadBox.maxFileSize', {context: {maxFileSize: `${maxSizeMB}MB`}})}</div>}
                { accept &&
                    <div className="file-upload-description">{i18n.translate('uploadBox.onlyFileTypes', {context: {fileTypes: supportedFileTypesForNotifications}})}</div>}

            </div>

        </div>
        <input
            {...getInputProps()}
            style={{
                opacity: '0',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
            }}
        />

    </div>

};