import {i18n} from "../../../../Utils/i18n/i18n";
import {get, map} from 'lodash'
import {DataGridWrapper} from "../../../../Utils/DataGridWrapper/DataGridWrapper";
import {SA_getAllCompanies, SA_getAllOrganizationsFiltered} from "../../../../Services/SA_api";
import '../AllItemsPages.scss'
import '../../SuperAdminPage.scss'
import {useEffect, useState} from "react";
import LoadingSpinner from "../../../LoadingSpinner/LoadingSpinner";
import {RemoveOrgCell} from "./RemoveOrgCell/RemoveOrgCell";
import {GridReactContextProvider} from "../../../../Utils/DataGridWrapper/GridContext";

const getColumns = async () => {
    return [
        {
            field: 'orgName',
            headerName: i18n.translate("superAdmin.allOrgsTable.columns.orgName"),
            minWidth: 100,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}}>{params.value}</div>,
        },
        {
            field: 'companyName',
            headerName: i18n.translate("superAdmin.allOrgsTable.columns.companyName"),
            minWidth: 240,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}}
                                         title={params.value}>{params.value}</div>,
        },
        {
            field: 'companyId',
            headerName: i18n.translate("superAdmin.allOrgsTable.columns.companyId"),
            minWidth: 180,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        {
            field: 'orgAddress',
            headerName: i18n.translate("superAdmin.allOrgsTable.columns.orgAddress"),
            minWidth: 100,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}}
                                         title={params.value}>{JSON.stringify(params.value)}</div>,
        },
        {
            field: 'orgContactInfo',
            headerName: i18n.translate("superAdmin.allOrgsTable.columns.orgContactInfo"),
            minWidth: 100,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}}
                                         title={params.value}>{JSON.stringify(params.value)}</div>,
        },

        {
            field: 'DBID',
            headerName: i18n.translate("superAdmin.allOrgsTable.columns.id"),
            minWidth: 180,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        {
            field: 'id',
            headerName: i18n.translate("superAdmin.allOrgsTable.columns.delete"),
            minWidth: 180,
            align: 'right',
            sortable: false,
            renderCell: (params) => <RemoveOrgCell organizationId={params.value} organizationName={params.row.orgName}
                                                   usersCount={params.row.usersCount}
                                                   hasCourses={params.row.hasCourses}/>,
        },
    ];
}

const convertData = (organizations) => {
    return map(organizations, (organization) => {
        return {
            ...organization,
            companyName: get(organization, 'company.companyName'),
            DBID: get(organization, 'id'),
            hasCourses: get(organization, 'organizationCourses.length'),
            usersCount: get(organization, 'organizationsWithPermission.length'),
        }
    })
}
const paginationModel = {
    page: 0,
    pageSize: 25,
};
const filterModel = ({companies}) => {
    return {
        filterValues: {},
        filterData: {
            companies
        },
        showFilters: {
            search: true,
            companies: true
        }
    }
}

export const SuperAdminAllOrganizaitons = ({}) => {
    const [companies, setCompanies] = useState()
    const init = async () => {
        const companies = await SA_getAllCompanies()
        const selectBoxCompanies = [{
            value: "",
            text: 'All'
        }, ...map(companies, (company) => {
            return {
                value: get(company, 'id'),
                text: get(company, 'companyName')
            }
        })]

        setCompanies(selectBoxCompanies)
    }
    useEffect(() => {
        init()
    }, []);
    const apiToGetData = async ({paginationModel, filterModel}) => {
        return SA_getAllOrganizationsFiltered({paginationModel, filterModel})
    }
    if (companies) {
        return <div className="allItemsWrapper SuperAdminPage">
            <GridReactContextProvider>
                <DataGridWrapper
                    filterModel={filterModel({companies})}
                    disableColumnFilter
                    getColumns={getColumns}
                    paginationModel={paginationModel}
                    apiToGetData={apiToGetData}
                    dataConverter={convertData}
                />
            </GridReactContextProvider>
        </div>
    } else {
        return <LoadingSpinner/>
    }
}