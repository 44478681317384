import React from "react";

export const RefreshDataReactContext = React.createContext({
    key: 0
});


export const RefreshDataContextProvider = ({children}) => {
    const [key, setKey] = React.useState(0)
    const [adminUserPageKey,setAdminUserPageKey] = React.useState(0)
    const refresh = () => {
        setKey(key + 1)
    }
    const refreshAdminUsersPage = () => {
        setAdminUserPageKey(adminUserPageKey + 1)
    }
    return <RefreshDataReactContext.Provider value={{key, refresh, adminUserPageKey, refreshAdminUsersPage}}>
        {children}
    </RefreshDataReactContext.Provider>
}
