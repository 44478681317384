import {format, toZonedTime} from 'date-fns-tz';

export const getFormattedDateDDMMYYYYHHmm = (date) => {
    const d = new Date(date);
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()} ${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(2, '0')}`;
}
export const getFormattedDateDDMMYYYY = (date) => {
    const d = new Date(date);
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
}
export const getLocalComputerDate = (date) => {
    const d = new Date(date);
    return d;
}

export const getFormattedGMTPlus2DateAndTime = (date) => {
    const d = getLocalComputerDate(date);
    return getFormattedDateDDMMYYYYHHmm(d);
}
export const getFormattedGMTPlus2Date = (date) => {
    const d = getLocalComputerDate(date);
    return getFormattedDateDDMMYYYY(d);
}

export const getGMTOffset = (timezone) => {
    const now = new Date();
    const zonedTime = toZonedTime(now, timezone);
    const formattedTime = format(zonedTime, 'O', { timeZone: timezone });

    // Extract the GMT offset from the formatted time
    const gmtOffset = formattedTime.split('GMT')[1];

    return 'GMT' + gmtOffset;
}