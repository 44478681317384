import {Tab, Tabs} from "@mui/material";
import {Link, Route, Routes, useLocation} from "react-router-dom";
import {map} from "lodash";
import './RoutingTabs.scss'
import {Colors} from "../styles/Colors";
import {useContext} from "react";
import {CompanyReactContext} from "../../contexts/CompanyContext";


export const RoutingTabs = ({tabs, baseRoute}) => {
    const companyContext = useContext(CompanyReactContext)
    // get the current tab from the url
    const location = useLocation();
    const currentTab = location.pathname.split(`${baseRoute}/`)[1] || tabs[0].value;
    //filter tabs based on feature flags
    const filteredTabs = tabs.filter((tab) => {
        if(tab.requiredFeatureFlags){
            const hasAllFeatureFlags = tab.requiredFeatureFlags.every((featureFlag) => companyContext.featureFlags[featureFlag])
            return hasAllFeatureFlags
        }
        return true
    })
    return <div className="RoutingTabs">
        <Tabs value={currentTab}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
        >
            {map(filteredTabs, (tab) => (
                <Tab label={tab.label} key={tab.subPath} value={tab.subPath} component={Link} to={`/${baseRoute}/${tab.subPath}`}
                     sx={{
                         textTransform: 'none',
                         fontWeight: 'bold',
                         fontSize: '24px',
                         fontFamily: 'OpenSansCond',
                         '&.Mui-selected': {
                             color: Colors['$menuItemText'],
                         },
                     }}/>
            ))}
        </Tabs>
        <div className="routeContent">
            <Routes>
                {map(filteredTabs, (tab) => {
                    return <Route path={tab.subPath} element={tab.element} key={tab.subPath}/>
                })}
            </Routes>
        </div>

    </div>
}