import {buttonColors} from "../../../Pickers/InputTheme";
import {i18n} from "../../../../Utils/i18n/i18n";
import {Button} from "../../../Form/Button/Button";
import React, {useContext} from "react";
import {GridReactContext} from "../../../../Utils/DataGridWrapper/GridContext";
import {find} from 'lodash';
import './OrganizationRowMenu.scss';
import {SA_addSignDocToOrganization, SA_removeCourseFromOrganization} from "../../../../Services/SA_api";

export const OrganizationRowMenu = ({valueToPresent, organizationSignDocs, signDocId, organizationId}) => {
    const gridContext = useContext(GridReactContext)

    const onClickAddOrganization = async () => {
        await SA_addSignDocToOrganization({signDocId, organizationId})
        gridContext.modifyFilterDisabled({filterName: 'companies', disabled: true, disabledNote: i18n.translate("superAdmin.createNewCourse.organizationsTable.onlyOneCompanyPerCourse")})
        gridContext.triggerTableRefresh()
    }
    const onClickRemoveOrganization = async () => {
        await SA_removeCourseFromOrganization({signDocId, organizationId})
        gridContext.triggerTableRefresh()
    }

    const getButtonToShow = () => {
        if(!find(organizationSignDocs, {SignDocId: signDocId})) {
            return <Button color={buttonColors.GOLD}
                           text={i18n.translate("superAdmin.createNewCourse.organizationsTable.addOrganization")}
                           onClick={onClickAddOrganization}/>
        }
        else{
            return <Button color={buttonColors.LIGHT_GREY}
                           text={i18n.translate("superAdmin.createNewCourse.organizationsTable.removeOrganization")}
                           onClick={onClickRemoveOrganization}/>
        }
    }
    return <div className="OrganizationRowMenu">
        <div className={`valueToPresent`} title={valueToPresent}>{valueToPresent}</div>
        <div className={`userRowButtons visible`}>
            {getButtonToShow()}
        </div>

    </div>
}