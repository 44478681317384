import './NotificationMessageButton.scss';
import bellIcon from '../../../assets/images/notification-bell.png'
import {i18n} from "../../../Utils/i18n/i18n";
import {SendNotificationsModal} from "./SendNotificationsModal/SendNotificationsModal";
import {useState} from "react";

export const NotificationMessageButton = ({fullHeight}) => {
    const [sendSMSModalOpen, setSendSMSModalOpen] = useState(false)
    const openSendSMSModal = () => {
        setSendSMSModalOpen(true)
    }
    const closeSendSMSModal = () => {
        setSendSMSModalOpen(false)
    }
    return <div className={`SMSNotificationButton ${fullHeight ? 'fullHeight' : ''}`}   >
        <div className="clickable" onClick={openSendSMSModal}>
            <div className="bell-icon">
                <img src={bellIcon} alt=""/>
            </div>
            <div className="rectangle">
                <div className="title">{i18n.translate('SMSNotificationButton.title')}</div>
                <div className="sub-title">{i18n.translate('SMSNotificationButton.subTitle')}</div>
            </div>
        </div>

        {sendSMSModalOpen ?
            <SendNotificationsModal sendSMSModalOpen={sendSMSModalOpen} closeSendSMSModal={closeSendSMSModal}/> : ''}
    </div>
}