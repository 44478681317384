export const mainThemeJson = {
    direction: 'rtl',
    typography: {
        // fontFamily: 'Khand'
    },
    palette: {
        primary: {
            light: '#fff',
            main: '#0098ff',
            dark: '#020518',
            contrastText: '#000',
        },
        lightText: '#fff',
        secondary: {
            light: 'white',
            main: '#fff',
            contrastText: '#fff',
        },
        primaryButton: {}
    },
    datePicker: {
        headerColor: '#0098ff'
    }
}