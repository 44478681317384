import './CopyText.scss'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
export const CopyText = ({ text }) => {
    return <div className="CopyText">
        <div className="value">
            {text}
        </div>
        <div className="copyValue">
            <ContentCopyIcon
                onClick={() => {
                    navigator.clipboard.writeText(text);
                }}
            />
        </div>

    </div>
}