import {SignDocTypes} from "../SignDoc/SignDocLib";
import {MySignDocs} from "../../LearningCenter/MySignDocs/MySignDocs";
import React from "react";
import {PageTitle} from "../PageTitle/PageTitle";
import {i18n} from "../../Utils/i18n/i18n";
import {MyOpenTasks} from "../../LearningCenter/MyOpenTasks/MyOpenTasks";

export const KnowledgeCenter = () => {

    return <div className="KnowledgeCenter MenuPage">
        <PageTitle title={i18n.translate('knowledgeCenter.title')}
                   subTitle={i18n.translate('knowledgeCenter.subTitle')}/>
        <div className="menuInnerPage">
            <MyOpenTasks
                showForceCompletion={false}
                showNonForceCompletion={true}
            />
        </div>

    </div>
}