import React, {useEffect} from 'react';
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";
import useSound from 'use-sound';

const soundUrl =  `${process.env.REACT_APP_PUBLIC_FILES_URL}/sounds/hand-clap.mpeg`; // replace with the path to your sound file
export const Confetti = ({stopSoundAfter= 5000, showConfetti}) => {
    const [play, { stop }] = useSound(soundUrl, { volume: 1 });

    useEffect(() => {

        return () => {
            stop();
        };
    }, [play, stop]);

    useEffect(() => {
        if(showConfetti){
            play();
            setTimeout(() => {
                stop();
            },stopSoundAfter)
        }
    }, [showConfetti]);
    if(showConfetti){
        return <Fireworks autorun={{ speed: 3 }} />;
    }

    return ""
};