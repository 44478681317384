import {get, indexOf, isArray, isEmpty, isString, map, reduce} from 'lodash'
import React from "react";
import {changePasswordApi, loginWithTokenAPI, userLogin, validate2fa} from "../Services/api";
import {
    calcAndGetTokenFromLogin,
    emptyLoginStateFromLocalStorage,
    getDecodedToken,
    loadUserStateFromLocalStorage,
    setUserState
} from "../Utils/User/user";
import {setAccessToken} from "../Services/auth";


//context definition
export const UserReactContext = React.createContext({
    userState: {},
    redirect: false,
    user: {},
    daysUntilLicenseExpired: {},
    login: async () => {
    },
    loginWithToken: async () => {
    },
    logout: () => {
    },
    saveLogin: () => {
    },
    loadSavedToken: () => {
    },
    isUserValid: () => {
    },
    canUserPerformAction: () => {
    },
    isPermissionsLoaded: () => {
    },
    isUserSuperAdmin: () => {
    },
    isManagingCompany: () => {
    },
    getUserName: () => {
    },
    getUserCompanyId: () => {
    },
    validateMFA: () => {
    },
})


export class UserContextProvider extends React.Component {
    constructor(props) {
        super(props)
        const userState = loadUserStateFromLocalStorage()
        this.state = {
            userState: userState,
            user: get(userState, 'user'),
            login: this.login,
            loginWithToken: this.loginWithToken,
            logout: this.logout,
            saveLogin: this.saveLogin,
            loadSavedToken: this.loadSavedToken,
            validateMFA: this.validateMFA,
            isUserValid: this.isUserValid,
            changePassword: this.changePassword,
            isPermissionsLoaded: this.isPermissionsLoaded,
            redirect: false,
            canUserPerformAction: this.canUserPerformAction,
            daysUntilLicenseExpired: null,
            isUserSuperAdmin: this.isUserSuperAdmin,
            getUserName: this.getUserName,
            isManagingCompany: this.isManagingCompany,
            getUserCompanyId: this.getUserCompanyId
        }
        setAccessToken(getDecodedToken(get(userState, 'token')))
    }

    componentDidMount() {
        const {userState} = this.state;
        if (!isEmpty(get(userState, 'user'))) {
            // this.getUserAllowedActions(userState)
            // this.getLicenseExpiration()
        }
    }


    login = async ({identifier, password}) => {
        let userLoginResponse = await userLogin({identifier, password})
        if (get(userLoginResponse, 'user.mfa')) {
            this.saveLogin({loginData: get(userLoginResponse, 'user')})
            if(get(userLoginResponse, 'user.linkLogin')){
                return {linkLogin: true, mfa: true}
            }
            else{
                return {mfa: true}
            }
        } else {
            this.saveLogin({loginData: get(userLoginResponse, 'user')})
            return {}
        }
    };
    loginWithToken = async ({token}) => {
        let userLoginResponse = await loginWithTokenAPI({token})
        this.saveLogin({loginData: get(userLoginResponse, 'user')})
        return {mfa: true}
    }

    validateMFA = async ({code}) => {
        try {
            const userLoginResponse = await validate2fa({code})
            if (userLoginResponse.error) {
                return {error: userLoginResponse.error}
            }
            this.saveLogin({loginData: get(userLoginResponse, 'user')})
        } catch (e) {
            return {error: get(e, 'errors[0].message')}
        }
    }


    getUserName = () => {
        return get(this.state, 'user.firstName') + ' ' + get(this.state, 'user.lastName')
    }

    isManagingCompany = () => {
        return get(this.state, 'user.isCompanyAdmin', false)
    }

    getUserCompanyId = () => {
        return get(this.state, 'user.companyId')
    }


    logout = ({noRedirect, callBack}) => {
        // userLogout()
        this.setState({
            userState: emptyLoginStateFromLocalStorage()
        }, callBack)

    };
    saveLogin = ({loginData, decodedToken, withRedirect, allowedAction}) => {
        const token = calcAndGetTokenFromLogin(loginData, decodedToken)
        setUserState({user: loginData, token})
        this.setState({
            userState: loginData,
            user: loginData,
            redirect: withRedirect
        }, () => {
            if (withRedirect) {
                this.setState({
                    redirect: false
                })
            }
        })
    };
    isPermissionsLoaded = () => {
        return !isEmpty(get(this.state.userState, 'user.allowedSystemActions'))
    }
    canUserPerformAction = (actionStrings, operation = 'and') => {
        let res;
        if (!isArray(actionStrings) && isString(actionStrings)) {
            actionStrings = [actionStrings]
        }
        let values = map(actionStrings, (actionString) => {
            return indexOf(get(this.state, 'user.allowedSystemActions'), actionString) !== -1
        })
        if (operation === 'and') {
            res = reduce(values, (res, val) => {
                return res && val
            })
        } else if (operation === 'or') {
            res = reduce(values, (res, val) => {
                return res || val
            })
        }
        return res
    }
    loadSavedToken = () => {
        return loadUserStateFromLocalStorage().token;
    };


    isUserValid = () => {
        if (get(this.state, 'userState.mfa') || get(this.state, 'userState.user.mfa')) {
            return false
        }
        return get(this.state, 'userState.token')
    }
    isUserSuperAdmin = () => {
        return get(this.state, 'user.roles.SuperAdmin')
    }
    changePassword = async ({oldPassword, newPassword}) => {
        let userLoginResponse = await changePasswordApi({oldPassword, newPassword})
        this.saveLogin({userLoginResponse})
    }

    render() {
        return <UserReactContext.Provider value={this.state}>
            {this.props.children}
        </UserReactContext.Provider>
    }
}


export const withUserContext = Component => {
    return props => {
        return (
            <UserReactContext.Consumer>
                {(user) => {
                    return <Component {...props} userContext={user}/>;
                }}
            </UserReactContext.Consumer>
        );
    };
};
