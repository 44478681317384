import cover from "../../assets/images/cover.png";
import React from "react";
import "./PageTitle.scss";
import "./MenuPage.scss"
import logo from "../../assets/images/poweredByLogo.svg";
import {useMediaQuery} from "react-responsive";

export const SuperAdminPageTitle = ({title, subTitle, subTitleBold}) => {
    const isMobile = useMediaQuery({query: `(max-width: 800px)`});
    return <div className="PageTitle">
        <div className="PageTitleAbsolute">
            <img src={cover} alt=""/>
            <div className="gradient"></div>
        </div>
        <div className="WeezBeeLogo">
            <img src={logo} alt=""/>
        </div>
        <div className="TitleRow">
            <div className="TitleWrapper">
                <div className="TitleText">{title}</div>
                <div className="SubtitleText">
                    {subTitle}
                    <span className="SubtitleBoldText">{subTitleBold}</span>
                </div>

            </div>

        </div>
    </div>
}