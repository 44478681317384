import {i18n} from "../../../../Utils/i18n/i18n";
import {FormInput} from "../../../Form/Input/FormInput";
import React from "react";
import {Modal} from "../../../Smart/Modal/Modal";
import {buttonColors} from "../../../Pickers/InputTheme";
import {Button} from "../../../Form/Button/Button";
import './AddOrganizationsModal.scss'

export const AddOrganizationsModal = ({open, onClose, onCreateOrganizations}) => {
    const [organizationsList, setOrganizationsList] = React.useState([])

    const onSubmit = () => {
        const organizations = organizationsList.split(',').map((org) => org.trim())
        onCreateOrganizations({organizations})
    }
    const onOrganizationListChange = (e) => {
        setOrganizationsList(e.target.value)
    }
    return <Modal
        open={open}
        onClose={onClose}>
        <div className="AddOrganizationsModal">
            <div className="modalTitle">
                <div>{i18n.translate('superAdmin.editCustomer.addOrganizationModal.title')}</div>
            </div>
            <div className="modalContent">
                <div className="instructions">{i18n.translate('superAdmin.editCustomer.addOrganizationModal.useCommaSeparatedValues')}</div>
                <FormInput
                    required
                    id={`organizationsList`}
                    label={i18n.translate('superAdmin.editCustomer.addOrganizationModal.organizationsList')}
                    className=""
                    value={organizationsList}
                    onChange={onOrganizationListChange}
                />
                <Button
                    disabled={!organizationsList || organizationsList === ""}
                    color={buttonColors.GREY}
                    onClick={onSubmit}
                    text={i18n.translate('general.saveChanges')}
                />
            </div>
        </div>
    </Modal>
}