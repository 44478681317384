import './CourseCompleted'
import {Navigate, useParams} from "react-router-dom";
import React, {useLayoutEffect} from "react";

export const CourseCompleted = () => {
    const {courseId} = useParams()
    useLayoutEffect(() => {
        typeof window !== undefined && window.localStorage.setItem("CompletedCourse", courseId)
    }, []);
    return <Navigate to="/completeCourseForm" replace={true} />
}