import './CreateNewSignDocButton.scss'
import {buttonColors} from "../../Pickers/InputTheme";
import {i18n} from "../../../Utils/i18n/i18n";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import {Button} from "../../Form/Button/Button";
import {useContext, useEffect, useState} from "react";
import {FeatureFlags} from "../../../Utils/Constants";
import {CompanyReactContext} from "../../../contexts/CompanyContext";
import {CreateEditNewSignDocModal} from "./CreateEditNewSignDocModal/CreateEditNewSignDocModal";
import {SignDocTypes, signDocTypeToFeatureFlagMap} from "../../SignDoc/SignDocLib";
import {filter} from "lodash";

export const CreateNewSignDocButton = ({
                                           onTriggerSignDocRefresh,
                                           validSignDocTypes = [SignDocTypes.READ_AND_SIGN_DOC_PDF],
                                           signDocType = SignDocTypes.READ_AND_SIGN_DOC_PDF,
                                           buttonText,
                                           forKnowledgeCenter
                                       }) => {
    const [newDocSignModalOpen, setNewDocSignModalOpen] = useState(false)
    const [signDocTypeState, setSignDocTypeState] = useState(signDocType)
    const [validSignDocTypesState, setValidSignDocTypesState] = useState(validSignDocTypes)
    const companyContext = useContext(CompanyReactContext)
    const openNewDocSignModal = () => {
        setNewDocSignModalOpen(true)
    }
    const closeNewDocSignModal = () => {
        setNewDocSignModalOpen(false)
    }

    const filterOnlySignDocTypesWithValidFeatureFlags = () => {
        const newValidSignDocTypes = filter(validSignDocTypes, (signDocType) => {
            return companyContext.featureFlags[signDocTypeToFeatureFlagMap[signDocType]]
        })
        setValidSignDocTypesState(newValidSignDocTypes)
        if (newValidSignDocTypes.length === 0) {
            return
        }
        if (!newValidSignDocTypes.includes(signDocTypeState)) {
            setSignDocTypeState(newValidSignDocTypes[0])
        }
    }

    useEffect(() => {
        filterOnlySignDocTypesWithValidFeatureFlags()
    }, [companyContext.featureFlags])

    if (!validSignDocTypesState || !validSignDocTypesState.length) {
        return ""
    }
    return <div className="CreateNewDocSignButton">
        <Button color={buttonColors.GREY}
                text={buttonText || i18n.translate('newDocSign.buttonTitle')}
                icon={<NoteAddIcon/>}
                onClick={openNewDocSignModal}/>
        {newDocSignModalOpen && <CreateEditNewSignDocModal
            forKnowledgeCenter={forKnowledgeCenter}
            closeNewDocSignModal={closeNewDocSignModal}
            newDocSignModalOpen={newDocSignModalOpen}
            signDocType={signDocTypeState}
            validSignDocTypes={validSignDocTypesState}
            onAfterEditComplete={onTriggerSignDocRefresh}/>}
    </div>
}