import React from 'react';
import './SelectBox.scss';
import {FormControl, InputLabel, Select} from '@mui/material'
import {ThemeProvider} from "@mui/material/styles";
import {InputTheme} from "../InputTheme";
import _ from "lodash";
import {i18n} from "../../../Utils/i18n/i18n";

export const SelectBox = ({className, label, value, onChange, options, inputProps, disabled, required}) => {

    return <ThemeProvider theme={InputTheme}>
        <div className={`select-box ${className}`}>
            <FormControl>
                <InputLabel id={`label-${label}`}>{label}</InputLabel>
                <Select
                    labelId={`label-${label}`}
                    label={label}
                    native
                    MenuProps={{
                        variant: 'menu'
                    }}
                    disabled={disabled}
                    value={value}
                    className="select-input"
                    inputProps={inputProps}
                    onChange={onChange}>
                    {_.map(options, (option) => {
                        return <option
                            disabled={option.disabled}
                            title={option.textOnHover}
                            key={option.value}
                            value={option.value}>
                            {option.text || i18n.translate(option.textKey)}
                        </option>
                    })}
                </Select>
            </FormControl>
    </div>
</ThemeProvider>

}




