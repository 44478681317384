import {filter} from 'lodash'
import homeIcon from '../../../assets/images/home-icon.png'
import chartIcon from '../../../assets/images/chart-icon.png'
import coursesIcon from '../../../assets/images/courses-icon.png'
import usersIcon from '../../../assets/images/users-icon.png'
import settingsIcon from '../../../assets/images/settings-icon.png'
import beeIcon from '../../../assets/images/icon-bee.png'
import filesIcon from '../../../assets/images/icon-files.png'
import {FeatureFlags} from "../../../Utils/Constants";

export const getMenuButtons = ({adminOrgsLength, isSuperAdmin, isCompanyAdmin, featureFlags}) => {
    const hasAdminOrgs = adminOrgsLength && adminOrgsLength > 0
    let menuItems = [
        {
            nameKey: 'menuItems.home',
            icon: (selected) => <img src={homeIcon} alt=""/>,
            link: '/home',
            detectSelected: ['home'],
            show: true,

        },
        {
            nameKey: 'menuItems.learningCenter',
            icon: (selected) => <img src={coursesIcon} alt=""/>,
            link: '/learningCenter/myTasks',
            detectSelected: ['learningCenter'],
            show: !isSuperAdmin
        },
        {
            nameKey: 'menuItems.knowledgeCenter',
            icon: (selected) => <img src={coursesIcon} alt=""/>,
            link: '/knowledgeCenter',
            detectSelected: ['knowledgeCenter'],
            show: !isSuperAdmin,
            requiredFeatureFlags: [FeatureFlags.KNOWLEDGE_CENTER]
        },
        {
            nameKey: 'menuItems.learningManagement',
            icon: (selected) => <img src={beeIcon} alt=""/>,
            link: '/learningManagement/users',
            detectSelected: ['learningManagement'],
            show: (hasAdminOrgs || isCompanyAdmin) && !isSuperAdmin
        },
        {
            nameKey: 'menuItems.reports',
            icon: (selected) => <img src={chartIcon} alt=""/>,
            link: '/reports/dashboard',
            detectSelected: ['reports'],
            show: (hasAdminOrgs || isCompanyAdmin) && !isSuperAdmin
        },
        {
            nameKey: 'menuItems.settings',
            icon: (selected) => <img src={settingsIcon} alt=""/>,
            link: '/settings/general',
            detectSelected: ['settings'],
            show: (hasAdminOrgs || isCompanyAdmin) && !isSuperAdmin
        },
        {
            nameKey: 'menuItems.superAdmin.customers',
            icon: (selected) => <img src={usersIcon} alt=""/>,
            link: '/nihol/customers',
            detectSelected: ['nihol/customers'],
            show: isSuperAdmin
        },
        {
            nameKey: 'menuItems.superAdmin.courses',
            icon: (selected) => <img src={beeIcon} alt=""/>,
            link: '/nihol/courses',
            detectSelected: ['nihol/courses'],
            show: isSuperAdmin
        },
        {
            nameKey: 'menuItems.superAdmin.users',
            icon: (selected) => <img src={usersIcon} alt=""/>,
            link: '/nihol/users',
            detectSelected: ['nihol/users'],
            show: isSuperAdmin
        },
        {
            nameKey: 'menuItems.superAdmin.organizations',
            icon: (selected) => <img src={usersIcon} alt=""/>,
            link: '/nihol/organizations',
            detectSelected: ['nihol/organizations'],
            show: isSuperAdmin
        }
    ]
    return filter(menuItems, (item) => {
        if(item.requiredFeatureFlags){
            const hasAllFeatureFlags = item.requiredFeatureFlags.every((featureFlag) => featureFlags[featureFlag])
            return hasAllFeatureFlags && item.show
        }
        else {
            return item.show
        }
    })
}