import React, {useContext} from "react";
import {Button as MaterialButton} from '@mui/material';
import './Button.scss'
import {ButtonTheme} from "../../Pickers/InputTheme";
import CheckIcon from '@mui/icons-material/DoneTwoTone';
import ErrorIcon from '@mui/icons-material/ErrorTwoTone';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {ThemeProvider} from "@mui/material/styles";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {UserReactContext} from "../../../contexts/UserContext";

export const Button = ({
                         text, onClick, type, component, disabled, style, className, size = null,
                         icon, color = "primary", iconRight = false, variant = "contained", loading = false, actionSucceeded, actionSucceededText, actionFailed,
                         disableWithoutPermissions, hideWithoutPermissions, withArrowIcon, customRef
                     }) => {
    const userContext = useContext(UserReactContext)
    if (disableWithoutPermissions && !disabled)  {
        disabled = !userContext.canUserPerformAction(disableWithoutPermissions, 'and')
    }
    if (hideWithoutPermissions) {
        if (!userContext.canUserPerformAction(hideWithoutPermissions, 'and')) {
            return ''
        }
    }
    const onClickInner = (event) => {
        if (!loading) {
            onClick(event)
        }
    }
    return <ThemeProvider
        theme={ButtonTheme({size, color})}>
        <MaterialButton
            component={component}
            className={`mui-button ${className || ''}`}
            disabled={disabled} type={type}
            onClick={onClickInner}
            variant={variant}
            ref={customRef}
            color="primary">
            <div className={`mui-button-inner ${size}`}  style={style} >
                {!actionSucceeded && !actionFailed ? <>
                        {icon && !iconRight && <div className="button-icon">{icon}</div>}
                        {text && <div className="button-text">{text}</div>}
                        {icon && iconRight && <div className={`button-icon ${iconRight ? 'right' : ''}`}>{icon}</div>}
                        {loading ? <LoadingSpinner color="white"/> : ''}
                        {withArrowIcon ? <div className="button-icon arrow-icon"><ArrowDropDownIcon/> </div>: ''}
                    </> :
                    <>
                        {<div className="button-icon">{actionSucceeded ? <CheckIcon/> : (actionFailed ?
                            <ErrorIcon/> : '')}</div>}
                        {text && <div
                            className="button-text">{actionSucceeded ? (actionSucceededText || 'Done!') : (actionFailed ? `Error-${text}` : '')}</div>}
                    </>}

            </div>

        </MaterialButton>
    </ThemeProvider>
}

