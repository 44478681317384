import './ValuesBar.scss'
import {useEffect, useState} from "react";
import {map} from "lodash";

/**
 * value example:
 * {
 *     value: 20,
 *     label: 'label'
 *     color: 'red'
 *     tooltip: 'tooltip'
 *     onClick: () => {}
 *     onHover: () => {}
 *     onLeave: () => {}
 *     className: 'className'
 * }
 * */

export const ValuesBar = ({values, showPercent, overallWidthPercent = 100, small}) => {
    const [isLoading, setIsLoading] = useState(true)
    const [valuesParams,setValuesParams] = useState()

    const calculateParams = () => {
        const totalValue = values.reduce((acc, value) => acc + value.value, 0)

        const newValuesParams = map(values, (value, index) => {
            const widthPercent = (value.value / totalValue) * overallWidthPercent
            return {
                value: value.value,
                widthPercent,
                colorClass: value.colorClass,

            }
        })
        setValuesParams(newValuesParams)
    }
    useEffect(() => {
        calculateParams()
    }, [values])

    return <div className={`ValuesBar ${small ? 'small' : ''}`}>
        {/*<div className="barWrapper">*/}
            {valuesParams && map(valuesParams, (value, index) => {
                const percentValue = `${value.widthPercent.toFixed(1)}%`
                return <div className={`barValue ${value.colorClass}`} style={{width: `${value.widthPercent}%`}} title={showPercent ? percentValue : value.value}>
                    {showPercent && value.widthPercent > 10 ? <div className="barValueText">{percentValue}</div> : ''}
                    {!showPercent && value.widthPercent > 5 ? <div className="barValueText">{value.value}</div> : ''}
                </div>
            })}
        {/*</div>*/}
    </div>
}