import React from "react";

export const GridReactContext = React.createContext({
    refreshKey: 0,
    filterDisabled: {},
    filterModel: {}, //only used for communication between components
    sortModel: {}, //only used for communication between components
    selectionModel: [],
    paginationModel: {},
    triggerTableRefresh: () => {
    },
    modifyFilterDisabled: () => {
    },
    updateFilterModel: () => {
    },
    getFilterModel: () => {
    },
    setSelectionModelToContext: () => {}
});


export const GridReactContextProvider = ({children, initFilterDisabled}) => {
    const [refreshKey, setRefreshKey] = React.useState(0)
    const [filterDisabled, setFilterDisabled] = React.useState(initFilterDisabled || {})
    const [filterModel, setFilterModel] = React.useState({})
    const [sortModel, setSortModel] = React.useState({})
    const [selectionModel, setSelectionModel] = React.useState([])
    const [paginationModel, setPaginationModel] = React.useState({})



    const triggerTableRefresh = () => {
        setRefreshKey(refreshKey + 1)
    }
    const modifyFilterDisabled = ({filterName, disabled, disabledNote}) => {
        setFilterDisabled({...filterDisabled, [filterName]: {disabled, disabledNote}})
    }
    const updateFilterModel = (filterModel) => {
        setFilterModel(filterModel)
    }
    const getFilterModel = ({companies}) => {
        return filterModel
    }
    const updateSortModel = (sortModel) => {
        setSortModel(sortModel)
    }
    const updateSelectionModel = (selectionModel) => {
        setSelectionModel(selectionModel)
    }
    const updatePaginationModel = (paginationModel) => {
        setPaginationModel(paginationModel)
    }
    const setSelectionModelToContext = (selectionModel) => {
        setSelectionModel(selectionModel)
    }

    return <GridReactContext.Provider value={{
        refreshKey,
        setSelectionModelToContext,
        triggerTableRefresh,
        modifyFilterDisabled,
        filterDisabled,
        filterModel,
        updateFilterModel,
        sortModel,
        updateSortModel,
        paginationModel,
        updatePaginationModel,
        selectionModel,
        updateSelectionModel,
    }}>
        {children}
    </GridReactContext.Provider>
}
