import React from 'react';
import Helmet from 'react-helmet';
import {useLocation,} from "react-router-dom";

export const HTMLTitle = (props) => {
    let location = useLocation();
    const rootTitle = 'WeezBee'
    const pathArtifacts = location.pathname.split('/')
    return (
        <Helmet>
            <title>{rootTitle}</title>
        </Helmet>
    );
};

