import {Button} from "../../Form/Button/Button";
import {buttonColors} from "../../Pickers/InputTheme";
import {i18n} from "../../../Utils/i18n/i18n";
import React, {useContext, useState} from "react";
import {GridReactContext} from "../../../Utils/DataGridWrapper/GridContext";
import './RemoveUserCell.scss'
import {Modal} from "../../Smart/Modal/Modal";
import {removeUserAPI} from "../../../Services/api";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export const RemoveUserCell = ({user: {id: userId, firstName, lastName, isCompanyAdmin}}) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const gridContext = useContext(GridReactContext)

    const removeUser = async () => {
        await removeUserAPI({userId})
        gridContext.triggerTableRefresh()
        setIsModalOpen(false)
    }
    const onOpenModal = () => {
        setIsModalOpen(true)
    }
    const onModalClose = () => {
        setIsModalOpen(false)
    }
    const userName = `${firstName} ${lastName}`
    return <div className="removeUserCell">

        {!isCompanyAdmin ? <Button color={buttonColors.GREY}
                 size="small"
                 icon={<DeleteForeverIcon/>}
                 // text={i18n.translate('createEditUserModal.deleteUserButton')}
                 onClick={onOpenModal}/> : i18n.translate('createEditUserModal.userIsCompanyAdmin')}
        {isModalOpen ? <Modal open={isModalOpen} onClose={onModalClose}>
            <div className="removeUserModal modalWrapper">
                <div
                    className="modalTitle">{i18n.translate('createEditUserModal.deleteUserTitle')} {userName}</div>
                <div
                    className="modalContent">{i18n.translate('createEditUserModal.deleteUserText')} {userName}?
                </div>
                <div className="modalFooter">
                    <Button color={buttonColors.GREY}
                            text={i18n.translate('createEditUserModal.deleteOrgConfirm')}
                            onClick={removeUser}/>
                    <Button color={buttonColors.YELLOW}
                            text={i18n.translate('general.cancel')}
                            onClick={onModalClose}/>
                </div>
            </div>
        </Modal> : ""}
    </div>
}