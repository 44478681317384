import {GeneralSettings} from "../GeneralSettings/GeneralSettings";
import {RoutingTabs} from "../../../Utils/RoutingTabs/RoutingTabs";
import {i18n} from "../../../Utils/i18n/i18n";
import {Reminders} from "../Reminders/Reminders";

export const SettingsTabs = () => {
    const tabs = [
        { label: i18n.translate('settingsPage.generalSettings.title'), subPath: 'general', element: <GeneralSettings /> },
        { label: i18n.translate('settingsPage.reminders.title'), subPath: 'reminders', element: <Reminders/> },
        // { label: i18n.translate('settingsPage.users.title'), subPath: 'users', element: <Box>Users</Box> },

    ]
    return <RoutingTabs tabs={tabs} baseRoute={'settings'}  />

}