import React from "react";

import EditIcon from '@mui/icons-material/Edit';
import './SAEditUserCell.scss'
import {GridReactContext} from "../../../../../Utils/DataGridWrapper/GridContext";
import {SACreateEditUserModal} from "../SACreateEditUserModal/SACreateEditUserModal";
import {Button} from "../../../../Form/Button/Button";
import {buttonColors} from "../../../../Pickers/InputTheme";
import {i18n} from "../../../../../Utils/i18n/i18n";
import {get} from "lodash";

export const SAEditUserCell = ({user}) => {
    const [createEditUserModalOpen, setCreateEditUserModalOpen] = React.useState(false)
    const gridContext = React.useContext(GridReactContext)
    const onSuccessCreateEditUser = () => {
        setCreateEditUserModalOpen(false)
        gridContext.triggerTableRefresh()
    }
    const editUser = () => {
        setCreateEditUserModalOpen(true)
    }
    const closeCreateEditUserModal = () => {
        setCreateEditUserModalOpen(false)
    }
    const isSuperAdmin = get(user, 'roles.SuperAdmin')
    return <div className="SAEditUserCell">
        <Button color={buttonColors.GREY}
                size="small"
                disabled={user.deletedAt || isSuperAdmin}
                icon={<EditIcon/>}
                text={i18n.translate('superAdmin.allUsersTable.buttons.edit')}
                onClick={editUser}/>
        {createEditUserModalOpen &&
            <SACreateEditUserModal editMode userId={user.id} closeCreateEditUserModal={closeCreateEditUserModal}
                                   createEditUserModalOpen={createEditUserModalOpen}
                                   onSuccess={onSuccessCreateEditUser}/>}
    </div>
}