import './NotificationAutomationButton.scss';
import {i18n} from "../../../Utils/i18n/i18n";
import {CreateAutomationModal} from "./CreateAutomationModal/CreateAutomationModal";
import {useContext, useState} from "react";
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import {Colors} from "../../../Utils/styles/Colors";
import {CompanyReactContext} from "../../../contexts/CompanyContext";
import {FeatureFlags} from "../../../Utils/Constants";

export const NotificationAutomationButton = ({fullHeight}) => {
    const [automationModalOpen, setAutomationModalOpen] = useState(false)
    const companyContext = useContext(CompanyReactContext)
    const openAutomationModal = () => {
        setAutomationModalOpen(true)
    }
    const closeAutomationModal = () => {
        setAutomationModalOpen(false)
    }
    if(!companyContext.featureFlags[FeatureFlags.AUTOMATION]){
        return ""
    }
    return <div className={`NotificationAutomationButton ${fullHeight ? 'fullHeight' : ''}`} >
        <div className="clickable" onClick={openAutomationModal} >
            <div className="bell-icon">
                <MoreTimeIcon sx={{color: Colors['$menuItemIcon'], fontSize: '50px'}}/>
            </div>
            <div className="rectangle">
                <div className="title">{i18n.translate('automations.notifications.title')}</div>
                <div className="sub-title">{i18n.translate('automations.notifications.subTitle')}</div>
            </div>
        </div>

        {automationModalOpen ? <CreateAutomationModal key="createAutomation" automationModalOpen={automationModalOpen}
                                                      closeAutomationModal={closeAutomationModal}/> : ''}
    </div>
}