import {DatePicker} from "../../Pickers/DatePicker/DatePicker";
import {i18n} from "../../../Utils/i18n/i18n";
import {Modal} from "../../Smart/Modal/Modal";
import React, {useEffect} from "react";
import {Button} from "../../Form/Button/Button";
import {buttonColors} from "../../Pickers/InputTheme";
import './SelectFinishDateForOrgModal.scss'
import {getGMTOffset} from "../../../Utils/Date/Date";
import {fromZonedTime, toDate, toZonedTime} from "date-fns-tz";

export const SelectFinishDateForOrgModal = ({organizationId, onSave, onClose, availableOrgs}) => {
    const [finishDate, setFinishDate] = React.useState(new Date())
    const [timezone, setTimezone] = React.useState()
    const innerOnSave = () => {
        // Convert the finishDate to the organization's timezone
        const org = availableOrgs.find(org => org.id === organizationId)
        const zonedDate = toZonedTime(finishDate, org.timezone);

        // Set the time to 23:59:59:999
        zonedDate.setHours( 23, 59, 59, 999);

        // Convert the date back to UTC
        const utcDate = fromZonedTime(zonedDate, org.timezone);

        onSave({organizationId, finishDate: toDate(utcDate)});
    }
    useEffect(() => {
        //find the organization in availableOrgs
        const org = availableOrgs.find(org => org.id === organizationId)
        setTimezone(getGMTOffset(org.timeZone))
    }, [])


    return <Modal open={organizationId} onClose={onClose} key={organizationId}>
        <div className="SelectFinishDateForOrgModal modalWrapper">
            <div className="modalTitle">
                <div>{i18n.translate('editCourseModal.dateModal.title')}</div>
            </div>
            <div className="modalContent">
                <div className="dateAndTZWrapper">
                    <DatePicker onChange={setFinishDate} value={finishDate}/>
                    <div
                        className="timezone">{i18n.translate('editCourseModal.dateModal.timeAndTimezone')} {timezone}</div>
                </div>
                <div>{i18n.translate('editCourseModal.edit.dateEditNotification')}</div>
            </div>
            <div className="modalFooter">
                <Button
                    color={buttonColors.YELLOW}
                    onClick={onClose}
                    text={i18n.translate('general.close')}
                />
                <Button
                    color={buttonColors.GREY}
                    onClick={innerOnSave}
                    text={i18n.translate('confirm')}
                />
            </div>
        </div>

    </Modal>
}