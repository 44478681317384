import React, {useContext} from "react";
import {Button as MaterialButton} from '@mui/material';
import './Button.scss'
import {ButtonTheme} from "../../Pickers/InputTheme";
import {UserReactContext} from "../../../contexts/UserContext";
import {ThemeProvider} from "@mui/material/styles";

export const UploadButton = ({text, uniqId, accept="*", onInputChange, type, component='span', disabled, style, className, icon, color = "primary", iconRight = false, variant = "contained", size,
                          disableWithoutPermissions, hideWithoutPermissions}) =>{
    const userContext = useContext(UserReactContext)

    if(disableWithoutPermissions && !disabled){
        disabled = !userContext.canUserPerformAction(disableWithoutPermissions, 'and')
    }
    if(hideWithoutPermissions && !disabled){
        if(!userContext.canUserPerformAction(hideWithoutPermissions, 'and')){
            return ''
        }
    }
    return <ThemeProvider theme={ButtonTheme({color, size})}>
        <input
            accept={accept}
            style={{display: 'none'}}
            id={uniqId}
            multiple
            type="file"
            onChange={onInputChange}
        />
        <label htmlFor={uniqId}>
            <MaterialButton
                component={component}
                className={`mui-button ${className || ''}`}
                disabled={disabled} type={type}
                variant={variant}
                color="primary">
                <div className="mui-button-inner" style={style}>
                    {icon && !iconRight && <div className="button-icon">{icon}</div>}
                    {text && <div className="button-text">{text}</div>}
                    {icon && iconRight && <div className={`button-icon ${iconRight ? 'right' : ''}`}>{icon}</div>}
                </div>

            </MaterialButton>
        </label>
    </ThemeProvider>
}

