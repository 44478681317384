import {i18n} from "../../../../Utils/i18n/i18n";
import {get, map} from 'lodash'
import React, {useEffect, useState} from "react";
import {DataGridWrapper} from "../../../../Utils/DataGridWrapper/DataGridWrapper";
import {getCompanyAutomations} from "../../../../Services/api";
import {useUserAdminOrgs} from "../../../Hooks/UseUserAdminOrgs";
import LoadingSpinner from "../../../LoadingSpinner/LoadingSpinner";
import {DeleteAutomationCell} from "../DeleteAutomationCell/DeleteAutomationCell";
import {EditAutomationCell} from "../EditAutomationCell/EditAutomationCell";
import {
    CreateAutomationModal
} from "../../../Notifications/NotificationAutomationButton/CreateAutomationModal/CreateAutomationModal";
import ScheduleIcon from '@mui/icons-material/Schedule';
import SendIcon from '@mui/icons-material/Send';
import './AutomationsTable.scss'

const getAutomationsColumns = ({onAutomationSelectedForEdit}) => {

    return [
        {
            field: 'automationName',
            headerName: i18n.translate("automationsTable.columns.automationName"),
            minWidth: 150,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}}>{params.value}</div>,
        },
        {
            field: 'description',
            headerName: i18n.translate("automationsTable.columns.description"),
            minWidth: 200,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        {
            field: 'organizationName',
            headerName: i18n.translate("automationsTable.columns.organization"),
            minWidth: 150,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        {
            field: 'courseName',
            headerName: i18n.translate("automationsTable.columns.task"),
            minWidth: 150,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        {
            field: 'when',
            headerName: i18n.translate("automationsTable.columns.when"),
            minWidth: 300,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        {
            field: 'sendType',
            headerName: i18n.translate("automationsTable.columns.how"),
            minWidth: 150,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        {
            field: 'status',
            headerName: i18n.translate("automationsTable.columns.status"),
            minWidth: 130,
            align: 'right',
            sortable: false,
            renderCell: (params) => <StatusCol status={params.value}/>,
        },
        {
            field: 'delete',
            headerName: i18n.translate("automationsTable.columns.delete"),
            minWidth: 100,
            align: 'right',
            sortable: false,
            renderCell: (params) => <DeleteAutomationCell automation={params.row}/>,
        },
        {
            field: 'edit',
            headerName: i18n.translate("automationsTable.columns.edit"),
            minWidth: 100,
            align: 'right',
            sortable: false,
            renderCell: (params) => <EditAutomationCell automation={params.row} onAutomationSelectedForEdit={onAutomationSelectedForEdit}/>,
        }
    ];
}

const tableFilters = {
    showFilters: {
        organizations: true,
        search: true,
    },
    filterValues: {},
    filterData: {}
}
const StatusCol = ({status}) => {
    const textStatus = i18n.translate(`automationsTable.status.${status}`)
    // if(automation.status === 'COMPLETED' && get(automation, 'additionalData.sentMessages')){
    //     const sent = get(automation, 'additionalData.sentMessages')
    //     const notSent =get(automation, 'additionalData.notSentMessages')
    //     return textStatus + ` (${sent}/${sent + notSent})`
    // }
    return <div className={`statusCol ${status}`} style={{width: '100%'}}>
        <div className="statusCol-token">
            <div className="statusInCol">{textStatus}</div> {status === 'SCHEDULED' ? <ScheduleIcon stye={{marginLeft: '6px'}} /> : <SendIcon style={{marginLeft: '6px'}} />}
        </div>

    </div>
}

const convertData = (automations) => {
    return map(automations, (automation) => {
        return {
            ...automation,
            automationName: get(automation, 'name'),
            description: get(automation, 'description'),
            organizationName: get(automation, 'organizationName'),
            courseName: get(automation, 'courseName'),
            sendType: i18n.translate(`automationsTable.sendType.${get(automation, 'type')}`),
            when: `${i18n.translate('automationsTable.atHour')}: ${get(automation, 'runByEventRelativeTime.runHour')}, ${get(automation, 'runByEventRelativeTime.runDaysBefore')} ${i18n.translate('automationsTable.daysBefore')}`
        }
    })
}

const paginationModel = {
    page: 0,
    pageSize: 20,
}

export const AutomationsTable = () => {
    const {adminOrganizationOptions, adminOrganizations, isOrgsLoading} = useUserAdminOrgs({withAllOption: true})
    const [localTableFilters, setLocalTableFilters] = useState()

    const [editAutomationModalOpen, setEditAutomationModalOpen] = useState(false)
    const [editAutomation, setEditAutomation] = useState(null)
    const onAutomationSelectedForEdit = (automation) => {
        setEditAutomation(automation)
    }
    const closeEditAutomationModal = () => {
        setEditAutomation(null)
        setEditAutomationModalOpen(false)
    }
    useEffect(() => {
        if(!editAutomation){
            return
        }
        setEditAutomationModalOpen(true)
    }, [editAutomation]);
    useEffect(() => {
        if(isOrgsLoading){
            return
        }
        setLocalTableFilters({
            ...tableFilters,
            filterData: {
                ...tableFilters.filterData,
                organizations: adminOrganizationOptions
            },
            filterValues: {
                ...tableFilters.filterValues,
                organizationId: get(adminOrganizationOptions, '[0].value')
            }
        })

    }, [isOrgsLoading]);
    const getColumns = async ({paginationModel, filterModel}) => {
        return getAutomationsColumns({onAutomationSelectedForEdit})
    }
    const apiToGetData = async ({paginationModel, filterModel}) => {
        return getCompanyAutomations({
            paginationModel,
            filterModel
        })
    }
    if(!localTableFilters){
        return <LoadingSpinner/>
    }
    return <><DataGridWrapper
            filterModel={localTableFilters}
            disableColumnFilter
            paginationModel={paginationModel}
            getColumns={getColumns}
            apiToGetData={apiToGetData}
            dataConverter={convertData}
        />
        {editAutomationModalOpen && editAutomation ? <CreateAutomationModal key="automationEdit"
                                                      automationModalOpen={editAutomationModalOpen}
                                                      automation={editAutomation}
                                                      closeAutomationModal={closeEditAutomationModal}/> : ""}
    </>
}