import React, {useState} from "react";
import {i18n} from "../../i18n/i18n";
import {get} from "lodash";
import {SelectBox} from "../../../Components/Pickers/SelectBox/SelectBox";


export const OrganizationFilter = ({filterModel, setFilterModel}) => {
    const [selectedOrganization, setSelectedOrganization] = useState(get(filterModel, 'filterValues.organizationId', ''))
    const onSelectOrgChange = (e) => {
        setSelectedOrganization(e.target.value)
        const newFilterModel = {
            ...filterModel,
            filterValues: {
                ...filterModel.filterValues,
                organizationId: e.target.value
            }
        }
        setFilterModel(newFilterModel)
    }
    return <div className="searchFilter">
        <SelectBox label={i18n.translate('dataGrid.filterLabels.organization')} options={get(filterModel, 'filterData.organizations', [])} value={selectedOrganization} onChange={onSelectOrgChange}/>
    </div>

}