import {i18n} from "../Utils/i18n/i18n"
import {filter, get} from "lodash"
import {SignDocTypes} from "../Components/SignDoc/SignDocLib";

export const userChartLabels = [
    i18n.translate('home.userChartLabels.finishedCourses'),
    i18n.translate('home.userChartLabels.inProgressCourses'),
    i18n.translate('home.userChartLabels.notStartedCourses')
]

export const calcUserChartData = ({signDocs}) => {
    const finished = get(filter(signDocs, signDoc => {

        if(signDoc.signDoc.type === SignDocTypes.REGULAR_COURSE){
            return signDoc.progressPercent && signDoc.progressPercent === 100 && signDoc.completedOnDate
        }
        else{
            return signDoc.signed && signDoc.completedOnDate
        }

    }), 'length', 0)
    const inProgress = get(filter(signDocs, signDoc => {
        if(signDoc.signDoc.type === SignDocTypes.REGULAR_COURSE){
            return signDoc.progressPercent && signDoc.progressPercent > 0 && signDoc.progressPercent < 100
        }
        else{
            return signDoc.viewed > 0 && !signDoc.signed
        }
    }), 'length', 0)
    const notStarted = get(filter(signDocs, signDoc => {
        if(signDoc.signDoc.type === SignDocTypes.REGULAR_COURSE){
            return !signDoc.progressPercent
        }
        else{
            return !signDoc.viewed && !signDoc.signed
        }
    }), 'length', 0)
    return [finished, inProgress, notStarted]
}