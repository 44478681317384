import {Button} from "../../Form/Button/Button";
import {buttonColors} from "../../Pickers/InputTheme";
import {i18n} from "../../../Utils/i18n/i18n";
import React from "react";
import {CreateEditUserModal} from "../CreateEditUserModal/CreateEditUserModal";
import {GridReactContext} from "../../../Utils/DataGridWrapper/GridContext";
import EditIcon from '@mui/icons-material/Edit';
import './EditUserCell.scss'

export const EditUserCell = ({user: {id: userId, isCompanyAdmin}}) => {
    const [createEditUserModalOpen, setCreateEditUserModalOpen] = React.useState(false)
    const gridContext = React.useContext(GridReactContext)
    const onSuccessCreateEditUser = () => {
        setCreateEditUserModalOpen(false)
        gridContext.triggerTableRefresh()
    }
    const editUser = () => {
        setCreateEditUserModalOpen(true)
    }
    const closeCreateEditUserModal = () => {
        setCreateEditUserModalOpen(false)
    }
    return <div className="EditUserCell">
        {!isCompanyAdmin ? <Button color={buttonColors.GREY}
                 size="small"
                 icon={<EditIcon/>}
                 // text={i18n.translate('createEditUserModal.editUser')}
                 onClick={editUser}/> : i18n.translate('createEditUserModal.userIsCompanyAdmin')}
        {createEditUserModalOpen && <CreateEditUserModal editMode userId={userId} closeCreateEditUserModal={closeCreateEditUserModal} createEditUserModalOpen={createEditUserModalOpen} onSuccess={onSuccessCreateEditUser}/>}
    </div>
}