import MaterialModal from '@mui/material/Modal';
import './Modal.scss'
import {Box} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const boxStyle = {
    borderRadius:'12px',
    boxShadow: 24,
    p: 3,
    bgcolor: '#fff',
    position: 'relative'
}

export const Modal = ({open, onClose, children}) => {

    return <MaterialModal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <div className="modalAbsoluteWrapper" >
            <Box sx={boxStyle}>
                <div className="ModalX" onClick={onClose}><CloseIcon/></div>
                <div className="ModalBox">
                    {children}
                </div>
            </Box>
        </div>

    </MaterialModal>
}