import {Button} from "../../../../Form/Button/Button";
import {buttonColors} from "../../../../Pickers/InputTheme";
import {i18n} from "../../../../../Utils/i18n/i18n";
import React, {useContext, useState} from "react";
import {SA_removeOrganizationAPI} from "../../../../../Services/SA_api";
import {GridReactContext} from "../../../../../Utils/DataGridWrapper/GridContext";
import './RemoveOrgCell.scss'
import {Modal} from "../../../../Smart/Modal/Modal";

export const RemoveOrgCell = ({organizationId, organizationName, usersCount, hasCourses}) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const gridContext = useContext(GridReactContext)

    const removeOrg = async () => {
        await SA_removeOrganizationAPI({organizationId})
        gridContext.triggerTableRefresh()
    }
    const onOpenModal = () => {
        setIsModalOpen(true)
    }
    const onModalClose = () => {
        setIsModalOpen(false)
    }
    return <div className="removeOrgCell">

        <Button color={buttonColors.GREY}
                 size="small"
                 text={i18n.translate('superAdmin.allOrgsTable.deleteOrg')}
                 onClick={onOpenModal}/>
        {isModalOpen ? <Modal open={isModalOpen} onClose={onModalClose}>
            <div className="removeOrgModal modalWrapper">
                <div className="modalTitle">{i18n.translate('superAdmin.allOrgsTable.deleteOrgTitle')} {organizationName}</div>
                <div className="modalContent">
                    {i18n.translate('superAdmin.allOrgsTable.deleteOrgText')} "{organizationName}" ?
                </div>
                <div className="modalFooter">
                    <Button color={buttonColors.YELLOW}
                            size="small"
                            text={i18n.translate('general.cancel')}
                            onClick={onModalClose}/>
                    <Button color={buttonColors.GREY}
                            size="small"
                            text={i18n.translate('superAdmin.allOrgsTable.deleteOrgConfirm')}
                            onClick={removeOrg}/>
                </div>
            </div>
        </Modal> : ""}
    </div>
}