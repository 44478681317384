import './MySignDocs.scss'
import React, {useContext, useEffect, useState} from "react";
import {getUserSignDocsAPI} from "../../Services/api";
import {filter, get, isEmpty, map, orderBy} from "lodash";
import {i18n} from "../../Utils/i18n/i18n";
import {SignDocCard} from "../../Components/SignDoc/SignDocCard/SignDocCard";
import {CompanyReactContext} from "../../contexts/CompanyContext";
import {SignDocTypes} from "../../Components/SignDoc/SignDocLib";

export const MySignDocs = ({types, referAsCourses, showForceCompletion = false, showNonForceCompletion = false}) => {
    const [completedSignDocs, setCompletedSignDocs] = useState()
    const [signDocsToDo, setSignDocsToDo] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const companyContext = useContext(CompanyReactContext)

    const initSignDocs = async () => {
        const userSignDocs = await getUserSignDocsAPI({types: types, showForceCompletion, showNonForceCompletion})

        const completedSignDocs = orderBy(filter(userSignDocs, (signDoc) => {
            if(get(signDoc, 'signDoc.type') === SignDocTypes.REGULAR_COURSE){
                return signDoc.progressPercent === 100 && signDoc.completedOnDate
            }
            else{
                return signDoc.signed && signDoc.completedOnDate
            }
        }), ['completedOnDate'], ['desc'])
        const signDocsToDo = orderBy(filter(userSignDocs, (signDoc) => {
            if(get(signDoc, 'signDoc.type') === SignDocTypes.REGULAR_COURSE){
                return signDoc.progressPercent < 100
            }
            else {
                return !signDoc.signed
            }
        }), ['finishByDate'], ['asc'])
        setCompletedSignDocs(completedSignDocs)
        setSignDocsToDo(signDocsToDo)

    }


    const init = async () => {
        initSignDocs()
        setIsLoading(false)
    }
    //run init every 5 seconds
    useEffect(() => {
        if(isEmpty(companyContext.featureFlags)){
            return
        }
        init()
        const interval = setInterval(() => {
            init()
        }, 60000);
        return () => clearInterval(interval);
    }, [companyContext.featureFlags]);

    return <div className="MySignDocs">

            <div className="PageSecondaryTitle signDocsTitle">
                {i18n.translate(`learningCenter.${referAsCourses ? 'coursesToDo' : 'signDocsToDo'}`)}
            </div>
            <div className="signDocsSection">
                {!signDocsToDo || signDocsToDo.length === 0 ?
                    <div className="noSignDocsToDo">{ i18n.translate(`learningCenter.${referAsCourses ? 'noCoursesToDo' : 'noSignDocsToDo'}`)}</div> : ''}
                <div className="SignDocsWrapper">
                    {map(signDocsToDo, (signDoc) => {
                        const finishByDate = new Date(signDoc.finishByDate).toLocaleDateString('he-IL')
                        return <div className="signDocWrapper" key={signDoc.id}>
                            <SignDocCard {...{...signDoc, ...signDoc.signDoc}} finishByDate={finishByDate} key={signDoc.id}/>
                        </div>
                    })}
                </div>

            </div>
            <div className="PageSecondaryTitle signDocsTitle">
                {i18n.translate(`learningCenter.${referAsCourses ? 'coursesCompleted' : 'signDocsCompleted'}`)}
            </div>
            <div className="signDocsSection">
                {!completedSignDocs || completedSignDocs.length === 0 ?
                <div className="noSignDocsToDo">{ i18n.translate(`learningCenter.${referAsCourses ? 'noCoursesCompleted' : 'noSignDocsCompleted'}`)}</div> : ''}
                <div className="SignDocsWrapper">
                    {map(completedSignDocs, (signDoc) => {
                        const finishByDate = new Date(signDoc.finishByDate).toLocaleDateString('he-IL')
                        return <div className="signDocWrapper" key={signDoc.id}>
                            <SignDocCard {...{...signDoc, ...signDoc.signDoc}} finishByDate={finishByDate} key={signDoc.id}/>
                        </div>
                    })}
                </div>
            </div>
    </div>
}