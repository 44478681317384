import React, {useContext, useEffect, useState} from "react";
import {UserReactContext} from "../../contexts/UserContext";
import {Navigate} from "react-router-dom";

export const Logout = () => {
    const [isLoggedOut, setIsLoggedOut] = useState(false)
    const userContext = useContext(UserReactContext)
    useEffect(() => {
        userContext.logout({
            callBack: () => {
                setIsLoggedOut(true)
            }
        })
    }, []);
    if (isLoggedOut) {
        return <Navigate to={{pathname: '/login'}}/>
    } else {
        return <div></div>
    }
}