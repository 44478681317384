import './SuperAdminCustomerMgmnt.scss'
import {buttonColors} from "../../Pickers/InputTheme";
import {i18n} from "../../../Utils/i18n/i18n";
import {Button} from "../../Form/Button/Button";
import React from "react";
import {GridReactContextProvider} from "../../../Utils/DataGridWrapper/GridContext";
import {SuperAdminCustomersTable} from "../SuperAdminCustomersTable/SuperAdminCustomersTable";
import {SuperAdminCreateNewCustomerModal} from "../SuperAdminCreateNewCustomer/SuperAdminCreateNewCustomerModal";
import '../SuperAdminPage.scss'

export const SuperAdminCustomerMgmnt = () => {
    const [isCreateNewCustomerModalOpen, setIsCreateNewCustomerModalOpen] = React.useState(false)
    const openCreateNewCustomerModal = () => {
        setIsCreateNewCustomerModalOpen(true)
    }
    const closeCreateNewCustomerModal = () => {
        setIsCreateNewCustomerModalOpen(false)
    }
    return <div className="SuperAdminCustomerMgmnt SuperAdminPage">
        <div className="buttonsWrapper">
            <Button color={buttonColors.GREY}
                // style={{width: '100%'}}
                    text={i18n.translate('superAdmin.createNewCustomer.modalTitle')}
                    onClick={openCreateNewCustomerModal}/>
        </div>
        <GridReactContextProvider>
            <div className="superAdminCustomersTableWrapper">
                <SuperAdminCustomersTable/>
            </div>
            <SuperAdminCreateNewCustomerModal
                createCustomerModalOpen={isCreateNewCustomerModalOpen}
                onUserCreated={closeCreateNewCustomerModal}
                closeCreateCustomerModal={closeCreateNewCustomerModal}/>
        </GridReactContextProvider>
    </div>
}