import {i18n} from "../../../Utils/i18n/i18n";
import {Button} from "../../Form/Button/Button";
import {buttonColors} from "../../Pickers/InputTheme";
import {Modal} from "../Modal/Modal";
import React from "react";
import './confirmationModal.scss'

export const ConfirmationModal = ({confirmationModalOpen, onCloseConfirmationModal, title, message, onConfirm, onCancel, confirmText, cancelText, messageBody}) => {
    return <Modal
        open={confirmationModalOpen}
        onClose={onCloseConfirmationModal}>
        <div className="ConfirmationModal modalWrapper">
            <div className="modalTitle">
                <div>{title}</div>
            </div>
            <div className="modalContent">
                <div className="message">{message}</div>
                {messageBody ? <div className="messageBody">
                        {messageBody}
                </div> : ''}
            </div>
            <div className="modalFooter">
                <Button
                    color={buttonColors.YELLOW}
                    onClick={onCancel}
                    text={cancelText || i18n.translate('confirmationModal.cancel')}
                />
                <Button
                    color={buttonColors.GREY}
                    onClick={onConfirm}
                    text={confirmText || i18n.translate('confirmationModal.confirm')}
                />
            </div>
        </div>
    </Modal>
}