import React, {useEffect} from "react";
import {fetchUserCompanyLogo, getCompanyFeatureFlagsAPI} from "../Services/api";
import {UserReactContext} from "./UserContext";

export const CompanyReactContext = React.createContext({
    companyLogoUrl: '',
    refreshLogoUrl: () => {},
    featureFlags: {}
});


export const CompanyContextProvider = ({children}) => {
    const userContext = React.useContext(UserReactContext)
    const [companyLogoUrl, setCompanyLogoUrl] = React.useState('')
    const [featureFlags, setFeatureFlags] = React.useState({})
    const refreshLogoUrl = async () => {
        setCompanyLogoUrl(`${companyLogoUrl}?${Date.now()}`)
    }
    const refreshFeatureFlags = async () => {
        fetchFeatureFlags()
    }

    const fetchCompanyLogo = async () => {
        const companyLogo = await fetchUserCompanyLogo()
        setCompanyLogoUrl(companyLogo)
    }
    const fetchFeatureFlags = async () => {
        try{
            const flags = await getCompanyFeatureFlagsAPI()
            setFeatureFlags(flags)
        }
        catch(e){
            console.error('Error fetching feature flags', e)
        }
    }
    useEffect(() => {
        if(userContext.isUserValid()){
            fetchCompanyLogo()
        }
    }, [userContext.user]);

    //useEffect to get feature flags every 30 seconds
    useEffect(() => {
        if(userContext.isUserValid()){
            fetchFeatureFlags()
            const interval = setInterval(() => {
                fetchFeatureFlags()
            }, 60000 * 4);
            return () => clearInterval(interval);
        }
    }, [userContext.user]);
    return <CompanyReactContext.Provider value={{companyLogoUrl, refreshLogoUrl, featureFlags, refreshFeatureFlags}}>
        {children}
    </CompanyReactContext.Provider>
}
