import './AdminDashboard.scss'
import React from "react";
import {SignDocsStats} from "../SignDocsStats/SignDocsStats";

export const AdminDashboard = ({types, textType}) => {
    return <div className="AdminDashboard">
        <div className="reportsContent">
            <SignDocsStats types={types} textType={textType}/>
        </div>
    </div>
}