
//this component is the content of a modal to add quota of SMS to a customer

import {FormInput} from "../../../Form/Input/FormInput";
import {Modal} from "../../../Smart/Modal/Modal";
import {Button} from "../../../Form/Button/Button";
import {buttonColors} from "../../../Pickers/InputTheme";
import {i18n} from "../../../../Utils/i18n/i18n";
import {useState} from "react";
import './SACustomerQuotaMngmntModal.scss'
import {SA_setCompanyQuotas} from "../../../../Services/SA_api";

export const SACustomerQuotaMngmntModal = ({addQuotaModalOpen, closeAddQuotaModal, onQuotaAdded, messageQuota, storageQuotaMB, companyId}) => {
    const [messageCredits, setMessageCredits] = useState(messageQuota || 0)
    const [storageQuota, setStorageQuota] = useState(storageQuotaMB || 0)
    const [isLoading, setIsLoading] = useState(false)
    const onMessageCreditsChange = (e) => {
        setMessageCredits(e.target.value)
    }
    const onStorageQuotaMBChange = (e) => {
        setStorageQuota(e.target.value)
    }
    const addQuota = async () => {
        setIsLoading(true)
        await SA_setCompanyQuotas({companyId, smsQuota: messageCredits, storageQuotaMB: storageQuota})
        setIsLoading(false)
        onQuotaAdded()
    }
    return <Modal open={addQuotaModalOpen} onClose={closeAddQuotaModal}>
        <div className="SACreateCustomerQuotaModal modalWrapper">
            <div className="modalTitle">{i18n.translate('superAdmin.customersTable.quota.editQuotaTitle')}</div>
            <div className="modalContent">
                <div className="inputWrapper">
                    <FormInput
                        label={i18n.translate('superAdmin.customersTable.quota.MessagesCreditsToEdit')}
                        className=""
                        type="number"
                        value={messageCredits}
                        onChange={onMessageCreditsChange}
                    />
                </div>
                <div className="inputWrapper">
                    <FormInput
                        label={i18n.translate('superAdmin.customersTable.quota.storageQuotaToEdit')}
                        className=""
                        type="number"
                        value={storageQuota}
                        onChange={onStorageQuotaMBChange}
                    />
                </div>
            </div>
            <div className="modalFooter">
                <Button
                    color={buttonColors.GREY}
                    onClick={addQuota}
                    text={i18n.translate('superAdmin.customersTable.quota.editCredits')}
                />
            </div>
        </div>
    </Modal>
}