import './MgmntSignDocCard.scss'
import '../SignDocCard/SignDocCard.scss';
import {Button} from "../../Form/Button/Button";
import {buttonColors} from "../../Pickers/InputTheme";
import {i18n} from "../../../Utils/i18n/i18n";
import React, {useState} from "react";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {get} from "lodash";


import {ConfirmationModal} from "../../Smart/confirmationModal/confirmationModal";
import {deleteSignDocAPI} from "../../../Services/api";
import {
    CreateEditNewSignDocModal
} from "../../LearningManagement/CreateNewSignDocButton/CreateEditNewSignDocModal/CreateEditNewSignDocModal";
import {SignDocIcons, SignDocTypes} from "../SignDocLib";
import {EditCourseModal} from "../../LearningManagement/EditCourseModal/EditCourseModal";

export const MgmntSignDocCard = ({signDoc, onAfterEditComplete, noButton}) => {
    const [editSignDocModalOpen, setEditSignDocModalOpen] = useState(false)
    const [hover, setHover] = React.useState(false)
    const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false)
    const type = signDoc.type
    const openDeleteConfirmModal = () => {
        setDeleteConfirmModalOpen(true)
    }
    const closeDeleteConfirmModal = () => {
        setDeleteConfirmModalOpen(false)
    }

    const closeSignDocModal = () => {
        setEditSignDocModalOpen(false)
    }

    const onSignDocSelected = () => {
        setEditSignDocModalOpen(true)
    }

    const onMouseEnter = () => {
        setHover(true)
    }
    const onMouseLeave = () => {
        setHover(false)
    }
    const onDeleteSignDoc = () => {
        if(!hover){
            return
        }
        openDeleteConfirmModal()
    }
    const deleteSignDoc = async () => {
        if(type === SignDocTypes.REGULAR_COURSE){
            return
        }
        await deleteSignDocAPI({signDocId: signDoc.id})
        closeDeleteConfirmModal()
        onAfterEditComplete()
    }
    return <div className="MgmntSignDocCard SignDocCard">
        <div className={`signDocCardImage ${type === SignDocTypes.REGULAR_COURSE ? 'round' : ''}`}>
            <img src={signDoc.imgSrc || SignDocIcons[signDoc.type]}/>
        </div>
        <div className="signDocCardContent" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {type !== SignDocTypes.REGULAR_COURSE && <div className={`deleteIcon ${hover ? 'hover' : ''}`}>
                <DeleteForeverIcon onClick={onDeleteSignDoc}/>
            </div>}
            <div className={`signDocTitle ${get(signDoc, 'docName.length') > 20 ? 'small' : ''}`}>{signDoc.docName}</div>
            <div className="signDocDescription" title={signDoc.description}>{signDoc.description}</div>
            <div className="signDocButton">
                <Button color={buttonColors.GOLD}
                        disabled={noButton}
                        onClick={onSignDocSelected}
                        style={{width: '100%'}}
                        text={i18n.translate(`LearningManagementPage.signDocCard.editSignDoc.${signDoc.type}`)}/>
            </div>
        </div>
        {editSignDocModalOpen && <CreateEditNewSignDocModal
            onAfterEditComplete={onAfterEditComplete}
            signDoc={signDoc}
            editMode={true}
            newDocSignModalOpen={editSignDocModalOpen}
            closeNewDocSignModal={closeSignDocModal}/>}
        {/*{type === SignDocTypes.REGULAR_COURSE && editSignDocModalOpen && <EditCourseModal*/}
        {/*    key={signDoc.id}*/}
        {/*    courseId={signDoc.id}*/}
        {/*    editCourseModalOpen={editSignDocModalOpen}*/}
        {/*    closeEditCourseModal={closeSignDocModal}/>}*/}

        {type !== SignDocTypes.REGULAR_COURSE && deleteConfirmModalOpen ? <ConfirmationModal
            confirmationModalOpen={deleteConfirmModalOpen}
            onCloseConfirmationModal={closeDeleteConfirmModal}
            title={i18n.translate('LearningManagementPage.signDocCard.deleteModal.title')}
            message={i18n.translate('LearningManagementPage.signDocCard.deleteModal.text')}
            messageBody={<div>
                <div>{i18n.translate('LearningManagementPage.signDocCard.deleteModal.signDocName')}: <span style={{fontWeight: 'bold'}}>{signDoc.docName}</span></div>
            </div>}
            onConfirm={deleteSignDoc}
            onCancel={closeDeleteConfirmModal}
            confirmText={i18n.translate('LearningManagementPage.signDocCard.deleteModal.confirm')}
        /> : ''}
    </div>
}