import React, {useState} from "react";
import {i18n} from "../../Utils/i18n/i18n";
import './Users.scss'
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {PageTitle} from "../PageTitle/PageTitle";
import {buttonColors} from "../Pickers/InputTheme";
import {Button} from "../Form/Button/Button";
import {ImportUsersModal} from "./ImportUsersModal/ImportUsersModal";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import {GridReactContext, GridReactContextProvider} from "../../Utils/DataGridWrapper/GridContext";
import OrganizationUsersTable from "./OrganizationUsersTable/OrganizationUsersTable";
import {CreateEditUserModal} from "./CreateEditUserModal/CreateEditUserModal";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {useUserAdminOrgs} from "../Hooks/UseUserAdminOrgs";
import {getAllOrganizationUsersCSVApi, getOrganizationUsersCSVApi} from "../../Services/api";

export const Users = () => {
    const {adminOrganizationOptions, adminOrganizations, isOrgsLoading} = useUserAdminOrgs({})
    const [importUsersModalOpen, setImportUsersModalOpen] = useState(false)
    const [createEditUserModalOpen, setCreateEditUserModalOpen] = useState(false)
    const gridContext = React.useContext(GridReactContext)
    const openImportUsersModal = () => {
        setImportUsersModalOpen(true)
    }
    const closeImportUsersModal = () => {
        setImportUsersModalOpen(false)
    }
    const openCreateEditUserModal = () => {
        setCreateEditUserModalOpen(true)
    }
    const closeCreateEditUserModal = () => {
        setCreateEditUserModalOpen(false)
    }
    const onSuccessCreateEditUser = () => {
        closeCreateEditUserModal()
        gridContext.triggerTableRefresh()
    }
    const onSuccessCreateUsers = () => {
        closeImportUsersModal()
        gridContext.triggerTableRefresh()
    }
    const buttons = [
        //download csv button
        {
            type: "A-Button",
            icon: <CloudDownloadIcon/>,
            href: `${process.env.REACT_APP_PUBLIC_FILES_URL}/weezbee-csv-example.csv`,
            text: i18n.translate('importUsersModal.downloadExample')
        },
        {
            icon: <GroupAddOutlinedIcon/>,
            color: buttonColors.GREY,
            text: i18n.translate('importUsersModal.title'),
            onClick: ({paginationModel, filterModel, sortModel}) => {
                openImportUsersModal()
            }
        },
        {
            icon: <PersonAddIcon/>,
            color: buttonColors.GREY,
            text: i18n.translate('createEditUserModal.createUser'),
            onClick: ({paginationModel, filterModel, sortModel}) => {
                openCreateEditUserModal()
            }
        },
    ]


    return <div className="UsersPage">
        <GridReactContextProvider>
            <div className="TableWRapper">
                {adminOrganizationOptions ?
                    <OrganizationUsersTable tableButtons={buttons} organizationsOptions={adminOrganizationOptions}/> :
                    <LoadingSpinner/>}
            </div>
            {importUsersModalOpen && <ImportUsersModal closeImportUsersModal={closeImportUsersModal}
                                                       importUserModalOpen={importUsersModalOpen}
                                                       onSuccessCreateUsers={onSuccessCreateUsers}/>}
            {createEditUserModalOpen && <CreateEditUserModal closeCreateEditUserModal={closeCreateEditUserModal}
                                                             createEditUserModalOpen={createEditUserModalOpen}
                                                             onSuccess={onSuccessCreateEditUser}/>}
        </GridReactContextProvider>
    </div>
}