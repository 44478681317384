import {Modal} from "../../Smart/Modal/Modal";
import {i18n} from "../../../Utils/i18n/i18n";
import {FileUploadBox} from "../../Smart/FileUploadBox/FileUploadBox";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {uploadCompanyLogoApi} from "../../../Services/api";
import React, {useContext, useState} from "react";
import {Button} from "../../Form/Button/Button";
import {buttonColors} from "../../Pickers/InputTheme";
import './UploadOrgLogoModal.scss'
import {CompanyReactContext} from "../../../contexts/CompanyContext";

export const UploadOrgLogoModal = ({
                                       editLogoModalOpen,
                                       closeEditLogoModal
                                   }) => {
    const [isUploadingLogo, setIsUploadingLogo] = useState(false)
    const companyContext = useContext(CompanyReactContext)
    const uploadOrganizationPhoto = () => async ({files, onProgress}) => {
        setIsUploadingLogo(true)
        await uploadCompanyLogoApi({files, onProgress})

        setTimeout(() => {
            companyContext.refreshLogoUrl()
            setIsUploadingLogo(false)
            closeEditLogoModal()
        }, 1000)
    }

    return <Modal
        open={editLogoModalOpen}
        onClose={closeEditLogoModal}>
        <div className="logoUploadWrapper modalWrapper">
            <div className="modalTitle">
                <div>{i18n.translate('OrganizationsPage.editLogo')}</div>
            </div>
            <div className="modalContent">
                <div className="dropzoneWrapper">
                    <FileUploadBox
                        uploadFiles={uploadOrganizationPhoto()}
                        maxSize={10000000}
                        accept={{
                            'image/*': ['.jpg', '.jpeg', '.png']
                        }}
                        supportedFileTypesForNotifications={['JPG', 'JPEG', 'PNG']}
                    >
                        {isUploadingLogo ?
                            <LoadingSpinner local/> : i18n.translate('OrganizationsPage.dropLogoHere')}
                    </FileUploadBox>
                </div>
                <div className="notification-logo">
                    {i18n.translate('OrganizationsPage.logoWillBeChangedForAllOrgs')}
                </div>
            </div>
            <div className="modalFooter">
                <Button
                    color={buttonColors.YELLOW}
                    onClick={closeEditLogoModal}
                    text={i18n.translate('general.close')}
                />
            </div>
        </div>

    </Modal>;
}
