import './SendNotificationCounts.scss'
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {i18n} from "../../../Utils/i18n/i18n";
import React from "react";


export const SendNotificationCounts = ({canSend, loadingSMSParameters, usersToSendCount, companySMSQuota, selectedSentToOption}) => {
    return <div className="SendNotificationCounts">
        {loadingSMSParameters ? <LoadingSpinner/> : ""}
        {canSend() && usersToSendCount && companySMSQuota >= usersToSendCount && !loadingSMSParameters ?
            <div className="smsStatusInformation">
                <div
                    className="statusTitle">{i18n.translate('sendSMSModal.smsStatusInformation.statusTitle')}</div>
                <div className="statusData statusUsertToNotify">
                    {i18n.translate('sendSMSModal.smsStatusInformation.statusUserToNotify')}
                    <span className="status-bold">{usersToSendCount}</span>
                    <span
                        className="status-bold">{i18n.translate('sendSMSModal.smsStatusInformation.employees')}</span>
                </div>
                <div className="statusData statusCompanyQuota">
                    {i18n.translate('sendSMSModal.smsStatusInformation.statusCompanyQuota')}
                    <span className="status-bold">{companySMSQuota - usersToSendCount}</span>
                    <span
                        className="status-bold">{i18n.translate('sendSMSModal.smsStatusInformation.SMSForUse')}</span>
                </div>
            </div> : ''}
        {!usersToSendCount && selectedSentToOption === 'onlyNotNotified' && canSend() && !loadingSMSParameters ?
            <div className="statusError">
                {i18n.translate('sendSMSModal.error.noUsersToSendOnlyNotNotified')}
            </div> : ''}
        {!usersToSendCount && selectedSentToOption !== 'onlyNotNotified' && canSend() && !loadingSMSParameters ?
            <div className="statusError">
                {i18n.translate('sendSMSModal.error.noUsersInCourse')}
            </div> : ''}
        {companySMSQuota < usersToSendCount && canSend() && !loadingSMSParameters ? <div className="statusError">
            <div>
                {i18n.translate('sendSMSModal.error.notEnoughSMS')}
            </div>
            <div>
                {i18n.translate('sendSMSModal.error.youAreLeftWithQuota')}
                <span
                    className="errorBold">{companySMSQuota}</span>, {i18n.translate('sendSMSModal.error.youNeed')}
                <span
                    className="errorBold">{usersToSendCount}</span> {i18n.translate('sendSMSModal.error.toCompleteTheProcess')}
            </div>
        </div> : ''}
    </div>
}