import {UserReactContext} from "../../contexts/UserContext";
import {useContext} from "react";
import {UsersHome} from "./UsersHome";
import {SuperAdminHome} from "./SuperAdminHome";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";


export const Home = () => {
    const userContext = useContext(UserReactContext)
    if(!userContext.isUserValid()){
        return <LoadingSpinner/>
    }
    if(userContext.isUserSuperAdmin()){
        return <SuperAdminHome/>
    }
    else{
        return <UsersHome/>
    }

}