import React from 'react';
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {Doughnut} from 'react-chartjs-2';
import {Colors} from "../../Utils/styles/Colors";
import {calcDataPercentagesForGraph} from "./DoughnutChartLib";

ChartJS.register(ArcElement, Tooltip, Legend);

export const DoughnutChart = ({labels, dataSet, label, backgroundColors}) => {
    const convertedData = calcDataPercentagesForGraph(dataSet);
    const options = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            },
            datalabels: {
                font: {
                    size: 18,
                    weight: 'bold',
                    family: 'Heebo',
                    color: Colors["$GraphText"]
                    // paddingBottom: 10,
                },
                align: 'left',
                offset: -55,
                anchor: 'center',
                formatter: (value, context) => {
                    // const dataIndex = context.dataIndex;
                    // const val = Number(context.chart.data.labels[dataIndex]);
                    if(value <= 15){
                        return ''
                    }
                    else{
                        return value + '%';
                    }
                }
            }

        },
    }
    const data = {
        labels: labels || [],
        datasets: [
            {
                label: label,
                data: convertedData || [],
                backgroundColor: backgroundColors || [
                    Colors["$GraphBackground1"],
                    Colors["$GraphBackground2"],
                    Colors["$GraphBackground3"],
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    Colors["$GraphBorder"],
                    Colors["$GraphBorder"],
                    Colors["$GraphBorder"],
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },

        ]
    }
    return <Doughnut data={data} options={options} plugins={[ChartDataLabels]}/>;
}
