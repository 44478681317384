import './TableDownloadLink.scss'
import {getCompanyFileDownloadLinkAPI} from "../../Services/api";
import {i18n} from "../i18n/i18n";

export const TableDownloadLink = ({fileId, text}) => {
    const getDownloadLinkFromServer = async () => {
        const downloadLink = await getCompanyFileDownloadLinkAPI({fileId})
        window.open(downloadLink, '_blank')
    }
    return <div className="TableDownloadLink" onClick={getDownloadLinkFromServer}>
        {text || i18n.translate('FilesManagementPage.fileManagementTable.download')}
    </div>
}