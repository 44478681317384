import {find, get, isEmpty, map} from 'lodash'
import {readString} from 'react-papaparse';
import {i18n} from "../../Utils/i18n/i18n";
import jschardet from 'jschardet';
import {MessageTypes} from "../../Utils/Constants";
import {isEmailValid, sanitizePhoneNumber, validatePhoneNumberAndFormat} from "../../Utils/functions";

export const importParametersFromFile = async ({file, companyPrefix, selectedMessagingTypes, sendWelcomeMessage}) => {
   const detectedEncoding =  await new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(file);
        fileReader.onload = (e) => {
            setTimeout(() => {
                const fileText = get(e, 'target.result', '')
                const encoding = jschardet.detect(fileText).encoding
                resolve(encoding)
            })
        }
    })

    return new Promise((resolve, reject) => {
        const fileExtension = file.name.split('.')[1].toLowerCase()
        if (fileExtension !== 'csv') {
            alert("File type is not supported, only txt or csv files")
            return
        }

        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
            setTimeout(() => {
                const fileText = get(e, 'target.result', '')
                let data = {};
                if (fileExtension === 'csv') {
                    const text = new TextDecoder(detectedEncoding).decode(fileText)
                    const res = readString(text)
                    const keys = res.data[0]
                    if (!keys.includes("First Name")) {
                        reject("errors.noFirstNameColumn")
                    }
                    if (!keys.includes("Last Name")) {
                        reject("errors.noLastNameColumn")
                    }
                    if (!keys.includes("Identifier")) {
                        reject("errors.noIdentifierColumn")
                    }
                    const removeRows = []
                    map(res.data.slice(1), (row, rowIndex) => {
                        if (!row || row.length === 0 || (row[0] === "" && row.length === 1)) {
                            removeRows.push(rowIndex)
                        }
                    })
                    map(removeRows, (index) => {
                        delete res.data[index + 1]
                    })

                    map(res.data.slice(1), (row, rowIndex) => {
                        map(row, (col, colIndex) => {
                            if (!data[rowIndex]) {
                                data[rowIndex] = {}
                            }
                            data[rowIndex][keys[colIndex]] = res.data[rowIndex + 1][colIndex]
                        })
                    })
                    const usePhoneNumber = sendWelcomeMessage && selectedMessagingTypes.includes(MessageTypes.SMS)
                    const useEmail = sendWelcomeMessage && selectedMessagingTypes.includes(MessageTypes.EMAIL)
                    const attributes = [
                        {
                            key: "First Name",
                            value: "firstName",
                            isMandatory: true
                        },
                        {
                            key: "Last Name",
                            value: "lastName",
                            isMandatory: true
                        },
                        {
                            key: "Phone",
                            value: "phone",
                            isMandatory: usePhoneNumber,
                            validationFunction: usePhoneNumber ? validatePhoneNumberAndFormat : null,
                            manipulationFunction: sanitizePhoneNumber
                        },
                        {
                            key: "Email",
                            value: "email",
                            isMandatory: useEmail,
                            validationFunction: useEmail ? isEmailValid : null
                        }
                    ]
                    const errors = {}
                    map(data, (user, index) => {
                        map(attributes, (attribute) => {
                            if(attribute.manipulationFunction){
                                user[attribute.key] = attribute.manipulationFunction(user[attribute.key])
                            }
                            if ((!user[attribute.key] || isEmpty(user[attribute.key])) && attribute.isMandatory || (attribute.validationFunction && !attribute.validationFunction(user[attribute.key]))) {
                                if (!errors[index]) {
                                    errors[index] = {}
                                }
                                errors[index][attribute.value] = true
                                user[attribute.value] = user[attribute.key].trim()
                            } else {
                                user[attribute.value] = user[attribute.key].trim()
                            }

                        })
                        if (!user["Identifier"]) {
                            errors[index]["identifier"] = i18n.translate("errors.missingMandatoryField", {
                                fieldName: "Identifier",
                                row: index + 1
                            });
                        } else {
                            user.identifier = `${companyPrefix}-${sanitizePhoneNumber(user["Phone"]).trim()}`
                        }

                    })
                    //search for empty rows and remove them
                    let emptyRows = []
                    map(data, (row, index) => {
                        if (!row.firstName && !row.lastName && !row.phone && !row.email) {
                            emptyRows.push(index)
                        }
                    })

                    map(emptyRows, (index) => {
                        delete data[index]
                    })
                    //remove errors which are relevant for empty rows
                    map(emptyRows, (index) => {
                        delete errors[index]
                    })

                    if (!isEmpty(errors)) {
                        reject({errors, data})
                    }
                    // if (errors.length) {
                    //     reject({errors, data})
                    // }
                    resolve(data)
                }
                reject()

            }, 200)
        };
    })

}

export const getOrganizationLogoById = ({orgId, orgs}) => {
    const organization = find(orgs, {value: orgId})
    return organization.orgLogo
}