import {i18n} from "../../Utils/i18n/i18n";
import {get, map, uniqBy} from "lodash";


export const getUniqNotes = ({orgs}) => {
    const notes = [];
    map(orgs, (org) => {
        map(get(org, 'organization.notes'), (note) => {
            notes.push({
                id: note.id,
                title: note.title,
                content: note.content
            })
        })
    })
    return uniqBy(notes, (note) => note.id)
}

export const userHomeChartLabels = {
    completed: {
        colorClass: 'colorYellow',
        label: i18n.translate('HomePage.courseLegend.completed')
    },
    inProgress: {
        colorClass: 'colorGrey',
        label: i18n.translate('HomePage.courseLegend.inProgress')
    },
    notStarted: {
        colorClass: 'colorLightGrey',
        label: i18n.translate('HomePage.courseLegend.notStarted')
    }
}