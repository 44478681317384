import './EditCustomerTableCol.scss';
import {buttonColors} from "../../../Pickers/InputTheme";
import {i18n} from "../../../../Utils/i18n/i18n";
import {Button} from "../../../Form/Button/Button";
import React from "react";
import {SAEditCustomerModal} from "../../SAEditCustomerModal/SAEditCustomerModal";

export const EditCustomerTableCol = ({customerId}) => {
    const [isEditCustomerModalOpen, setIsEditCustomerModalOpen] = React.useState(false)
    const onClickEditCustomer = () => {
        setIsEditCustomerModalOpen(true)
    }
    const closeEditCustomerModal = () => {
        setIsEditCustomerModalOpen(false)
    }
    return <div className="EditCustomerTableCol">
        <Button color={buttonColors.GOLD}
                size="small"
                text={i18n.translate('superAdmin.customersTable.columns.editCompany')}
                onClick={onClickEditCustomer}/>

        {isEditCustomerModalOpen ? <SAEditCustomerModal initCustomerId={customerId} editCustomerModalOpen={isEditCustomerModalOpen} closeEditCustomerModal={closeEditCustomerModal} onUserEditCompleted={closeEditCustomerModal} /> : ''}
    </div>
}