import React from 'react';
import "./ProgressSlider.scss"
import {i18n} from "../../i18n/i18n";

export const SignDocProgressSlider = ({viewed, signed, type}) => {
   const actualProgress = viewed && !signed ? 50 : (signed ? 100 : 0)
    return <div className="ProgressSlider SignDocProgressSlider">
        <div className="progressBar">
            <div className={`progressRound rightRound on`}/>
            <div className={`progressRound middleRound ${viewed ? 'on' : ''}`}/>
            <div className={`progressRound leftRound ${signed ? 'on' : ''}`}/>
        </div>
        <div className="overlayProgressBar" style={{width: `${actualProgress}%`}}/>
        <div className="progressTexts">
            <div className={`progressText rightText on`}>{i18n.translate(`signDocProgress.notStarted.${type}`)}</div>
            <div className={`progressText middleText ${viewed ? 'on' : ''}`}>{i18n.translate(`signDocProgress.inProgress.${type}`)}</div>
            <div className={`progressText leftText ${signed ? 'on' : ''}`}>{i18n.translate(`signDocProgress.finished.${type}`)}</div>
        </div>
    </div>
}

