import {Note} from "../../Note/Note";
import {find, get, map} from "lodash";
import Chip from "@mui/material/Chip";
import {FormInput} from "../../Form/Input/FormInput";
import {i18n} from "../../../Utils/i18n/i18n";
import React, {useState} from "react";
import './EditNote.scss'
import {buttonColors} from "../../Pickers/InputTheme";
import {Button} from "../../Form/Button/Button";
import {deleteNoteAPI, updateNote} from "../../../Services/api";
import AddIcon from '@mui/icons-material/Add';
import {ConfirmationModal} from "../../Smart/confirmationModal/confirmationModal";

export const EditNote = ({note, triggerReload}) => {
    const [originalNote] = useState(note)
    const [title, setTitle] = useState(note.title)
    const [content, setContent] = useState(note.content)
    const [orgs, setOrgs] = useState(note.orgs)
    const [availableOrgs, setAvailableOrgs] = useState(note.availableOrgs)
    const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false)


    const onSave = async() => {
        const newNote = await updateNote({noteId: note.id, title, content, orgs})
        setTitle(newNote.title)
        setContent(newNote.content)
    }
    const onDeleteConfirm = async() => {
        await deleteNoteAPI({noteId: note.id})
        setDeleteConfirmationModalOpen(false)
        triggerReload && triggerReload()
    }
    const onDeleteClick = () => {
        setDeleteConfirmationModalOpen(true)

    }
    const closeDeleteConfirmationModal = () => {

    }

    const onEditNoteTitle = (event) => {
        setTitle(event.target.value)
    }
    const onEditNoteContent = (event) => {
        setContent(event.target.value)
    }
    const removeOrgFromNote = (orgId) => () => {
        const newOrgs = orgs.filter((org) => org.id !== orgId)
        //Add Org to availableOrgs
        const newOrg = find(orgs, {id: orgId})
        const newAvailableOrgs = [...availableOrgs, newOrg]
        setAvailableOrgs(newAvailableOrgs)
        setOrgs(newOrgs)
    }
    const addOrgToNote = (orgId) => () => {
        const newOrg = find(availableOrgs, {id: orgId})
        const newOrgs = [...orgs, newOrg]
        const newAvailableOrgs = availableOrgs.filter((org) => org.id !== orgId)
        setAvailableOrgs(newAvailableOrgs)
        setOrgs(newOrgs)

    }
    return <div className="EditNote">
        <div className="noteWithOrgs" key={note.id}>
            <div className="orgNoteWrapper">
                <Note note={{title, content}}/>
            </div>
            <div className="EditRightPane">
                <div className="noteChips">
                    {map(orgs, (org) => {
                        return <div className="noteChip">
                            <Chip key={org.id} label={org.orgName} onDelete={removeOrgFromNote(org.id)}/>
                        </div>
                    })}
                    {map(availableOrgs, (org) => {
                        return <div className="noteChip">
                            <Chip deleteIcon={<AddIcon/>} variant="outlined" key={org.id} label={org.orgName}
                                  onDelete={addOrgToNote(org.id)}/>
                        </div>
                    })}

                </div>
                <div className="noteEditFields">
                    <div className="editTitle">
                        <FormInput
                            id={`noteTitle-${note.id}`}
                            label={i18n.translate('note.title')}
                            className=""
                            value={title}
                            onChange={onEditNoteTitle}
                            inputProps={{maxLength: 36}}
                        />
                        <div className="noteCount">
                            {get(title, 'length', 0)}/36
                        </div>
                    </div>
                    <div className="editContent">
                        <FormInput
                            id={`noteContent-${note.id}`}
                            label={i18n.translate('note.content')}
                            className=""
                            value={content}
                            onChange={onEditNoteContent}
                            inputProps={{maxLength: 130}}
                        />
                        <div className="noteCount">
                            {get(content, 'length', 0)}/130
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="saveContainer">
            <Button
                color={buttonColors.GREY}
                onClick={onDeleteClick}
                text={i18n.translate('note.delete')}
            />
            <Button
                color={buttonColors.YELLOW}
                onClick={onSave}
                text={i18n.translate('general.saveChanges')}
            />

        </div>
        {deleteConfirmationModalOpen ? <ConfirmationModal
            confirmationModalOpen={deleteConfirmationModalOpen}
            onCloseConfirmationModal={closeDeleteConfirmationModal}
            title={i18n.translate('note.deleteNoteConfirmation.title')}
            message={i18n.translate('note.deleteNoteConfirmation.message')}
            messageBody={<div>
                <div>{i18n.translate('note.deleteNoteConfirmation.noteName')}: <span style={{fontWeight: 'bold'}}>{note.title}</span></div>
            </div>}
            onConfirm={onDeleteConfirm}
            onCancel={closeDeleteConfirmationModal}
            confirmText={i18n.translate('note.deleteNoteConfirmation.deleteNote')}
        /> : ''}
        <div className="horizontalDivider"/>
    </div>
}