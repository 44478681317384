import _ from 'lodash';
import queryString from 'query-string';
import FileSaver from "file-saver";


let serverCall;

export const setServerCall = (axiosInstance) => {
    serverCall = axiosInstance
}

//////////////////////////////////////
// add the security token to each call
const AUTH_STARTER = 'Bearer ';


//////////////////////////////////////
// helpers for the helpers

const wrapReturn = (returnValue) => {
    if (!returnValue || !returnValue.data)
        return Promise.reject({statusCode: 404, error: true})
    if (returnValue.data.meta && returnValue.data.meta.error)
        return Promise.reject(returnValue.data.meta)
    // if (_.get(returnValue, 'data.meta')) {
    //     if (_.get(returnValue, 'config.setUserState', true)) {
    //         setUserState(_.get(returnValue, 'data.meta'));
    //     }
    // }
    // setLicenseDataFromMeta(_.get(returnValue, 'data.meta'))
    return Promise.resolve(returnValue.data.data)
}

const wrapError = error => {
    // if(error && error.response && error.response.status === 401){
    //     emptyLoginState()
    //     if(!window.location.href.includes('login')){
    //         window.location.href = "/login";
    //     }
    // }
    // alert(_.get(error, 'response.data.meta.errors[0].message'), i18n.translate('errors.errorMessageDefault'))
    if (error.response && error.response.data) {
        return Promise.resolve(error.response.data.meta)
    }
    else
        return Promise.resolve(error)
}

const jsonHeaders = {
    headers: {'content-type': 'application/json; charset=UTF-8'},
}

const formUrlEncodedHeaders = {
    headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
};
const formDataConfig = (progressHandler) => {
    return {
        headers: {'content-type': 'application/form-data; charset=UTF-8'},
        onUploadProgress: progressHandler
    }
};

export const getUrl = (url, conf) => {
    return serverCall.get(url, {...conf})
        .then(wrapReturn).catch(wrapError)
}

export const dataPostUrl = (url, data) => {
    return serverCall.post(url,
        queryString.stringify(data), formUrlEncodedHeaders)
        .then(wrapReturn).catch(wrapError)
}

export const dataPutUrl = (url, data) => {
    return serverCall.put(url,
        queryString.stringify(data), formUrlEncodedHeaders)
        .then(wrapReturn).catch(wrapError)
}

export const formPostUrl = (url, data, progressHandler) => {
    return serverCall.post(url,
        data, formDataConfig(progressHandler))
        .then(wrapReturn).catch(wrapError)
}


/*
export const uploadSingleFile = (url, file, data = {}) => {
    let formData = new FormData();
    _.each(data, (v, k) => formData.append(k, v))
    formData.append('asset', file)
    return formPostUrl(
        url,
        formData,
        wrapProgressHandler)
}
*/
let genericProgressListener = null;
let nextUploadToken = 0

export const setProgressListener = x => (genericProgressListener = x);

export const uploadFiles = (url, files, onProgress = null, data = {}, assetAttribute = 'assets') => {

    const uploadToken = nextUploadToken++

    const wrapProgressHandler = event => {
        const {loaded, total, type} = event;
        let progress
        if (type === 'progress' && total) {
            progress = Math.floor((loaded * 100) / total);
        }
        return genericProgressListener
            ? genericProgressListener({
                progress,
                uploadToken,
                url,
                files,
                data
            })
            : null;
    };

    let formData = new FormData();
    _.each(data, (v, k) => formData.append(k, v))
    _.each(files, f => formData.append(assetAttribute, f))

    return formPostUrl(
        url,
        formData,
        onProgress)
}

export const jsonPutUrl = (url, data) => {
    return serverCall.put(url, JSON.stringify(data), jsonHeaders)
        .then(wrapReturn).catch(wrapError)
}

export const jsonPostUrl = (url, data, conf) => {
    return serverCall.post(url, JSON.stringify(data), {...jsonHeaders, ...conf})
        .then(wrapReturn).catch(wrapError)
}

export const jsonDeleteUrl = (url, data) => {
    return serverCall.delete(url, JSON.stringify(data), jsonHeaders)
        .then(wrapReturn).catch(wrapError)
}

export const saveFileFromRes = ({res, fileName, fileType, callback}) => {
    let blob = new Blob([res], {type: fileType});
    FileSaver.saveAs(blob,  fileName);
    callback && callback()
}


export default {
    uploadFiles,
    jsonPostUrl,
    jsonDeleteUrl,
    jsonPutUrl,
    formPostUrl,
    dataPutUrl,
    dataPostUrl,
    getUrl
}