import rest from '../Utils/rest'

export const SA_createSignDoc = ({description, docName, imgSrc, type}) => {
    return rest.jsonPostUrl(`/api/sa/signDocs/create`, {description, docName, imgSrc, type})
}
export const SA_editSignDoc = ({description, docName, imgSrc, signDocId}) => {
    return rest.jsonPostUrl(`/api/sa/signDocs/${signDocId}/edit`, {description, docName, imgSrc})
}

export function SA_uploadSignDocZipFileAPI({files, onProgress, signDocId}) {
    return rest.uploadFiles(`api/sa/signDocs/${signDocId}/uploadZip`, files, onProgress)
}

export const SA_getAllCourses = ({paginationModel, filterModel}) => {
    return rest.jsonPostUrl(`/api/sa/signDocs/all`, {paginationModel, filterModel})
}


export const SA_getAllOrganizationsForSignDoc = ({paginationModel, filterModel, signDocId}) => {
    return rest.jsonPostUrl(`/api/sa/organizations/allForSignDoc`, {paginationModel, filterModel, signDocId})
}
export const SA_getAllOrganizationsForCompany = ({paginationModel, filterModel, companyId}) => {
    return rest.jsonPostUrl(`/api/sa/organizations/allForCompany`, {paginationModel, filterModel, companyId})
}

export const SA_addSignDocToOrganization = ({signDocId, organizationId}) => {
    return rest.jsonPostUrl(`/api/sa/organizations/addSignDoc`, {signDocId, organizationId})
}
export const SA_removeCourseFromOrganization = ({signDocId, organizationId}) => {
    return rest.jsonPostUrl(`/api/sa/organizations/removeSignDoc`, {signDocId, organizationId})
}

export const SA_getAllCompanies = () => {
    return rest.getUrl(`/api/sa/companies/all`)
}

export const SA_getAllCompaniesPagination = ({paginationModel, filterModel}) => {
    return rest.jsonPostUrl(`/api/sa/companies/all`, {paginationModel, filterModel})
}

export const SA_getSignDoc = ({signDocId}) => {
    return rest.getUrl(`/api/sa/signDocs/${signDocId}`)
}

export const SA_createCompany = ({companyName, companyUsersPrefix, identifier, contactPhone , contactEmail, contactFirstName, contactLastName, userIdentifier, userPassword,  userFirstName, userLastName}) => {
    return rest.jsonPostUrl(`/api/sa/companies/create`, {companyName, companyUsersPrefix, identifier, contactPhone, contactEmail,contactFirstName, contactLastName, userIdentifier, userPassword, userFirstName, userLastName})
}

export const SA_getCompanyAndAdminUsers = ({companyId}) => {
    return rest.getUrl(`/api/sa/companies/${companyId}/companyAndAdminUsers`)
}


export const SA_updateCompany = ({companyName, identifier, contactPhone , contactEmail, contactFirstName, contactLastName, companyId}) => {
    return rest.jsonPostUrl(`/api/sa/companies/${companyId}/update`, {companyName, identifier, contactPhone, contactEmail, contactFirstName, contactLastName})
}

export const SA_createOrganizationsForCompany = ({companyId, organizations}) => {
    return rest.jsonPostUrl(`/api/sa/companies/${companyId}/createOrganizations`, {companyId, organizations})
}

export const SA_getAllUsersFiltered = ({paginationModel, filterModel}) => {
    return rest.jsonPostUrl(`/api/sa/users/allFiltered`, {paginationModel, filterModel})
}
export const SA_getAllOrganizationsFiltered = ({paginationModel, filterModel}) => {
    return rest.jsonPostUrl(`/api/sa/organizations/allFiltered`, {paginationModel, filterModel})
}

export const SA_removeOrganizationAPI = ({organizationId}) => {
    return rest.jsonPostUrl(`/api/sa/organizations/${organizationId}/remove`, {})
}

export const SA_removeSignDocFromCompanyAPI = ({signDocId}) => {
    return rest.jsonPostUrl(`/api/sa/signDocs/${signDocId}/remove`)
}

export const SA_updateUserAPI = ({userId, firstName, lastName, email, phone, isMfaRequired}) => {
    return rest.jsonPostUrl(`/api/sa/users/${userId}/update`, {firstName, lastName, email, phone, isMfaRequired})
}
export const SA_setCompanyQuotas = ({companyId, smsQuota, storageQuotaMB}) => {
    return rest.jsonPostUrl(`/api/sa/companies/${companyId}/setQuotas`, {smsQuota, storageQuotaMB})
}

export const SA_getUsersData = ({userIds}) => {
    return rest.jsonPostUrl(`/api/sa/users/getData`, {userIds})
}
export const SA_deleteUsers = ({userIds}) => {
    return rest.jsonPostUrl(`/api/sa/users/remove`, {userIds})
}

export const SA_permanentlyDeleteUsers = ({userId}) => {
    return rest.jsonPostUrl(`/api/sa/users/permanentlyDelete`, {permanentlyDeleteUserId: userId})
}
export const SA_getCompanyFeatureFlags = ({companyId}) => {
    return rest.jsonPostUrl(`/api/sa/companies/getFeatureFlags`, {companyId})
}
export const SA_updateCompanyFeatureFlags = ({companyId, featureFlags}) => {
    return rest.jsonPostUrl(`/api/sa/companies/updateFeatureFlags`, {companyId, featureFlags})
}

export const SA_makeCompanyAdmin = ({newAdminUserId}) => {
    return rest.jsonPostUrl(`/api/sa/makeCompanyAdmin`, {newAdminUserId})
}

export const SA_makeRegularUser = ({adminUserId}) => {
    return rest.jsonPostUrl(`/api/sa/setAdminAsRegularUser`, {adminUserId})
}