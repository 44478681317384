import './Settings.scss'
import {i18n} from "../../Utils/i18n/i18n";
import {PageTitle} from "../PageTitle/PageTitle";
import React from "react";
import {SettingsTabs} from "./SettingsTabs/SettingsTabs";

export const Settings = () => {

    return <div className="Settings MenuPage">
        <PageTitle
            size="small"
            title={i18n.translate('settingsPage.title')}
            subTitle={i18n.translate('settingsPage.subTitle')}
            subTitleBold={i18n.translate('settingsPage.subTitleBold')}/>
        <div className="menuInnerPage">
            <div className="settingsWrapper">
                <div className="settingsTabsWrapper">
                    <SettingsTabs/>
                </div>
            </div>
        </div>

    </div>

}