import {createTheme} from '@mui/material/styles';
import {Colors} from "../../Utils/styles/Colors";

const createThemeAdapted = (theme) => {
    return createTheme(theme)
}
const inputTheme = {
    direction: 'rtl',
    typography: {
        fontFamily: "OpenSans"
    },
    palette: {
        primary: {
            light: 'white',
            main: Colors["$themeTitle"],
            contrastText: '#fff',
        },
        secondary: {
            light: 'white',
            main: Colors["$button-color"],
            contrastText: 'grey',

        }
    },
    overrides: {
        // MuiOutlinedInput:{
        //     colorSecondary: {
        //         borderColor: 'white',
        //
        //     }
        // },
        MuiInputBase: {
            colorSecondary: {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white'

                }
            }
        },
        MuiCheckbox: {
            root: {
                color: '#c4c4c4'
            }
        },
        MuiRadio: {
            root: {
                color: '#c4c4c4'
            }
        },
        MuiFormControlLabel: {
            label: {
                fontSize: '16px'
            },

        },


    }
}

const buttonRegularPalette = {
    primary: {
        light: 'white',
        main: Colors["$button-color"],
        contrastText: '#fff'
    },
    white: {
        light: '#fff',
        main: '#fff',
        contrastText: '#fff'
    },
    error: {
        light: Colors["$DefaultColor"],
        main: Colors["$DefaultColor"],
        dark: Colors["$DefaultColor"],
        contrastText: '#fff'
    }
}
const buttonLightGreyPalette = {
    primary: {
        light: '#fff',
        main: Colors["$buttonLightGreyColor"],
        contrastText: '#fff'
    },
    error: {
        light: Colors["$DefaultColor"],
        main: Colors["$DefaultColor"],
        dark: Colors["$DefaultColor"],
        contrastText: '#fff'
    }
}
const buttonYellowPalette = {
    primary: {
        light: '#fff',
        main: Colors["$buttonYellowColor"],
        contrastText: '#fff'
    },
    error: {
        light: Colors["$DefaultColor"],
        main: Colors["$DefaultColor"],
        dark: Colors["$DefaultColor"],
        contrastText: '#fff'
    }
}
const buttonGoldPalette = {
    primary: {
        light: '#fff',
        main: Colors["$buttonGoldColor"],
        contrastText: '#fff'
    },
    error: {
        light: Colors["$DefaultColor"],
        main: Colors["$DefaultColor"],
        dark: Colors["$DefaultColor"],
        contrastText: '#fff'
    }
}
const buttonRedPalette = {
    primary: {
        light: '#fff',
        main: Colors["$DefaultColor"],
        contrastText: '#fff'
    },
    error: {
        light: Colors["$DefaultColor"],
        main: Colors["$DefaultColor"],
        dark: Colors["$DefaultColor"],
        contrastText: '#fff'
    }
}
const buttonThemeRegular = ({palette}) => {
    return {
        typography: {
            // fontFamily: "'Roboto'",
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        display: 'flex',
                        flexDirection: 'row',
                        alightItems: 'center',
                        borderRadius: '30px',
                        textTransform: 'none',
                        height: '40px',
                        padding: '2px 22px',
                        minWidth: '120px',
                        fontSize: '18px',
                        lineHeight: '18px',
                        '&.Mui-disabled': {
                            opacity: '.35',
                            backgroundColor: palette.primary.main,
                            color: 'white'
                        },
                    },
                    label: {
                        height: '40px'
                    },
                    outlined: {
                        padding: '1px 8px',
                    },
                    outlinedPrimary: {
                        borderWidth: '2px',

                    },
                    containedPrimary: {

                    },
                    contained: {
                        boxShadow: 'unset',

                    }
                }
            }
        },
        palette: palette
    }
}


const buttonThemeSmall = ({palette}) => {
    return {
        typography: {
            fontFamily: "'Roboto'",
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        display: 'flex',
                        flexDirection: 'row',
                        alightItems: 'center',
                        borderRadius: '12px',
                        textTransform: 'none',
                        height: '28px',
                        padding: '1px 10px',
                        minWidth: '80px',
                        fontSize: '14px',
                        lineHeight: '14px',
                        ".button-text": {
                            paddingTop: '2px'
                        },
                        '&.Mui-disabled': {
                            opacity: '.35',
                            backgroundColor: palette.primary.main,
                            color: 'white'
                        },

                    },
                    label: {
                        height: '20px'
                    },
                    outlined: {
                        padding: '1px 8px',

                    },
                    contained: {
                        boxShadow: 'unset'
                    }
                }
            }
        },
        overrides: {
            MuiButton: {
                root: {
                    display: 'flex',
                    flexDirection: 'row',
                    alightItems: 'center',
                    borderRadius: '12px',
                    textTransform: 'none',
                    height: '28px',
                    padding: '1px 10px',
                    minWidth: '80px',
                    fontSize: '14px',
                    lineHeight: '14px',
                    ".button-text": {
                        paddingTop: '2px'
                    }

                },
                label: {
                    height: '20px'
                },
                outlined: {
                    padding: '1px 8px',

                },
                contained: {
                    boxShadow: 'unset'
                }
            }
        },
        palette: palette
    }
}

const buttonThemeMedium = ({palette}) => {
    return {
        typography: {
            fontFamily: "'Roboto'",
        },
        overrides: {
            MuiButton: {
                root: {
                    display: 'flex',
                    flexDirection: 'row',
                    alightItems: 'center',
                    borderRadius: '12px',
                    textTransform: 'none',
                    height: '32px',
                    padding: '1px 12px',
                    minWidth: '90px',
                    fontSize: '14px',
                    lineHeight: '14px',
                    '&.Mui-disabled': {
                        opacity: '.35',
                        backgroundColor: palette.primary.main,
                        color: 'white'
                    },
                },
                label: {
                    height: '20px'
                },
                outlined: {
                    padding: '1px 8px',
                    minWidth: '90px'
                },
                outlinedPrimary: {
                    borderWidth: '2px'
                },
                contained: {
                    boxShadow: 'unset'
                }
            }
        },
        palette: palette
    }
}

const smallInputOverrides = {
    MuiOutlinedInput: {
        input: {
            padding: '10px 14px'
        }
    },
    MuiInputLabel: {
        formControl: {
            top: '-8px'
        },
        shrink: {
            transform: 'translate(0px, 10px) scale(0.85) !important'
        }
    },
}

const buttonSmallGrey = createThemeAdapted(buttonThemeSmall({palette: buttonRegularPalette}))
const buttonSmallBlue = createThemeAdapted(buttonThemeSmall({palette: buttonYellowPalette}))
const buttonSmallLightGrey = createThemeAdapted(buttonThemeSmall({palette: buttonLightGreyPalette}))
const buttonSmallDarkBlue = createThemeAdapted(buttonThemeSmall({palette: buttonGoldPalette}))
const buttonSmallRed = createThemeAdapted(buttonThemeSmall({palette: buttonRedPalette}))

const buttonMediumGrey = createThemeAdapted(buttonThemeMedium({palette: buttonRegularPalette}))
const buttonMediumBlue = createThemeAdapted(buttonThemeMedium({palette: buttonYellowPalette}))
const buttonMediumLightGrey = createThemeAdapted(buttonThemeMedium({palette: buttonLightGreyPalette}))
const buttonMediumDarkBlue = createThemeAdapted(buttonThemeMedium({palette: buttonGoldPalette}))
const buttonMediumRed = createThemeAdapted(buttonThemeMedium({palette: buttonRedPalette}))

const buttonLargeGrey = createThemeAdapted(buttonThemeRegular({palette: buttonRegularPalette}))
const buttonLargeBlue = createThemeAdapted(buttonThemeRegular({palette: buttonYellowPalette}))
const buttonLargeLightGrey = createThemeAdapted(buttonThemeRegular({palette: buttonLightGreyPalette}))
const buttonLargeDarkBlue = createThemeAdapted(buttonThemeRegular({palette: buttonGoldPalette}))
const buttonLargeRed = createThemeAdapted(buttonThemeRegular({palette: buttonRedPalette}))


export const buttonColors = {
    GREY: 'grey',
    YELLOW: 'yellow',
    LIGHT_GREY: 'lightGrey',
    GOLD: 'gold',
    RED: 'red'
}

export const InputTheme = createThemeAdapted(inputTheme);
export const ButtonTheme = ({size, color}) => {
    if (size === 'small') {
        switch (color) {
            case 'grey':
                return buttonSmallGrey
            case 'yellow':
                return buttonSmallBlue
            case 'lightGrey':
                return buttonSmallLightGrey
            case 'gold':
                return buttonSmallDarkBlue
            case 'red':
                return buttonSmallRed
            default:
                return buttonSmallBlue
        }
    } else if (size === 'medium') {
        switch (color) {
            case 'grey':
                return buttonMediumGrey
            case 'yellow':
                return buttonMediumBlue
            case 'lightGrey':
                return buttonMediumLightGrey
            case 'gold':
                return buttonMediumDarkBlue
            case 'red':
                return buttonMediumRed
            default:
                return buttonMediumBlue
        }
    } else {
        switch (color) {
            case 'grey':
                return buttonLargeGrey
            case 'yellow':
                return buttonLargeBlue
            case 'lightGrey':
                return buttonLargeLightGrey
            case 'gold':
                return buttonLargeDarkBlue
            case 'red':
                return buttonLargeRed
            default:
                return buttonLargeBlue
        }
    }
}
export const CheckboxTheme = createThemeAdapted(inputTheme);
export const RadioTheme = createThemeAdapted(inputTheme);
export const SmallInputTheme = createThemeAdapted({
    ...inputTheme,
    overrides: {...smallInputOverrides}
});





