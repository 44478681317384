import {filter} from "lodash";

export const PermissionBehaviorEnum = {
    HIDE: 'HIDE',
    DISABLE: 'DISABLE'
}

export const PermissionsEnum = {
    "login": "login",
    "logout": "logout",
}

export const getAdminOrganizations = ({organizationsWithPermission}) => {
    const organizationsWIthAdminPermissions = filter(organizationsWithPermission, {permission: 'ADMIN'})
    return organizationsWIthAdminPermissions
}