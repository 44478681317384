import {FormInput} from "../../Form/Input/FormInput";
import {i18n} from "../../../Utils/i18n/i18n";
import React, {useState} from "react";
import './EditOrganization.scss'
import {buttonColors} from "../../Pickers/InputTheme";
import {Button} from "../../Form/Button/Button";
import {updateOrganizationNameApi} from "../../../Services/api";

export const EditOrganization = ({organization}) => {
    const [orgName, setOrgName] = useState(organization?.organization?.orgName)


    const onSave = async() => {
        const newOrganization = await updateOrganizationNameApi({orgName, orgId: organization?.organization?.id})
        setOrgName(newOrganization.orgName)
    }

    const onEditOrgName = (event) => {
        setOrgName(event.target.value)
    }
    return <div className="EditOrganization">
        <div className="orgName">
            <FormInput
                id={`noteContent-${organization.id}`}
                label={i18n.translate('organization.organizationName')}
                className=""
                value={orgName}
                onChange={onEditOrgName}
            />
        </div>
        <div className="saveContainer">
            <Button
                color={buttonColors.GREY}
                onClick={onSave}
                text={i18n.translate('general.saveChanges')}
            />

        </div>
        <div className="horizontalDivider"/>
    </div>
}