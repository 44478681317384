import React, {useState} from "react";
import {get} from "lodash";
import {SelectBox} from "../../../Components/Pickers/SelectBox/SelectBox";
import './SignDocFilter.scss'
import {i18n} from "../../i18n/i18n";

export const SignDocFilter = ({filterModel, setFilterModel, disabledObj}) => {
    const [selectedSignDoc, setSelectedCourse] = useState(get(filterModel, 'filterValues.signDocId'))
    const onSelectSignDocChange = (e) => {
        setSelectedCourse(e.target.value)
        const newFilterModel = {
            ...filterModel,
            filterValues: {
                ...filterModel.filterValues,
                signDocId: e.target.value
            }
        }
        setFilterModel(newFilterModel)
    }
    return <div className="SignDocFilter">
        <SelectBox label={i18n.translate('dataGrid.filterLabels.signDoc')} disabled={get(disabledObj, 'disabled')} options={filterModel.filterData.signDocs} value={selectedSignDoc} onChange={onSelectSignDocChange}/>
        {get(disabledObj, 'disabled') ? <div className="disabledNotice">{get(disabledObj, 'disabledNote')}</div> : ''}
    </div>

}