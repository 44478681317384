import React from 'react';
import './DatePicker.scss';
import {DatePicker as MaterialDatePicker} from '@mui/x-date-pickers/DatePicker';
import {ThemeProvider} from "@mui/material/styles";
import {InputTheme} from "../InputTheme";

export const DatePicker = ({value, onChange}) => {
    return <ThemeProvider theme={InputTheme}>
        <MaterialDatePicker
            value={value}
            onChange={onChange}/>
    </ThemeProvider>

}



