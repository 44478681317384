import {useContext} from "react";
import "./PageTitle.scss";
import "./MenuPage.scss"
import {UserReactContext} from "../../contexts/UserContext";
import {SuperAdminPageTitle} from "./SuperAdminPageTitle";
import {UserPageTitle} from "./UserPageTitle";

export const PageTitle = ({size, title, subTitle, subTitleBold, customComponent}) => {
    const userContext = useContext(UserReactContext)
    if(userContext.isUserSuperAdmin()){
        return <SuperAdminPageTitle subTitleBold={subTitleBold} subTitle={subTitle} title={title} customComponent={customComponent}/>
    }
    else{
        return <UserPageTitle subTitleBold={subTitleBold} subTitle={subTitle} title={title} customComponent={customComponent} size={size}/>
    }

}