import {i18n} from "../../../Utils/i18n/i18n";
import {get, map} from 'lodash'
import {useState} from "react";
import {DataGridWrapper} from "../../../Utils/DataGridWrapper/DataGridWrapper";
import {getOrganizationUsersApi} from "../../../Services/api";
import {EditUserCell} from "../EditUserCell/EditUserCell";
import {RemoveUserCell} from "../RemoveUserCell/RemoveUserCell";

const getUsersColumns = () => {

    return [
        {
            field: 'firstName',
            headerName: i18n.translate("allUsersTable.columns.firstName"),
            minWidth: 100,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}}>{params.value}</div>,
        },
        {
            field: 'lastName',
            headerName: i18n.translate("allUsersTable.columns.lastName"),
            minWidth: 100,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        {
            field: 'identifier',
            headerName: i18n.translate("allUsersTable.columns.identifier"),
            minWidth: 120,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        {
            field: 'phone',
            headerName: i18n.translate("allUsersTable.columns.phone"),
            minWidth: 100,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        {
            field: 'email',
            headerName: i18n.translate("allUsersTable.columns.email"),
            minWidth: 180,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        {
            field: 'id',
            headerName: i18n.translate("allUsersTable.columns.edit"),
            minWidth: 180,
            align: 'right',
            sortable: false,
            renderCell: (params) => <EditUserCell user={params.row}/>,
        },
        {
            headerName: i18n.translate("allUsersTable.columns.delete"),
            minWidth: 180,
            align: 'right',
            sortable: false,
            renderCell: (params) => <RemoveUserCell user={params.row}/>,
        }
    ];
}

const tableFilters = {
    showFilters: {
        search: true,
        organizations: true
    },
    filterValues: {},
    filterData: {}
}

const convertData = (users) => {
    return map(users, (user) => {
        return user
    })
}

const paginationModel = {
    page: 0,
    pageSize: 100,
}

export default function OrganizationUsersTable({organizationsOptions, tableButtons}) {
    const [localTableFilters, setLocalTableFilters] = useState({
        ...tableFilters,
        filterData: {
            ...tableFilters.filterData,
            organizations: organizationsOptions
        },
        filterValues: {
            ...tableFilters.filterValues,
            organizationId: get(organizationsOptions, '[0].value')
        }
    })
    const getColumns = async ({paginationModel, filterModel}) => {
        const organizationId = get(filterModel, 'filterValues.organizationId')
        return getUsersColumns({organizationId})
    }
    const apiToGetData = async ({paginationModel, filterModel}) => {
        return getOrganizationUsersApi({
            paginationModel,
            filterModel
        })
    }
    return <DataGridWrapper
        buttons={tableButtons}
        filterModel={localTableFilters}
        disableColumnFilter
        paginationModel={paginationModel}
        getColumns={getColumns}
        apiToGetData={apiToGetData}
        dataConverter={convertData}
    />
}