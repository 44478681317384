import './DoughnutChartLegend.scss'
import {keys, map} from "lodash";

export const DoughnutChartLegend = ({items}) => {
    return <div className="DoughnutChartLegend">
        {map(keys(items), (item, index) => {
            return <div className="legendItem" key={index}>
                <div className={`legendColor ${items[item].colorClass}`}></div>
                <div className="legendText">{items[item].label}</div>
            </div>
        })}
    </div>
}