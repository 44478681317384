export const adminEditCourseFilterModel = ({companies, companyId}) => {
    return {
        showFilters: {
            search: true,
            companies: true
        },
        filterValues: {
            companyId: companyId
        },
        filterData: {
            companies
        }
    }
}