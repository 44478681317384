import './DeleteUserConfirmationModal.scss'
import {i18n} from "../../../Utils/i18n/i18n";
import {map} from "lodash";
import {Button} from "../../Form/Button/Button";
import {buttonColors} from "../../Pickers/InputTheme";
import React, {useContext, useEffect} from "react";
import {Modal} from "../../Smart/Modal/Modal";
import {SA_deleteUsers, SA_getUsersData} from "../../../Services/SA_api";
import {GridReactContext} from "../../../Utils/DataGridWrapper/GridContext";


export const DeleteUserConfirmationModal = ({deleteUserConfirmationModalOpen, onCloseDeleteUserConfirmationModal, onSuccess}) => {
    const [usersToDeleteWithData, setUsersToDeleteWithData] = React.useState([])
    const gridContext = useContext(GridReactContext)
    const prepareUsersToDelete = async () => {
        const usersWithData = await SA_getUsersData({userIds: gridContext.selectionModel})
        setUsersToDeleteWithData(usersWithData)
    }
    useEffect(() => {
        prepareUsersToDelete()
    }, [gridContext.selectionModel])

    const onDeleteUsers = async () => {
        //TODO call the api to delete the users
        try {
            await SA_deleteUsers({userIds: gridContext.selectionModel})
            gridContext.triggerTableRefresh()
            onSuccess && onSuccess()
        }
        catch (e) {
            console.error(e)
        }

    }
    return <Modal
        open={deleteUserConfirmationModalOpen}
        onClose={onCloseDeleteUserConfirmationModal}>
        <div className="SADeleteUsersModal modalWrapper">
            <div className="modalTitle">
                <div>{i18n.translate('SADeleteUsersModal.title')}</div>
            </div>
            <div className="modalContent">
                <div className="message">{i18n.translate('SADeleteUsersModal.message', {context: {userCount: gridContext.selectionModel.length}})}</div>
                <div className="usersToDelete">
                    <div className="titleRow">
                        <div className="userDataCell">{i18n.translate('SADeleteUsersModal.firstName')}</div>
                        <div className="userDataCell">{i18n.translate('SADeleteUsersModal.lastName')}</div>
                        <div className="userDataCell">{i18n.translate('SADeleteUsersModal.email')}</div>
                        <div className="userDataCell">{i18n.translate('SADeleteUsersModal.phone')}</div>
                        <div className="userDataCell">{i18n.translate('SADeleteUsersModal.identifier')}</div>
                    </div>
                    {map(usersToDeleteWithData, (user) => {
                        return <div key={user.id} className="userToDelete">
                            <div className="userDataCell">{user.firstName}</div>
                            <div className="userDataCell">{user.lastName}</div>
                            <div className="userDataCell">{user.email}</div>
                            <div className="userDataCell">{user.phone}</div>
                            <div className="userDataCell">{user.identifier}</div>
                        </div>
                    })}
                </div>
            </div>
            <div className="modalFooter">
                <Button
                    color={buttonColors.YELLOW}
                    onClick={onCloseDeleteUserConfirmationModal}
                    text={i18n.translate('general.close')}
                />
                <Button
                    disabled={!usersToDeleteWithData || usersToDeleteWithData.length === 0}
                    color={buttonColors.GREY}
                    onClick={onDeleteUsers}
                    text={i18n.translate('SADeleteUsersModal.deleteUsers')}
                />
            </div>
        </div>
    </Modal>
}