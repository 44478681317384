import React from 'react';
import './LoadingSpinner.scss'
import loadingFIle from '../../assets/images/loading1.gif'

class LoadingSpinner extends React.Component {
    render() {
        const {color = "primary", local, text} = this.props
        return <div className={`loading-container ${local ? 'local' : ''}`}>
            <img src={loadingFIle} alt=""/>
           { text ? <div className="text-wrapper">
               <div className="text-loading">{text}</div>
            </div> : ''}
        </div>
    }
}

export default LoadingSpinner