import './SAEditCustomerModal.scss';
import React, {useEffect} from "react";
import {FormInput} from "../../Form/Input/FormInput";
import {i18n} from "../../../Utils/i18n/i18n";
import {Button} from "../../Form/Button/Button";
import {buttonColors} from "../../Pickers/InputTheme";
import {SA_getCompanyAndAdminUsers, SA_updateCompany} from "../../../Services/SA_api";
import {GridReactContext, GridReactContextProvider} from "../../../Utils/DataGridWrapper/GridContext";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {EditAddOrganizationsToCompany} from "../EditAddOrganizationsToCompany/EditAddOrganizationsToCompany";
import {Modal} from "../../Smart/Modal/Modal";

export const SAEditCustomerModal = ({
                                        onUserEditCompleted,
                                        initCustomerId,
                                        editCustomerModalOpen,
                                        closeEditCustomerModal,
                                    }) => {
    const [companyName, setCompanyName] = React.useState()
    const [companyUsersPrefix, setCompanyUserPrefix] = React.useState()
    const [identifier, setIdentifier] = React.useState()
    const [contactPhone, setContactPhone] = React.useState()
    const [contactEmail, setContactEmail] = React.useState()
    const [userIdentifier, setUserIdentifier] = React.useState()
    const [userFirstName, setUserFirstName] = React.useState()
    const [userLastName, setUserLastName] = React.useState()
    const [errorPrefix, setErrorPrefix] = React.useState(false)
    const [contactFirstName, setContactFirstName] = React.useState()
    const [contactLastName, setContactLastName] = React.useState()
    const [isLoading, setIsLoading] = React.useState(false)
    const gridContext = React.useContext(GridReactContext)

    const init = async () => {
        setIsLoading(true)
        const companyRes = await SA_getCompanyAndAdminUsers({companyId: initCustomerId})
        //company
        setCompanyName(companyRes.companyName)
        setCompanyUserPrefix(companyRes.companyUsersPrefix)
        setIdentifier(companyRes.identifier)
        setContactPhone(companyRes.contactPhone)
        setContactEmail(companyRes.contactEmail)
        setContactFirstName(companyRes.contactFirstName)
        setContactLastName(companyRes.contactLastName)
        //user
        // setUserIdentifier(get(companyRes, 'companyAdminUser.identifier'))
        // setUserFirstName(get(companyRes, 'companyAdminUser.firstName'))
        // setUserLastName(get(companyRes, 'companyAdminUser.lastName'))
        setIsLoading(false)
    }

    useEffect(() => {
        init()
    }, []);

    const onEditCompanyName = (e) => {
        setCompanyName(e.target.value)
    }
    const onEditCompanyUserPrefix = (e) => {
        //check if it only contains english letters and numbers
        const trimmed = e.target.value.replace(/[^a-zA-Z0-9]/g, '')
        if (trimmed !== e.target.value) {
            setErrorPrefix(true)
        } else {
            setErrorPrefix(false)
        }
        //capitalize
        setCompanyUserPrefix(e.target.value.toUpperCase())
    }
    const onEditIdentifier = (e) => {
        setIdentifier(e.target.value)
    }
    const onEditContactPhone = (e) => {
        setContactPhone(e.target.value)
    }
    const onEditContactEmail = (e) => {
        setContactEmail(e.target.value)
    }
    const onEditUserIdentifier = (e) => {
        //remove special characters
        let trimmed = e.target.value.replace(/[^a-zA-Z0-9]/g, '')
        //remove spaces
        trimmed = trimmed.replace(/\s/g, '')
        setUserIdentifier(trimmed)
    }
    const onEditUserFirstName = (e) => {
        setUserFirstName(e.target.value)
    }
    const onEditUserLastName = (e) => {
        setUserLastName(e.target.value)
    }
    const onEditContactFirstName = (e) => {
        setContactFirstName(e.target.value)
    }
    const onEditContactLastName = (e) => {
        setContactLastName(e.target.value)
    }
    const onCreateCompany = async () => {
        setIsLoading(true)
        await SA_updateCompany({
            companyId: initCustomerId,
            companyName,
            identifier,
            contactPhone,
            contactEmail,
            contactFirstName,
            contactLastName
        })
        setIsLoading(false)
        onUserEditCompleted && onUserEditCompleted()
        gridContext.triggerTableRefresh()
    }

    const buttonDisabled = !companyName || !companyUsersPrefix || !identifier || errorPrefix
    return <Modal
        open={editCustomerModalOpen}
        onClose={closeEditCustomerModal}>
        <div className="SuperAdminEditCustomer modalWrapper">
            <div className="modalTitle">
                <div>{i18n.translate('superAdmin.editCustomer.modalTitle')}</div>
            </div>
            <div className="modalContent">
                <div className="editCustomerContent">
                    <div className="detailsTitle">
                        <div>{i18n.translate('superAdmin.editCustomer.detailsTitle')}</div>
                    </div>
                    {isLoading ? <LoadingSpinner/> : ""}
                    <div className="details">
                        <div className="detailsRow">
                            <FormInput
                                required
                                id={`companyName`}
                                label={i18n.translate('superAdmin.createNewCustomer.companyName')}
                                className=""
                                value={companyName}
                                onChange={onEditCompanyName}
                            />
                            <FormInput
                                required
                                disabled
                                id={`companyUsersPrefix`}
                                label={i18n.translate('superAdmin.createNewCustomer.userPrefix')}
                                error={errorPrefix && i18n.translate('superAdmin.createNewCustomer.userPrefixError')}
                                className=""
                                value={companyUsersPrefix}
                                onChange={onEditCompanyUserPrefix}
                            />
                            <FormInput
                                required
                                id={`identifier`}
                                label={i18n.translate('superAdmin.createNewCustomer.identifier')}
                                className=""
                                value={identifier}
                                onChange={onEditIdentifier}
                            />
                        </div>


                        <div className="detailsRow">
                            <FormInput
                                id={`contactPhone`}
                                label={i18n.translate('superAdmin.createNewCustomer.contactPhone')}
                                className=""
                                value={contactPhone}
                                onChange={onEditContactPhone}
                            />
                            <FormInput
                                id={`contactEmail`}
                                label={i18n.translate('superAdmin.createNewCustomer.contactEmail')}
                                className=""
                                value={contactEmail}
                                onChange={onEditContactEmail}
                            />
                            <FormInput
                                id={`contactFirstName`}
                                label={i18n.translate('superAdmin.createNewCustomer.contactFirstName')}
                                className=""
                                value={contactFirstName}
                                onChange={onEditContactFirstName}/>
                            <FormInput
                                id={`contactLastName`}
                                label={i18n.translate('superAdmin.createNewCustomer.contactLastName')}
                                className=""
                                value={contactLastName}
                                onChange={onEditContactLastName}/>
                        </div>
                    </div>
                    {/*<div className="userCreationTitle">*/}
                    {/*    <div>{i18n.translate('superAdmin.editCustomer.adminUserDetails')}</div>*/}
                    {/*</div>*/}
                    {/*<div className="userCreation">*/}
                    {/*    <div className="detailsRow">*/}
                    {/*        <div className="userIdentifierWithPrefix">*/}
                    {/*            <FormInput*/}
                    {/*                required*/}
                    {/*                id={`userIdentifier`}*/}
                    {/*                disabled*/}
                    {/*                label={i18n.translate('superAdmin.createNewCustomer.userIdentifier')}*/}
                    {/*                className="ltrInput"*/}
                    {/*                value={userIdentifier}*/}
                    {/*                onChange={onEditUserIdentifier}*/}
                    {/*            />*/}
                    {/*        </div>*/}

                    {/*    </div>*/}
                    {/*    <div className="detailsRow">*/}
                    {/*        <FormInput*/}
                    {/*            required*/}
                    {/*            disabled*/}
                    {/*            id={`userFirstName`}*/}
                    {/*            label={i18n.translate('superAdmin.createNewCustomer.userFirstName')}*/}
                    {/*            value={userFirstName}*/}
                    {/*            onChange={onEditUserFirstName}/>*/}
                    {/*        <FormInput*/}
                    {/*            id={`userLastName`}*/}
                    {/*            disabled*/}
                    {/*            label={i18n.translate('superAdmin.createNewCustomer.userLastName')}*/}
                    {/*            value={userLastName}*/}
                    {/*            onChange={onEditUserLastName}/>*/}
                    {/*    </div>*/}

                    {/*</div>*/}
                    <div className="organizationsWrapper">
                        <GridReactContextProvider>
                            <EditAddOrganizationsToCompany companyId={initCustomerId}/>
                        </GridReactContextProvider>
                    </div>
                </div>
            </div>

            <div className="modalFooter">
                <Button
                    color={buttonColors.YELLOW}
                    onClick={closeEditCustomerModal}
                    text={i18n.translate('general.close')}
                />
                <Button
                    disabled={buttonDisabled}
                    color={buttonColors.GREY}
                    onClick={onCreateCompany}
                    text={i18n.translate('general.saveChanges')}
                />
            </div>
        </div>
    </Modal>
}