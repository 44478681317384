import {i18n} from "../../../Utils/i18n/i18n";
import {get, map} from 'lodash'
import {useEffect, useState} from "react";
import {DataGridWrapper} from "../../../Utils/DataGridWrapper/DataGridWrapper";
import {SA_getAllCompanies, SA_getAllCourses} from "../../../Services/SA_api";
import {EditSignDocTableCol} from "./EditSignDocTableCol/EditSignDocTableCol";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {RemoveCourseCol} from "./RemoveCourseCol/RemoveCourseCol";

const getColumns = async () => {
    return [
        {
            field: 'docName',
            headerName: i18n.translate("superAdmin.coursesTable.columns.courseName"),
            minWidth: 220,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}}>{params.value}</div>,
        },
        {
            field: 'description',
            headerName: i18n.translate("superAdmin.coursesTable.columns.description"),
            minWidth: 250,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        {
            field: 'type',
            headerName: i18n.translate("superAdmin.coursesTable.columns.type"),
            minWidth: 200,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}}
                                         title={i18n.translate(`signDocTypes.${params.value}`)}>
                {i18n.translate(`signDocTypes.${params.value}`)}
            </div>,
        },
        {
            field: 'companyName',
            headerName:
                i18n.translate("superAdmin.coursesTable.columns.companyName"),
            minWidth: 200,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}} title={params.value}>{params.value}</div>,
        },
        {
            field: 'id',
            headerName: i18n.translate("superAdmin.coursesTable.columns.edit"),
            minWidth: 200,
            align: 'right',
            sortable: false,
            renderCell: (params) => <EditSignDocTableCol signDocId={params.value} type={params.row.type}/>,
        },
        {
            headerName: i18n.translate("superAdmin.coursesTable.columns.remove"),
            minWidth: 200,
            align: 'right',
            sortable: false,
            renderCell: (params) => <RemoveCourseCol signDocId={params.row.id} docName={params.row.docName}/>,
        },
    ];
}

const convertData = (courses) => {
    return map(courses, (course) => {
        return {
            ...course,
            companyName: get(course, 'company.companyName')
        }
    })
}
const paginationModel = {
    page: 0,
    pageSize: 25,
};
const getFilterModel = ({companies}) => {
    return {
        filterValues: {},
        filterData: {
            companies
        },
        showFilters: {
            search: true,
            companies: true
        }
    }
}

export default function SuperAdminCoursesTable({usersData, organizationCourses, organizationId}) {
    const [companies, setCompanies] = useState()
    const init = async () => {
        const companies = await SA_getAllCompanies()
        const selectBoxCompanies = [{
            value: "",
            text: ''
        }, ...map(companies, (company) => {
            return {
                value: get(company, 'id'),
                text: get(company, 'companyName')
            }
        })]

        setCompanies(selectBoxCompanies)
    }
    useEffect(() => {
        init()
    }, []);
    const apiToGetData = async ({paginationModel, filterModel}) => {
        return SA_getAllCourses({organizationId, paginationModel, filterModel})
    }
    if (companies) {
        return <DataGridWrapper
            filterModel={getFilterModel({companies})}
            disableColumnFilter
            getColumns={getColumns}
            paginationModel={paginationModel}
            apiToGetData={apiToGetData}
            dataConverter={convertData}
        />
    } else {
        return <LoadingSpinner/>
    }
}