import './DeleteAutomationCell.scss'
import {ConfirmationModal} from "../../../Smart/confirmationModal/confirmationModal";
import {i18n} from "../../../../Utils/i18n/i18n";
import {buttonColors} from "../../../Pickers/InputTheme";
import {Button} from "../../../Form/Button/Button";
import React, {useContext, useState} from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {deleteAutomationAPI} from "../../../../Services/api";
import {GridReactContext} from "../../../../Utils/DataGridWrapper/GridContext";


export const DeleteAutomationCell = ({automation}) => {
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
    const gridContext = useContext(GridReactContext);
    const openConfirmationModal = () => {
        setConfirmationModalOpen(true)
    }
    const closeConfirmationModal = () => {
        setConfirmationModalOpen(false)
    }
    const deleteAutomation = async() => {
        await deleteAutomationAPI({automationId: automation.id})
        gridContext.triggerTableRefresh()
        closeConfirmationModal()
    }
    return <div className="deleteAutomationCell" >
        <Button
            size="small"
            color={buttonColors.GREY}
            icon={<DeleteForeverIcon/>}
            onClick={openConfirmationModal}
            // text={i18n.translate('deleteAutomationModal.deleteAutomation')}
        />
        {confirmationModalOpen ? <ConfirmationModal
            confirmationModalOpen={confirmationModalOpen}
            onCloseConfirmationModal={closeConfirmationModal}
            title={i18n.translate('deleteAutomationModal.title')}
            message={i18n.translate('deleteAutomationModal.message')}
            messageBody={<div>
                <div>{i18n.translate('deleteAutomationModal.automationName')}: <span style={{fontWeight: 'bold'}}>{automation.name}</span></div>
            </div>}
            onConfirm={deleteAutomation}
            onCancel={closeConfirmationModal}
            confirmText={i18n.translate('deleteAutomationModal.deleteAutomation')}
        /> : ''}
    </div>
}