import React from 'react';
import "./SignDocCard.scss"
import {Link} from "react-router-dom";
import {Button} from "../../Form/Button/Button";
import {getCardDesignValues} from "./SignDocCardLib";
import {SignDocProgressSlider} from "../../../Utils/CardProgressSlider/CourseProgressSlider/SignDocProgressSlider";
import {SignDocIcons, SignDocTypes} from "../SignDocLib";
import {CourseProgressSlider} from "../../../Utils/CardProgressSlider/CourseProgressSlider/CourseProgressSlider";
import {get} from "lodash";

export const SignDocCard = ({imgSrc, docName, finishByDate, progressPercent, viewed, signed, id, description, type, doNotForceCompletion}) => {
    const {buttonColor, message, completionClass, buttonText} = getCardDesignValues({progressPercent, viewed, signed, finishByDate, type})
    const emptyClick = () => {
    }

    return <div className={`SignDocCard ${doNotForceCompletion ? 'doNotForceCompletion' : ''}`}>
        <div className={`signDocCardImage ${type === SignDocTypes.REGULAR_COURSE ? 'round' : ''}`}>
            <img src={imgSrc || SignDocIcons[type]}/>
        </div>
        <div className="signDocCardContent" >
            <div className={`signDocTitle ${get(docName, 'length') > 20 ? 'small' : ''}`} title={docName}>{docName}</div>
            <div className="signDocDescription" title={description}>{description}</div>
            {!doNotForceCompletion ? <div className="signDocProgressWrapper">
                {type === SignDocTypes.REGULAR_COURSE ?
                    <CourseProgressSlider progressPercent={progressPercent} type={type}/> :
                    <SignDocProgressSlider viewed={viewed} signed={signed} type={type}/>}

            </div> : ''}
            <Link className="signDocButton" to={`/${type === SignDocTypes.REGULAR_COURSE ? 'course' : 'signDoc'}/${id}`} target="_blank" rel="noopener noreferrer">
                <Button color={buttonColor}
                        onClick={emptyClick}
                        style={{width: '100%'}}
                        text={buttonText}/>
            </Link>
            {!doNotForceCompletion ? <div className={`signDocEndDate ${completionClass}`}>{message}</div> : ''}
        </div>
    </div>
}

