import {i18n} from "../../../../Utils/i18n/i18n";
import './NotificationSuccessAlertMessageBody.scss'

export const NotificationSuccessAlertMessageBody = ({sentMessages, notSentMessages}) => {
    return <div className="NotificationSuccessAlertMessageBody">
        <div className="messagesCount">
            <div>{i18n.translate('CourseReminderNotificationButton.alerts.sentMessages')}: {sentMessages}</div>
        </div>
        {notSentMessages ? <div className="messagesCount">
            <div>{i18n.translate('CourseReminderNotificationButton.alerts.notSentMessages')}: {notSentMessages}</div>
            <div className="infoMessage">{i18n.translate('CourseReminderNotificationButton.alerts.notSentMessagesInfo')}</div>
        </div> : ''}
    </div>
}