import '../CreateEditCourseModal.scss';
import {i18n} from "../../../../Utils/i18n/i18n";
import {FormInput} from "../../../Form/Input/FormInput";
import React, {useEffect, useState} from "react";
import {Button} from "../../../Form/Button/Button";
import {buttonColors} from "../../../Pickers/InputTheme";
import {
    SA_editSignDoc,
    SA_getAllCompanies,
    SA_getAllOrganizationsForSignDoc,
    SA_getSignDoc,
    SA_uploadSignDocZipFileAPI
} from "../../../../Services/SA_api";
import {FileUploadBox} from "../../../Smart/FileUploadBox/FileUploadBox";
import {DataGridWrapper} from "../../../../Utils/DataGridWrapper/DataGridWrapper";
import {GridReactContextProvider} from "../../../../Utils/DataGridWrapper/GridContext";
import {adminNewCourseConvertData, getAdminNewCourseColumns} from "../CreateNewCourseModalLib";
import {cloneDeep, get, map} from "lodash";
import {adminEditCourseFilterModel} from "./SAEditSignDocModalLib";
import LoadingSpinner from "../../../LoadingSpinner/LoadingSpinner";
import {Modal} from "../../../Smart/Modal/Modal";
import {imageOptionsArray} from "../CreateEditCourseLib";
import {ImageSelectGallery} from "../ImageSelectGallery/ImageSelectGallery";


const imageOptions = cloneDeep(imageOptionsArray)

export const SAEditSignDocModal = ({initSignDocId, editCourseModalOpen, closeEditCourseModal}) => {
    const [docName, setDocName] = useState()
    const [description, setDescription] = useState()
    const [signDocId, setSignDocId] = useState(initSignDocId)
    const [companyId, setCompanyId] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [companies, setCompanies] = useState()
    const [zipFileUploaded, setZipFileUploaded] = useState(false)
    const [selectedImageUrl, setSelectedImageUrl] = useState()
    const editCourseName = (e) => {
        setDocName(e.target.value)
    }
    const editCourseDescription = (e) => {
        setDescription(e.target.value)
    }
    const init = async () => {
        setIsLoading(true)
        const signDoc = await SA_getSignDoc({signDocId})
        setCompanyId(get(signDoc, 'company.id'))
        if (!get(signDoc, 'company.id')) {
            const companies = await SA_getAllCompanies()
            const selectBoxCompanies = map(companies, (company) => {
                return {
                    value: get(company, 'id'),
                    text: get(company, 'companyName')
                }
            })
            setCompanies(selectBoxCompanies)
        } else {
            const selectBoxCompanies = [{
                value: get(signDoc, 'company.id'),
                text: get(signDoc, 'company.companyName')
            }]
            setCompanies(selectBoxCompanies)
        }
        //if imgSrc not in imageOptions, add it to the list
        if (!imageOptions.includes(get(signDoc, 'imgSrc'))) {
            imageOptions.push(get(signDoc, 'imgSrc'))
        }
        setSelectedImageUrl(get(signDoc, 'imgSrc'))
        setSignDocId(signDoc.id)
        setDocName(signDoc.docName)
        setDescription(signDoc.description)
        setIsLoading(false)

    }
    const apiToGetData = async ({paginationModel, filterModel}) => {
        return SA_getAllOrganizationsForSignDoc({paginationModel, filterModel, signDocId})
    }
    useEffect(() => {
        init()
    }, []);

    const uploadCourseZip = async ({files, onProgress}) => {
        return SA_uploadSignDocZipFileAPI({files, onProgress, signDocId})
    }
    const getCourseColumnsWithCourseId = async () => {
        return getAdminNewCourseColumns({signDocId})
    }

    const onZipFileCompletedUpload = () => {
        setZipFileUploaded(true)
    }

    const onSelectedImageUrl = (imageUrl) => () => {
        setSelectedImageUrl(imageUrl)
    }

    const onSaveEditedCourse = async () => {
        setIsLoading(true)
        const newCourseData = await SA_editSignDoc({signDocId, docName, description, imgSrc: selectedImageUrl})
        setSelectedImageUrl(get(newCourseData, 'imgSrc'))
        setSignDocId(newCourseData.id)
        setDocName(newCourseData.docName)
        setDescription(newCourseData.description)
        setIsLoading(false)
    }

    return <Modal
        open={editCourseModalOpen}
        onClose={closeEditCourseModal}>
        <div className="CreateEditCourse modalWrapper">
            <div className="modalTitle">
                {i18n.translate('superAdmin.editCourse.modalTitle')}
            </div>
            <div className="modalContent">
                <div className="editCourseContent">
                    {isLoading ? <LoadingSpinner/> : ''}
                    <div className="upperRow">
                        <div className="courseDetails">
                            <FormInput
                                label={i18n.translate('superAdmin.editCourse.docName')}
                                className=""
                                value={docName}
                                onChange={editCourseName}
                            />
                            <FormInput
                                label={i18n.translate('superAdmin.editCourse.description')}
                                className=""
                                value={description}
                                onChange={editCourseDescription}
                            />
                        </div>

                        {signDocId ? <div className={`courseZipFile`}>
                            <FileUploadBox uploadFiles={uploadCourseZip} onFileUploaded={onZipFileCompletedUpload} maxSize={314572800}>
                                {/*300MB*/}
                                <div className="text1">{i18n.translate('superAdmin.editCourse.dropZipHere')}</div>
                                <div className="text2">{i18n.translate('superAdmin.editCourse.dropZipNote')}</div>
                            </FileUploadBox>
                        </div> : ''}
                    </div>
                    {selectedImageUrl ? <ImageSelectGallery selectedImageUrl={selectedImageUrl} onSelectedImageUrl={onSelectedImageUrl} imageOptions={imageOptions}/> : ''}
                    <div className="saveContainer">
                        <Button
                            color={buttonColors.GREY}
                            onClick={onSaveEditedCourse}
                            disabled={docName === '' || !docName || description === '' || !description}
                            text={i18n.translate('general.saveChanges')}
                        />
                    </div>
                    <div className="organizationsTable">
                        {signDocId && companies ? <GridReactContextProvider
                            initFilterDisabled={{companies: {disabled: get(companies, 'length') === 1}}}>
                            <DataGridWrapper
                                filterModel={adminEditCourseFilterModel({
                                    companies,
                                    companyId: companyId || get(companies, '[0].value')
                                })}
                                disableColumnFilter
                                getColumns={getCourseColumnsWithCourseId}
                                apiToGetData={apiToGetData}
                                dataConverter={adminNewCourseConvertData}
                            />
                        </GridReactContextProvider> : ''}
                    </div>
                </div>
            </div>
            <div className="modalFooter">
                <Button
                    color={buttonColors.YELLOW}
                    onClick={closeEditCourseModal}
                    text={i18n.translate('general.close')}
                />
            </div>
        </div>
    </Modal>
}