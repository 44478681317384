import {Modal} from "../../Smart/Modal/Modal";
import {i18n} from "../../../Utils/i18n/i18n";
import './EditNotesModal.scss';
import {find, map} from "lodash";
import React, {useEffect, useState} from "react";
import {createNoteAPI, getAllNotesForCompanyAPI, getUserOrganizationsWithNotesAndCourses} from "../../../Services/api";
import {getAdminOrganizations} from "../../../Utils/User/UserLib";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {EditNote} from "../EditNote/EditNote";
import {Button} from "../../Form/Button/Button";
import {buttonColors} from "../../Pickers/InputTheme";
import AddIcon from "@mui/icons-material/Add";

export const EditNotesModal = ({
                                   editNotesModalOpen,
                                   closeEditNotesModal
                               }) => {

    const [organizations, setOrganizations] = useState()
    const [notes, setNotes] = useState()
    const [adminOrgs, setAdminOrgs] = useState()
    const init = async () => {
        let [orgs, notes] = await Promise.all([getUserOrganizationsWithNotesAndCourses(), getAllNotesForCompanyAPI()])
        orgs = getAdminOrganizations({organizationsWithPermission: orgs})
        setAdminOrgs(orgs)
        map(notes, (note) => {
            note.orgs = []
            note.availableOrgs = []
            map(orgs, (org) => {
                if (find(note.organizations, {id: org.organization.id})) {
                    note.orgs.push({
                        id: org.organization.id,
                        orgName: org.organization.orgName
                    })
                } else {
                    note.availableOrgs.push({
                        id: org.organization.id,
                        orgName: org.organization.orgName
                    })
                }
            })
        })
        setNotes(notes)
        setOrganizations(orgs)
    }
    useEffect(() => {
        init()
    }, []);

    const onCreateNote = async () => {
        const newNote = await createNoteAPI({title: i18n.translate('settingsPage.newNoteTitle')})
        init()
    }
    return <Modal
        open={editNotesModalOpen}
        onClose={closeEditNotesModal}>
        <div className="editNotesModal modalWrapper">
            <div className="modalTitle">
                <div>{i18n.translate('settingsPage.editNotesModal')}</div>
                <div className="createNoteButton">
                    <Button
                        color={buttonColors.GREY}
                        onClick={onCreateNote}
                        icon={<AddIcon/>}
                        text={i18n.translate('settingsPage.createNote')}>
                    </Button>
                </div>
            </div>
            <div className="modalContent editNotesContent">

                {notes ? <div className="notesAndOrgsWrapper">
                    {map(notes, (note, index) => {
                        return <EditNote note={note} key={note.id} triggerReload={init}/>
                    })}
                </div> : <LoadingSpinner/>}
            </div>
            <div className="modalFooter">
                <Button
                    color={buttonColors.YELLOW}
                    onClick={closeEditNotesModal}
                    text={i18n.translate('general.close')}
                />
            </div>
        </div>
    </Modal>;
}
