import {i18n} from "../../../Utils/i18n/i18n";
import {map} from 'lodash'
import {DataGridWrapper} from "../../../Utils/DataGridWrapper/DataGridWrapper";
import {SA_getAllCompaniesPagination} from "../../../Services/SA_api";
import {EditCustomerTableCol} from "./EditCustomerTableCol/EditCustomerTableCol";
import {CustomerFFTableCol} from "./CustomerFFTableCol/CustomerFFTableCol";
import {SACustomerQuotaMngmnt} from "./SACustomerQuotaMngmnt/SACustomerQuotaMngmnt";

const getColumns = async () => {
    return [
        {
            field: 'companyName',
            headerName: i18n.translate("superAdmin.customersTable.columns.companyName"),
            minWidth: 300,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}}>{params.value}</div>,
        },
        {
            field: 'smsQuota',
            headerName: i18n.translate("superAdmin.customersTable.columns.smsQuota"),
            minWidth: 80,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}}>{params.value}</div>,
        },
        {
            field: 'storageQuotaMB',
            headerName: i18n.translate("superAdmin.customersTable.columns.storageQuotaMB"),
            minWidth: 80,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}}>{params.value}MB</div>,
        },
        {
            field: 'editQuotas',
            headerName: i18n.translate("superAdmin.customersTable.columns.editQuota"),
            minWidth: 150,
            align: 'right',
            sortable: false,
            renderCell: (params) => <SACustomerQuotaMngmnt company={params.row} companyId={params.row.id}/>,
        },
        {
            field: 'id',
            headerName: i18n.translate("superAdmin.customersTable.columns.edit"),
            minWidth: 150,
            align: 'right',
            sortable: false,
            renderCell: (params) => <EditCustomerTableCol customerId={params.value}/>,
        },
        {
            field: 'ff',
            headerName: i18n.translate("superAdmin.customersTable.columns.featureFlags"),
            minWidth: 150,
            align: 'right',
            sortable: false,
            renderCell: (params) => <CustomerFFTableCol customer={params.row}/>,
        },
    ];
}

const convertData = (companies) => {
    return map(companies, (company) => {
        return company
    })
}
const paginationModel = {
    page: 0,
    pageSize: 25,
};
const filterModel = {
    filterValues: {},
    filterData: {},
    showFilters: {
        search: true
    }
}

export const SuperAdminCustomersTable = () => {
    const apiToGetData = async({paginationModel, filterModel}) => {
        return SA_getAllCompaniesPagination({paginationModel, filterModel})
    }
    return <DataGridWrapper
        filterModel={filterModel}
        disableColumnFilter
        getColumns={getColumns}
        paginationModel={paginationModel}
        apiToGetData={apiToGetData}
        dataConverter={convertData}
    />
}