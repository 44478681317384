import './Reminders.scss'
import {SMSQuota} from "../SMSQuota/SMSQuota";
import React, {useContext} from "react";
import {UserReactContext} from "../../../contexts/UserContext";
import {NotificationMessageButton} from "../../Notifications/NotificationMessageButton/NotificationMessageButton";
import {
    NotificationAutomationButton
} from "../../Notifications/NotificationAutomationButton/NotificationAutomationButton";
import {AutomationsTable} from "./AutomationsTable/AutomationsTable";
import {GridReactContextProvider} from "../../../Utils/DataGridWrapper/GridContext";
import {CompanyReactContext} from "../../../contexts/CompanyContext";
import {FeatureFlags} from "../../../Utils/Constants";


export const Reminders = () => {
    const userContext = useContext(UserReactContext)
    const companyContext = useContext(CompanyReactContext)
    return <div className="Reminders">
        <GridReactContextProvider>
            <div className="remindersInfoAndButton">
                <div className="remindersButton">
                    <NotificationAutomationButton fullHeight/>
                </div>
                <div className="remindersButton">
                    <NotificationMessageButton fullHeight/>
                </div>
                <div className="remindersInfo">
                    {userContext.isManagingCompany() ? <SMSQuota/> : ''}
                </div>
            </div>
            {companyContext.featureFlags[FeatureFlags.AUTOMATION] ? <div className="reminderAutomations">
                <AutomationsTable/>
            </div> : ''}
        </GridReactContextProvider>

    </div>
}