import {Modal} from "../../Smart/Modal/Modal";
import {i18n} from "../../../Utils/i18n/i18n";
import './EditOrgNameModal.scss';
import {map, get} from "lodash";
import React from "react";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {EditOrganization} from "../EditOrganization/EditOrganization";
import {Button} from "../../Form/Button/Button";
import {buttonColors} from "../../Pickers/InputTheme";
import {useUserAdminOrgs} from "../../Hooks/UseUserAdminOrgs";

export const EditOrgNameModal = ({
                                     editOrgNameModalOpen,
                                     closeEditOrgNameModal
                                 }) => {

    const {adminOrganizationOptions, adminOrganizations, isOrgsLoading} = useUserAdminOrgs({})

    return <Modal
        open={editOrgNameModalOpen}
        onClose={closeEditOrgNameModal}>
        <div className="editOrganizationNameModal modalWrapper">
            <div className="modalTitle">
                <div>{i18n.translate('settingsPage.editOrganizationsNames')}</div>
            </div>
            <div className="modalContent">
                {adminOrganizations ? <div className="organizationsWrapper">
                    {map(adminOrganizations, (organization, index) => {
                        return <EditOrganization organization={organization}/>
                    })}
                </div> : <LoadingSpinner/>}
            </div>
            <div className="modalFooter">
                <Button
                    color={buttonColors.YELLOW}
                    onClick={closeEditOrgNameModal}
                    text={i18n.translate('general.close')}
                />
            </div>
        </div>
    </Modal>;
}
