import {DataGridWrapper} from "../../../Utils/DataGridWrapper/DataGridWrapper";
import {adminNewCourseConvertData} from "../CreateEditCourse/CreateNewCourseModalLib";
import {GridReactContext} from "../../../Utils/DataGridWrapper/GridContext";
import React from "react";
import {i18n} from "../../../Utils/i18n/i18n";
import {SA_createOrganizationsForCompany, SA_getAllOrganizationsForCompany} from "../../../Services/SA_api";
import './EditAddOrganizationsToCompany.scss'
import {AddOrganizationsModal} from "./AddOrganizationsModal/AddOrganizationsModal";

export const getAdminCustomerOrgsColumns = async ({courseId}) => {
    return [
        {
            field: 'orgName',
            headerName: i18n.translate("superAdmin.createNewCourse.organizationsTable.orgName"),
            minWidth: 430,
            align: 'right',
            sortable: false,
            renderCell: (params) => <div style={{width: '100%'}}>{params.value}</div>,
        }
    ];
}

export const adminCustomerOrgsFilterModel = () => {
    return {
        showFilters: {
            search: true,
        },
        filterValues: {},
        filterData: {}
    }
}
export const EditAddOrganizationsToCompany = ({companyId}) => {
    const [addOrganizationsModalOpen, setAddOrganizationsModalOpen] = React.useState(false)
    const gridContext = React.useContext(GridReactContext)
    const apiToGetData = async ({paginationModel, filterModel}) => {
        return SA_getAllOrganizationsForCompany({paginationModel, filterModel, companyId})
    }
    const openCreateNewOrganizationsModal = () => {
        setAddOrganizationsModalOpen(true)
    }
    const onOrganizationModalClose = () => {
        setAddOrganizationsModalOpen(false)
    }
    const onCreateOrganizations = async ({organizations}) => {
        setAddOrganizationsModalOpen(false)
        await SA_createOrganizationsForCompany({companyId, organizations})
        gridContext.triggerTableRefresh()
    }
    return <div className="EditAddOrganizationsToCompany">
        <div className="orgsTitle">{i18n.translate('superAdmin.editCustomer.organizationsTitle')}</div>
            <DataGridWrapper
                filterModel={adminCustomerOrgsFilterModel()}
                disableColumnFilter
                buttons={[
                    {
                        text: i18n.translate('superAdmin.editCustomer.addOrganizations'),
                        onClick: openCreateNewOrganizationsModal
                    }
                ]}
                getColumns={getAdminCustomerOrgsColumns}
                apiToGetData={apiToGetData}
                dataConverter={adminNewCourseConvertData}
            />
            {addOrganizationsModalOpen &&
                <AddOrganizationsModal open={addOrganizationsModalOpen} onClose={onOrganizationModalClose}
                                       onCreateOrganizations={onCreateOrganizations}/>}
    </div>
}