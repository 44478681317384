import React, {useEffect, useRef, useState} from 'react';
import ReactPlayer from 'react-player';

export const VideoPlayer = ({videoUrl, startAtDecimalPercent, onWatchComplete}) => {
    const playerRef = useRef(null);
    const [duration, setDuration] = useState(0);
    const onProgress = (state) => {
        console.log('onProgress', state)
        //TODO save progress in the future
        //state example:
        //{playedSeconds: 342.461066, played: 0.9955263546511628, loadedSeconds: 344, loaded: 1}
    }
    const handleDuration = (duration) => {
        setDuration(duration);
    };
    useEffect(() => {
        if (playerRef.current && startAtDecimalPercent && duration) {
            playerRef.current.seekTo(startAtDecimalPercent * duration);
        }
    }, [duration]);
    return (
        <div style={{position: 'relative'}}>
            <ReactPlayer
                width='100%'
                ref={playerRef}
                // config={{
                //     youtube: {
                //         playerVars: {
                //             disablekb: 1
                //         }
                //     }
                // }}
                playing={true} // to auto play we need to set "muted" to true as well
                onDuration={handleDuration}
                url={videoUrl} controls={true} onEnded={onWatchComplete} onProgress={onProgress}/>
            {/*<div*/}
            {/*    style={{*/}
            {/*        position: 'absolute',*/}
            {/*        top: 0,*/}
            {/*        left: 0,*/}
            {/*        bottom: 0,*/}
            {/*        right: 0,*/}
            {/*    }}*/}
            {/*    tabIndex={0}*/}
            {/*    onFocus={(e) => e.target.blur()}*/}
            {/*/>*/}
        </div>

    );
};

