import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {startCourseAPI} from "../Services/api";
import LoadingSpinner from "../Components/LoadingSpinner/LoadingSpinner";

export const Course = () => {
    const {signDocId} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [course, setCourse] = useState(null);

    const init = async () => {
        const course = await startCourseAPI({signDocId});
        setCourse(course);
        setIsLoading(false);
    }

    useEffect(init, []);
    if(isLoading){
        return <LoadingSpinner/>
    }
    return <iframe
        src={course?.courseLink || ''}
        height="100%"
        width="100%"
        title="Scorm Package URL"
    />
}



