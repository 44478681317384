import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom'
import ReactGA from 'react-ga4'

export const GoogleAnalytics = () => {

    useEffect(() => {
        if(process.env.REACT_APP_ENV === 'production'){
            ReactGA.initialize(process.env.REACT_APP_GA4_ID);
        }
    }, []);

    const location = useLocation()
    // console.log(location.pathname)
    if(process.env.REACT_APP_ENV === 'production'){
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }

    //custom event
    // ReactGA.event({
    //     category: "your category",
    //     action: "your action",
    //     label: "your label", // optional
    //     value: 99, // optional, must be a number
    //     nonInteraction: true, // optional, true/false
    //     transport: "xhr", // optional, beacon/xhr/image
    // });
    return <></>
}
