import React, {useEffect, useState} from "react";
import useDebounce from "../../../Hooks/useDebounce";
import {FormInput} from "../../../Components/Form/Input/FormInput";
import {i18n} from "../../i18n/i18n";
import {get} from "lodash";


export const SearchFilter = ({filterModel, setFilterModel}) => {
    const [searchValue, setSearchValue] = useState(get(filterModel, 'filterValues.search', ''))
    const debouncedSearchValue = useDebounce(searchValue, 500)
    useEffect(() => {
        let newFilterModel = {
            ...filterModel,
            filterValues: {
                ...filterModel.filterValues,
                search: debouncedSearchValue
            }
        }
        setFilterModel(newFilterModel)
    }, [debouncedSearchValue])

    const onSearchValueChange = (e) => {
        setSearchValue(e.target.value)
    }
    return <div className="searchFilter">
        <FormInput
            id="password"
            label={i18n.translate('dataGrid.filters.search.input')}
            className={``}
            value={searchValue}
            onChange={onSearchValueChange}
        />
    </div>

}