import React, {useContext, useEffect, useState} from "react";
import {Navigate} from "react-router-dom";
import logo from '../../assets/images/logo.png'
import './Login.scss'
import {FormInput} from "../Form/Input/FormInput";
import {Button} from "../Form/Button/Button";
import {get, trim} from 'lodash'
import {UserReactContext} from "../../contexts/UserContext";
import {i18n} from "../../Utils/i18n/i18n";
import {buttonColors} from "../Pickers/InputTheme";
import {TypeVerificationCode} from "./TypeVerificationCode/TypeVerificationCode";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

export const Login = ({referUrl, doNotRedirectOnSuccess, token}) => {
    const [referrer, setReferrer] = useState(referUrl);
    const [password, setPassword] = useState('');
    const [identifier, setIdentifier] = useState('');
    const [error, setError] = useState('');
    const [mfaStep, setMfaStep] = useState(false);
    const [loading, setLoading] = useState(false);
    const [urlLoginCompleted, setUrlLoginCompleted] = useState(false);

    const userContext = useContext(UserReactContext);

    const handleIdentifierChange = (event) => {
        setIdentifier(event.target.value)
    }
    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const {mfa} = await userContext.login({identifier, password})
            if (mfa) {
                setMfaStep(true)
            }
            // this.props.configurationContext.getConfiguration()
        } catch (e) {
            setError(get(e, 'errors[0].message'))

        }
    }
    const handleTokenLogin = async () => {
        setLoading(true)
        try {
            await userContext.loginWithToken({token})
            setMfaStep({linkLogin: true})

            // this.props.configurationContext.getConfiguration()
        } catch (e) {
            setError(get(e, 'errors[0].message'))
        }
        finally {
            setLoading(false)
            setUrlLoginCompleted(true)
        }
    }
    useEffect(() => {
        if (token) {
            handleTokenLogin()
        }
    }, []);
    const enterKeyListener = (event) => {
        if (event.key === 'Enter' && !mfaStep && isDataValid()) {
            handleSubmit(event)
        }
    }
    useEffect(() => {
        document.addEventListener('keydown', enterKeyListener)
        return () => {
            document.removeEventListener('keydown', enterKeyListener)
        }

    })
    const isDataValid = () => {
        if (!password || trim(password) === '' || !identifier || trim(identifier) === '') {
            return false
        }
        return true
    }

    let buttonDisabled = !isDataValid()
    if(!token || urlLoginCompleted){
        const isUserValid = userContext.isUserValid()
        if (!doNotRedirectOnSuccess && isUserValid) {
            return <Navigate to={{pathname: '/home'}}/>
        }
    }



    return <div className="login-container">
        <img className="loginLogo" src={logo}/>
        {loading ? <LoadingSpinner/> : ''}
        {!mfaStep ? <div className="login-form" >
                <FormInput
                    error={error}
                    dontShowErrorMessage
                    required
                    id="identifier"
                    label={i18n.translate('login.identifier')}
                    className="login-text-field first"
                    value={identifier}
                    onChange={handleIdentifierChange}
                />
                <FormInput
                    error={error}
                    required
                    type="password"
                    id="password"
                    label={i18n.translate('login.password')}
                    className={`login-text-field ${!error ? 'first' : ''}`}
                    value={password}
                    onChange={handlePasswordChange}
                />
                <div className="login-btn-wrapper">
                    <Button color={buttonColors.GREY} type="submit" style={{width: '100%'}} disabled={buttonDisabled}
                            text={i18n.translate('login.login')} onClick={handleSubmit} value="Login"/>
                </div>
            </div> :
            <div className="login-form">
                <TypeVerificationCode linkLogin={get(mfaStep, 'linkLogin')}/>
            </div>

        }
    </div>
}
