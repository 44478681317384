import './EditAutomationCell.scss'
import {buttonColors} from "../../../Pickers/InputTheme";
import {Button} from "../../../Form/Button/Button";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";


export const EditAutomationCell = ({automation, onAutomationSelectedForEdit}) => {
    return <div className="deleteAutomationCell">
        <Button
            size="small"
            color={buttonColors.GREY}
            icon={<EditIcon/>}
            onClick={() => onAutomationSelectedForEdit(automation)}
            // text={i18n.translate('deleteAutomationModal.deleteAutomation')}
        />
    </div>
}