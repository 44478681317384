import './EditSignDocTableCol.scss';
import {buttonColors} from "../../../Pickers/InputTheme";
import {i18n} from "../../../../Utils/i18n/i18n";
import {Button} from "../../../Form/Button/Button";
import React from "react";
import {SAEditSignDocModal} from "../../CreateEditCourse/EditSignDocModal/SAEditSignDocModal";
import {SignDocTypes} from "../../../SignDoc/SignDocLib";

export const EditSignDocTableCol = ({signDocId, type}) => {
    const [isEditCourseModalOpen, setIsEditCourseModalOpen] = React.useState(false)
    const onClickEditCourse = () => {
        setIsEditCourseModalOpen(true)
    }
    const closeEditCourseModal = () => {
        setIsEditCourseModalOpen(false)
    }
    if(type !== SignDocTypes.REGULAR_COURSE){
        return null
    }
    return <div className="EditCourseTableCol">
        <Button color={buttonColors.GOLD}
                size={'small'}
                text={i18n.translate('superAdmin.coursesTable.columns.editCourse')}
                onClick={onClickEditCourse}/>

        {isEditCourseModalOpen ? <SAEditSignDocModal initSignDocId={signDocId} editCourseModalOpen={isEditCourseModalOpen} closeEditCourseModal={closeEditCourseModal} /> : ''}
    </div>
}