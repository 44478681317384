import React, {useState} from "react";
import {get} from "lodash";
import {SelectBox} from "../../../Components/Pickers/SelectBox/SelectBox";
import './CompanyFilter.scss'
import {i18n} from "../../i18n/i18n";

export const CompanyFilter = ({filterModel, setFilterModel, disabledObj}) => {
    const [selectedCompany, setSelectedCompany] = useState(get(filterModel, 'filterValues.companyId'))
    const onSelectCompanyChange = (e) => {
        setSelectedCompany(e.target.value)
        const newFilterModel = {
            ...filterModel,
            filterValues: {
                ...filterModel.filterValues,
                companyId: e.target.value
            }
        }
        setFilterModel(newFilterModel)
    }
    return <div className="searchFilter">
        <SelectBox label={i18n.translate('dataGrid.filterLabels.company')} disabled={get(disabledObj, 'disabled')} options={filterModel.filterData.companies} value={selectedCompany} onChange={onSelectCompanyChange}/>
        {get(disabledObj, 'disabled') ? <div className="disabledNotice">{get(disabledObj, 'disabledNote')}</div> : ''}
    </div>

}