import React, {useContext, useEffect} from "react";
import {Login} from "../Login";
import "./LoginFirstPageWrapper.scss"
import { useLocation } from "react-router-dom";
import {UserReactContext} from "../../../contexts/UserContext";

export const LoginFirstPageWrapper = () => {
    const userContext = useContext(UserReactContext);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const token = query.get('t');
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const token = query.get('t');
        if(token){
            userContext.logout({noRedirect: true})
        }
    }, [])
    return <div className="login-first-page-wrapper">
        <div className="login-comp-wrapper">
            <Login token={token}/>
        </div>
    </div>

}